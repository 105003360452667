import React from 'react'
import { useState } from "react";
import { useHistory } from 'react-router-dom'
import axios from "axios";
import { config } from '../../setting';
import style from './forgotpassword.module.css'
import backgroundImage from './forgetpasswordBack.png'


const Forgotpassword = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const [success, setsuccess] = useState(false);
    const [emailValue, setEmailValue] = useState('');

    const history = useHistory();

    const onSubmitClicked = async () => {
        try {
            await axios.put(`${config.url.NEW_API}/users/forgotPassword/${emailValue}`);
            setsuccess(true);
            setTimeout(() => {
                history.push('/user/login')

            }, 5000);
        } catch (e) {
            setErrorMessage(e.message)
        }
    }

    return success ? (
        <div style={{ backgroundImage: `url(${backgroundImage})` }} className={style.forgotpasswordback} >
            <div className={style.forgetpassword_Container}>
                <h1>Forgot your password</h1>
                <p><b>Your email is now sent. Please check your email</b></p>
                <img style={{marginTop: "5%"}} src="/asset/forgetpasswordSent.png" alt="" />
            </div>
        </div>
    ) : (
        <div style={{ backgroundImage: `url(${backgroundImage})` }} className={style.forgotpasswordback} >
            <div className={style.forgetpassword_Container}>
                <h1>Forgot your password</h1>
                <p><b>Tell us your email and we will send you a reset password email</b></p>
                {errorMessage && <div>{errorMessage}</div>}
                <div className={style.forgetPasswordBox}>
                    <img className={style.logoimage} src={'/asset/agrollyTaiwanlogo-white.png'} alt="" />
                    <div className={style.emailInputBox}>
                        <label htmlFor="">Email</label>
                        <input
                            value={emailValue}
                            onChange={e => setEmailValue(e.target.value)}
                            placeholder="someone@email.com" />
                    </div>
                    <div>
                        <button
                            disabled={!emailValue}
                            onClick={onSubmitClicked}
                            className={style.sendEmailbtn}>
                            Send Email
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )



}

export default Forgotpassword

