import React from 'react'
import style from "./loader.module.css";

const Loader2 = () => {
  return (
    <div className={style.loaderbackground}>
        <div className={style.loader2}>
            Loading
        </div>
    </div>
  )
}

export default Loader2