import React from 'react';
import style from './question.module.css'


const AnswerContantCard = (props) => {

    return (
        <div className={style.answerdiv}>
            <div className={style.answeruser}>
                <img style={{ width: "50px", height:"50px", borderRadius: "50%" }} src={props.ans.user.user_image} alt="" />
                <p > {props.ans.user.first_name}</p>
            </div>
            <div>
                <p>{props.ans.message}</p>  
            </div>
        </div>
    );
}

export default AnswerContantCard;