import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CityName from '../../helperdata/TaiwanCityData'
import style from './register.module.css'
import { Link, useHistory } from 'react-router-dom';
import Facebook from '../Facebook';
import Google from '../Google'
import { config } from '../../setting';
import { useTranslation } from 'react-i18next';

const UserRegister = (props) => {

    const { t } = useTranslation();
    const [first_name, setFirst_name] = useState('');
    const [last_name, setLast_name] = useState('');
    const [region, setRegion] = useState('');
    const [city, setCity] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    //helper
    const [TaiwanRegion, setTaiwanRegion] = useState({});
    const [TaiwanCities, setTaiwanCities] = useState([]);
    //error message
    const [errorMessage, setErrorMessage] = useState('')

    const history = useHistory();

    function getTaiwanCities() {
        const TaiwanCity = CityName
        setTaiwanRegion(TaiwanCity)
    }

    function getCities() {
        if (region !== '') {

            var regionCity = TaiwanRegion[region]
            console.log(regionCity)
            setTaiwanCities(TaiwanRegion[region])

        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        const UserSingup = {
            first_name: first_name,
            last_name: last_name,
            country: 'Taiwan',
            region: region,
            city: city,
            email: email,
            password: password,
            user_image: '/asset/farmer.png'
        }

        await axios
            .post(config.url.NEW_API + '/users/register', UserSingup)
            .then(response => {
                console.log(response)
                //localStorage.setItem('usertoken', response.data.token)
                history.push('/user/please-verify')
            }).catch((e) => {
                console.log(e.response.data.error)
                setErrorMessage(e.response.data.error)
            })

        
    }

    useEffect(async () => {
        await getTaiwanCities()
    }, []);

    useEffect(async () => {
        await getCities()
    }, [region]);

    return (
        <div className={style.container}>
            <div style={{ backgroundImage: "url(/asset/registerbackground.png)" }} className={style.signup_wrapper}>
                <div className={style.signup_heading}>
                    <h1>Welcome to the community !</h1>
                </div>
                <div className={style.main_signup_form}>
                    <div className={style.left_signup_form}>
                        <img src={'/asset/registerImg.jpeg'} alt="" />
                    </div>
                    <div className={style.right_signup_form}>
                        <div className={style.signup_logo}>
                            <img src={'/asset/agrollyTaiwanlogo-white.png'} alt="" />
                        </div>
                        <div className={style.signup_form}>
                            <form onSubmit={onSubmit}>
                                <div className={style.half_field}>
                                    <label htmlFor="InputfirstName">{t('first-name')}</label>
                                    <input type="text" id="InputfirstName" 
                                        value={first_name} className={style.inputBox}
                                        onChange={e => setFirst_name(e.target.value)} />
                                </div>
                                <div className={style.half_field}>
                                    <label htmlFor="InputlastName" >{t('last-name')}</label>
                                    <input type="text" id="InputlastName"
                                        value={last_name} className={style.inputBox}
                                        onChange={e => { setLast_name(e.target.value) }} />
                                </div>
                                <div className={style.half_field} style={{width: "100%"}}>
                                    <label htmlFor="InputEmail" >{t('email')}</label>
                                    <input type="email" id="InputEmail" aria-describedby="emailHelp"
                                        value={email} className={style.inputBox}
                                        onChange={e => setEmail(e.target.value)} />
                                </div>


                                <div className={style.half_field}>
                                    <label htmlFor="InputPassword">{t('password')}</label>
                                    <input type="password" id="InputPassword" 
                                        value={password} className={style.inputBox}
                                        onChange={e => setPassword(e.target.value)} />
                                </div>

                                <div className={style.half_field} >
                                    <label  htmlFor="CoutrySelect">{t('region')}</label>
                                    <select style={{ backgroundImage: "url(/asset/vector.png)" }} id={style.city}
                                        defaultValue={region} 
                                        onChange={e => { setRegion(e.target.value) }}>
                                        <option >請選擇</option>
                                        <option value={"north"} >北區</option>
                                        <option value={"east"} >東區</option>
                                        <option value={"middle"} >中區</option>
                                        <option value={"south"} >南區</option>
                                        <option value={"external"} >外島</option>
                                    </select>
                                </div>

                                <div>
                                    {TaiwanCities.length === 0 ? null
                                        : <div style={{width: "100%"}} className={style.half_field} >
                                            <label htmlFor="exampleFormControlSelect1">{t('city')}</label>
                                            <select style={{ backgroundImage: "url(/asset/vector.png)"}}  id={style.city} 
                                            defaultValue={city} onChange={e => setCity(e.target.value)}>
                                                <option selected>Choose...</option>
                                                {TaiwanCities.map((city) => { return <option key={city} value={city}>{city}</option> })}
                                            </select>
                                        </div>}
                                </div>
                                {errorMessage && <div>{errorMessage}</div>}
                                <div style={{ textAlign: " center", padding: "3%", marginTop: "2%" }}>
                                    <input className={style.loginbtn} type="submit" value={t('sign-up')} />
                                </div>

                            </form>
                            <div style={{ textAlign: " center", padding: "3%" }} >
                                <div>
                                    <p> --- or log in with --- </p>
                                    <span style={{display:"inline"}}> 
                                        <Facebook />
                                        <Google/>
                                    </span>

                                </div>
                                <div>
                                    <p> --- Already have an account ? --- </p>

                                    <Link to="/user/login">
                                        <button className={style.sighupbtn} >
                                            {t('login')}
                                        </button>
                                    </Link>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default UserRegister;
