import axios from 'axios';
import React, { useState, useEffect } from 'react';
import UserCommentCard from './commentsCard';
import style from './comment.module.css'
import jwt_decode from 'jwt-decode';
import {config} from '../../../setting';


const CropComment = (props) => {
    const [messages, setMessage] = useState([])
    const [comment, setComment] = useState("")

    async function GetMessage() {

        axios.get(config.url.API_URL+'/message/crop/' + props.cropID)
            .then(response => {
                // console.log(response.data)
                setMessage(response.data)
            })
            .catch((error) => {
                console.log(error);
            })


    }

    const onSubmit = async (e) => {
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);

        const cropcommment = {
            crop_id: props.cropID,
            user_id: decoded.user_id,
            message: comment
        }

        await axios.post(config.url.API_URL+'/message/post', cropcommment)
            .then(res => {
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })


    }



    useEffect(() => {
        GetMessage()
    }, []);


    return (
        <div >
        <p className={style.messagetitle}>what others said? </p>
            <div className={style.details}> 
                {messages.map((message) => {
                    return <UserCommentCard key={message.id} megInfo={message} />
                })}
            </div>

            <div>
                <form onSubmit={onSubmit} >
                    <div className="form-floating">
                        <textarea className="form-control" value={comment} onChange={e => setComment(e.target.value)} placeholder="My comment..." style={{ "height": "100px" }}></textarea>

                    </div>
                    <div className="form-group">
                        <input type="submit" value="send" align="center" />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CropComment;