import React, { useEffect, useState } from 'react'
import style from './pestbaseInfo.module.css'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { config } from '../../setting';
import PestCrop from './PestCrops'

const PestbaseInfo = () => {

    const params = useParams();
    const [pestData, setPestData] = useState({})
    const [cropArray, setCropArraly] = useState([])

    const getPestOrigin = async () => {
        await axios.get(config.url.NEW_API + '/pestdisease/pest_crops/' + params.id)
            .then(response => {
                console.log(response.data)
                setPestData(response.data)
                setCropArraly(response.data.croppest)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const displaycrops = async () => {
        console.log("test function")
    }

    const NewHOC = (PassedComponent) => {
        return class extends React.Component {
            render() {
                return (
                    <div>
                        <PassedComponent {...this.props} />
                    </div>
                )
            }
        }
    }

    const Movie = ({ name }) => <div>{name}</div>

    const PestCrop = NewHOC(Movie);

    useEffect(() => {
        getPestOrigin()
    }, [])

    return (
        <div className={style.profileCardContainer}>
            <div className={style.cardChild}>
                <div>
                    <h3>{pestData.name}</h3>
                    <h5>{pestData.name_en}</h5>
                    <br></br>
                    {pestData.feature ?
                        <div>
                            <h5><b>特徵:</b> </h5>
                            {parse(`${pestData.feature}`)}
                        </div> : null
                    }

                </div>
                <div style={{textAlign:"center"}}>
                    <img src={pestData.pic_path} />
                </div>
            </div>

            {pestData.description ?
                <div>
                    <h5><b>出現:</b> </h5>
                    {parse(`${pestData.description}`)}
                </div> : null
            }
            {pestData.solution ?
                <div>
                    <h5><b>處理方法: </b></h5>
                    {parse(`${pestData.solution}`)}
                </div> : null
            }
            <br />
            <div>
                <h5><b>影響作物: </b></h5>
                <div className={style.cropCards}>
                    {cropArray.length > 0 ? cropArray.map((crop, index) => {
                        return (<div className={style.cropComponent}>
                            <div style={{ textAlign: "center" }}>
                                <img className={style.cropimg} width={150} src={crop.cropbase.crop_image} />
                            </div>
                            <h5>
                                {crop.cropbase.chinese_name} <br></br>
                                <small>
                                    {crop.cropbase.common_name}
                                </small>

                            </h5>

                        </div>)
                    }) : null
                    }
                </div>
            </div>


        </div>

    )
}

export default PestbaseInfo

