import React from 'react'
import style from '../homepage.module.css'

const TwContact = () => {
  return (
    <div className={style.news_letter} id="contact">
            <div className={style.main_box}>
                <div className={style.left_area_box} data-aos="fade-right" data-aos-duration="2000">
                    <img src="/asset/subscribeimage2.jpg" alt="newsletter image" />
                </div>
                <div className={style.right_area_box} data-aos="fade-left" data-aos-duration="2000">
                    <h1>Subscribe to Agrolly Taiwann</h1>
                    <p>comming up informations send to you by email</p>
                    <form>
                        <input type="text" name="" className={style.search_box} placeholder="Enter your Email ID..." />
                        <button className={style.sub_btn}>Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
  )
}

export default TwContact