import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import style from '../cropPage.module.css'
import SeasonDisplay from './seasonDisplay';

const CropBaseContent = (props) => {

    const [seasonArray, SetSeasonArray] = useState(props.seasonPlant)

    const readSeasonString = async() => {
       
        var seasonplant = props.seasonPlant
        console.log(seasonplant)
        SetSeasonArray(seasonplant)
        
        return seasonArray.map((season, index) =>{
            console.log(season)
            return <SeasonDisplay season={season}/>
        })
    }


    return (
        <div className = {style.CropBaseContent}>
            {parse(`${props.cropBaseContent}`)}
        </div>
    );
}

export default CropBaseContent;



