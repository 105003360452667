import { t } from 'i18next'
import React from 'react'
import style from './homepage.module.css'

const about = () => {
  return (
    <div className={style.about_section} id="about">
      <div className={style.about_image} data-aos="fade-right" data-aos-duration="1000">
        <img src="asset/aboutImage.png" className={style.about_image_1} />
      </div>
      <div className={style.content_area} data-aos="fade-left" data-aos-duration="1000">
        <div className={style.about_content}>
          <h1 data-aos="fade-right" data-aos-duration="1000">{t('about-1')}</h1>
          <hr className={style.divider_about} />
          <h2>All You Need To Know About Agrolly Taiwan</h2>
          <p>
            {t('about-3')}
          </p>
          <p>
            {t('about-4')}
          </p>
          <p>
            {t('about-5')}
          </p>
          <p>
            {t('about-6')}
          </p>


        </div>
      </div>
    </div>
  )
}

export default about