import React from 'react'
import i18next from 'i18next';
import style from './langage.module.css'

function Langage() {

    function LanguageClick(lang) {i18next.changeLanguage(lang)}

    return (
        <div>
            <ul className={`navbar-nav ${style.langagebar}`} >
                <li className="nav-item dropdown">
                    <a className={`nav-link dropdown-toggle ${style.langagedropdown}`} href="/" id="WeatherDropdown" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        Language
                    </a>
                    <div className="dropdown-menu" aria-labelledby="WeatherDropdown">
                        <button className="dropdown-item" onClick={() => { LanguageClick("en") }}>
                            English
                        </button>
                        <button className="dropdown-item" onClick={() => { LanguageClick("ch") }}>
                            中文
                        </button>
                    </div>
                </li>
            </ul>

        </div>
    )
}

export default Langage
