import React from 'react';
import PestDisplayCard from './pestDispalyCard';
import { useTranslation } from 'react-i18next';
// import style from './pest.module.css'
import style from '../cropPageStyle.module.css'



const PestCard = (props) => {
    const { t } = useTranslation();
   

    return (
        <div className={style.details_container}>
            <div>
                {(props.croppest.length === 0) ?
                    <div>
                        <div style={{ dispaly: "block", width: "100%" }}>
                            <p className={style.title}>{t('no-data-yet')}</p>
                        </div>
                        <img style={{ width: "300px" }} src="/asset/comingupsoon.png" alt="" />
                    </div>
                    :
                    <div className={style.scroll_area}>
                        {props.croppest.map((pest, index) =>{
                           return (<div><PestDisplayCard key={index} pest={pest}  /></div>)
                        })}
                    </div>
                }
            </div>
        </div>
    );
}

export default PestCard;