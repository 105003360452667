import React from "react";
import { useHistory } from "react-router-dom";
import style from "./forgotpassword.module.css";
import backgroundImage from "./forgetpasswordBack.png";

const EmailVerificationFail = () => {
  const history = useHistory();


  return (
    <div
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className={style.forgotpasswordback}
    >
      <div className={style.forgetpassword_Container}>
        <h1>Uh oh ...</h1> 
        <p>Something went wrong while trying to reset your password</p>
        <img style={{ margin: "4%" }} src="/asset/failImage.png" alt="" />
        <div>
        <button onClick={() => history.push("/user/register")}>
          Back to register
        </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationFail;
