import React, { useEffect, useState } from "react";
import style from "./plantedCropDetail.module.css";
import axios from "axios";
import { config } from "../../setting";
import { useHistory } from "react-router-dom";

import laData from "../../helperdata/latitude.json";
import Compress from 'compress.js';

import CropMeta from "./CropMeta";
import Dialog from "../layout/Dialog";
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import jwt_decode from 'jwt-decode';
import DiaryEdit from "./DiaryEdit";
import { useTranslation } from 'react-i18next';

const PlantedCropDetail = (props) => {

  const { t } = useTranslation();

  const [cropID, setCropId] = useState(1);
  const [common_name, setCommentName] = useState("");
  const [chinese_name, setChineseName] = useState("");
  const [crop_image, setCropImage] = useState("");
  const [maxTemp, setMaxTemp] = useState(0);
  const [minTemp, setMinTemp] = useState(0);
  const [kcdata, setkcValue] = useState([]);
  const [po_data, setPoData] = useState([])

  const [isOpen, setIsOpen] = useState(false)
  const [editisOpen, seteditIsOpen] = useState(false)

  const [start_date, setStartDate] = useState("");
  const [note, setNote] = useState("");
  const [field_size, setField_size] = useState(0);
  const [cropmeta, setCropMeta] = useState({});

  const [diaryDate, setDiaryDate] = useState(new Date());

  const [diaryContent, setDiaryContent] = useState("")
  const [file, setFile] = useState("")
  const [filename, setFilename] = useState("Choose File")

  const [diaryArray, setDiaryArray] = useState([])

  const history = useHistory();

  const getDetailPlantInfo = async () => {
    await axios
      .get(config.url.NEW_API + "/usercrop/" + props.match.params.id)
      .then((res) => {
        var usercrop = res.data;
        // console.log(res.data);
        setCropId(usercrop.cropId);
        setCommentName(usercrop.cropbase.common_name);
        setChineseName(usercrop.cropbase.chinese_name);
        setCropImage(usercrop.cropbase.crop_image);
        setMaxTemp(usercrop.cropbase.max_temp);
        setMinTemp(usercrop.cropbase.min_temp);
        setStartDate(usercrop.start_date);
        setNote(usercrop.note);
        setField_size(usercrop.field_size);
        setCropMeta(usercrop.meta);
      });
  };

  const getKcValue = async () => {
    await axios.get(config.url.NEW_API + "/kc/" + cropID).then((res) => {
      // console.log(res.data[0]);
      setkcValue(res.data[0]);
    });

    laData.map((la) => {
      if (la.city_long == "25") {
        setPoData(la)
        // console.log(la);
      }
    });
  };

  const deleteFromMyGarden = () => {
    axios
      .put(config.url.NEW_API + "/usercrop/delete/" + props.match.params.id, {
        uuid: props.match.params.id,
      })
      .then((res) => {
        history.push("/usercrop/garden");
      });
  };

  // const displayMeta = () => {
  //   // const kc = await getKcValue()
  //   // console.log(kc)

  //   if (Object.keys(cropmeta).length !== 0) {
  //     console.log(cropmeta);
  //     return (
  //       <div>
  //         <CropMeta cropmeta={cropmeta} kcdata={kcdata} po_data={po_data} />
  //       </div>
  //     );
  //   }
  // };


  const submitCropDiary = async () => {

    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);

    const date1 = new Date(start_date);
    const date2 = new Date(diaryDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log(diffTime + " milliseconds");
    // console.log(diffDays + " days");

    // console.log(file)

    const postDiary = await axios.post(config.url.NEW_API + '/diary/add', {
      cropId: cropID,
      usercropId: props.match.params.id,
      userId: decoded.uuid,
      content: diaryContent,
      date: diaryDate,
      days: diffDays
    })
      .then(response => {
        var responseObject = response.data
        responseObject.user = {
          first_name: decoded.first_name,
          user_image: decoded.user_image
        }
        responseObject.messages = []

        setDiaryArray([...diaryArray, responseObject])
        return response.data
      }).catch(err => {
        console.log(err)
      })

    if (file !== '') {
      const formData = new FormData();
      formData.append("file", file)

      await axios.put(config.url.NEW_API + '/diary/uploadimage/' + postDiary.id, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          // console.log(response.data)
          setDiaryArray(oldArray => [diaryArray, ...oldArray]);
        }).catch(err => {
          console.log(err)
        })
    }

    setTimeout(function () {
      setIsOpen(false)
    }, 3000);
  }

  const fileSelected = async (event) => {
    const image = event.target.files[0]

    const compressfile = await resizeImageFn(image)
    setFile(compressfile)
    setFilename(event.target.name)
  }


  const EditCropDiray = async (diaryId, returnContent, returnDate) => {

    const date1 = new Date(start_date);
    const date2 = new Date(returnDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    axios
      .put(config.url.NEW_API + "/diary/usercrop/edit/" + diaryId, {
        content: returnContent,
        date: returnDate,
        days: diffDays
      })
      .then((res) =>
        console.log(res.data)
      );
  }

  const DeleteCropDiray = async (diaryId) => {

    axios.delete(config.url.NEW_API + `/diary/usercrop/delete/${diaryId}`)
      .then(response => {
        // console.log(`Deleted post with ID ${diaryId}`);

        setDiaryArray(oldValues => {
          return oldValues.filter(diary => diary.id !== diaryId)
        })

      })
      .catch(error => {
        console.error(error);
      });

    seteditIsOpen(false)
  }

  async function resizeImageFn(file) {

    const compress = new Compress()

    const resizedImage = await compress.compress([file], {
      size: 1,
      quality: 0.8,
      maxWidth: 300,
      maxHeight: 300,
      resize: true
    })
    const img = resizedImage[0];
    const base64str = img.data
    const imgExt = img.ext
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    return resizedFiile;
  }

  const getDiary = async () => {
    await axios
      .get(config.url.NEW_API + "/diary/usercrop/" + props.match.params.id)
      .then((res) => {
        var diary = res.data;
        // console.log(diary)
        setDiaryArray(diary)
      });
  }

  useEffect(() => {
    getDetailPlantInfo();
    getKcValue();
    getDiary()
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   displayMeta();
  // }, [cropmeta]);

  return (
    <div >
      <h1>Instraction</h1>
      <div className={style.product_section}>
        <div className={style.carousel_container}>
          <div className={style.mySlides}>
            <img src={crop_image} alt="crops" />
          </div>
        </div>

        <div className={style.product_page}>
          <div className={style.product_top}>
            <div className={style.product_description}>
              <h1>
                {" "}
                {chinese_name} {common_name}{" "}
              </h1>
              <p>the date you start: {start_date}</p>
              <p>register field : {field_size}</p>
              {note === "" ? null : <p>note: {note}</p>}
              {/* </div>
            <div className={style.product_order}> */}
              <p>
                <b>Max Temp : </b> {maxTemp}
              </p>
              <p>
                <b>Min Temp : </b> {minTemp}
              </p>
              {/* <span> Estimated days to harvest </span> */}
            </div>
          </div>
          <div className={style.product_bottom}>
            <div className={style.top_garden_btn}>
              <button className={style.diarybtn} style={{ float: "right" }} onClick={(e) => setIsOpen(true)}>{t('write-diary')}</button>
              <Dialog isOpen={isOpen} onClose={(e) => setIsOpen(false)}>
                <Form onSubmit={submitCropDiary}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>圖片上傳</Form.Label>
                    <Form.Control onChange={e => fileSelected(e)} type="file" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>作物日誌</Form.Label>
                    <Form.Control value={diaryContent} onChange={e => { setDiaryContent(e.target.value) }} as="textarea" rows={8} />
                  </Form.Group>
                  <DatePicker onChange={setDiaryDate} value={diaryDate} />

                  <br />
                  <input type="submit" value="submit" className="btn btn-primary" />
                  {/* <button onClick={submitCropDiary} className="btn btn-primary">submit</button> */}
                </Form>
              </Dialog>
              <br />
              <div>
                {diaryArray.length > 0 ? <div>{diaryArray.map((diary, index) => {
                  return (
                    <div className={style.DiaryCard} key={index}>
                      <div style={{ textAlign: "center" }}>
                        <img src={diary.image} />
                      </div>
                      <div>
                        <small><DiaryEdit DeleteCropDiray={DeleteCropDiray} EditCropDiray={EditCropDiray} diary={diary} /></small>
                        <small>{new Date(diary.date).toDateString()}({t('plant-day')}: {diary.days})</small>
                        <p>{diary.content}</p>

                      </div>
                    </div>

                  )
                })}</div> : null}
              </div>

            </div>
            <div className={style.product_items}>
              <button className={style.diaryDeleteBtn} onClick={deleteFromMyGarden} type="submit">
                delete this crop
              </button>
              <h1>Weekly Irrigate</h1>
              <hr className={style.product_divider} />
              <div>{Object.keys(cropmeta).length !== 0 ? <div>
                <CropMeta cropmeta={cropmeta} kcdata={kcdata} po_data={po_data} />
              </div> : null}</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantedCropDetail;

// {cropmeta.InitWeeks.map((idx , week) => {

//   var avaragetemp = week.avgTemp;
//   var Days = week.Days;
//   var vegedata = kcdata;
//   var mouthPoparam = 0.1

//   // var requireIrrigation = Number.parseFloat( mouthPoparam * (0.46 * avaragetemp + 8) * vegedata * Days ).toFixed(2);

//   return (
//     <div>
//       <p>
//         {week.StartDay} - {week.EndDay} ({week.Days})
//       </p>
//       <p>
//         Temperature : {week.MinWeekTemp} ~ {week.MaxWeekTemp}{" "}
//       </p>
//       <p>Week Total Rainfall : {week.weekRainfall} </p>
//       <hr/>

//     </div>
//   );
// })}


