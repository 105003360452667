import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import style from './question.module.css'
import { Link } from 'react-router-dom';
import { config } from '../../setting';

import jwt_decode from 'jwt-decode';

const UserProfile = (props) => {
    const { t } = useTranslation();

    const [userInfo, setUserInfo] = useState({})

    function displayUser() {
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);
        // console.log(decoded)
        
        axios.get(config.url.NEW_API +'/users/' + decoded.uuid)
            .then((res) => {
                setUserInfo(res.data)
            })

        
        // setUserInfo(decoded)
    }

    useEffect(async () => {
        displayUser()
    }, []);

    return (
        <div>
            <div className={style.forum_profile_image} >
                <img src={userInfo.user_image} alt="" />
            </div>
            <div className={style.forum_profile_status}>
                <span>{userInfo.first_name} {userInfo.last_name}&nbsp;
                <Link to={"/user/profile"}> <i className="fas fa-edit" style={{ fontSize: "24px", color: "orange" }}></i></Link></span>
                <p>{t('location')}: {userInfo.country}, {userInfo.city}</p>
                <p>{t('position')}: {userInfo.position}</p>
                <p>{t('member')}: {userInfo.member}</p>
            </div>
        </div>
    );
}

export default UserProfile;