import React, { Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import { config } from "../../setting";

import TaiwanCityData from "../../helperdata/TaiwanCityData";
import { withTranslation } from "react-i18next";

import style from "./profileEdit.module.css";

class UserProfileEdit extends Component {
  constructor() {
    super();

    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeRegion = this.onChangeRegion.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangePosition = this.onChangePosition.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      uuid: "",
      first_name: "",
      last_name: "",
      country: "",
      region: "",
      city: "",
      phone: "",
      position: "",
      password: "",
      regionCity: ["Keelung","Taipei","Yilan","Hsinchu","Taoyuan","Miaoli","Taichung","Zhanghua","Yunlin","Nantou","Jiayi","Tainan","Kaohsiung","Pingtung","Taitung","Hualian"],
      regionChange: false,
      errors: {},
      image: "",
    };
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);

    // console.log(decoded)
    axios.get(config.url.NEW_API + "/users/" + decoded.uuid).then((res) => {
      console.log(res.data);
      this.setState({
        uuid: res.data.uuid,
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        country: res.data.country,
        region: res.data.region,
        city: res.data.city,
        phone: res.data.phone,
        position: res.data.position,
        image: res.data.user_image,
      });
    });

    // this.getCities();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.state.region !== prevProps.region && !this.state.regionChange) {
      var TaiwanCity = TaiwanCityData;
      var regionCities = TaiwanCity[this.state.region];
      this.setState({ regionChange: true, regionCity: regionCities });
    }
  }

  async getCities() {
    if (this.state.region !== "") {
      var regionCity = this.state.regionCity[this.state.region];
      console.log(regionCity);
    }
  }

  onChangeFirstName(e) {
    this.setState({
      first_name: e.target.value,
    });
  }
  onChangeLastName(e) {
    this.setState({
      last_name: e.target.value,
    });
  }

  onChangeCountry(e) {
    this.setState({
      country: e.target.value,
    });
  }
  onChangeRegion(e) {
    this.setState({
      region: e.target.value,
      regionChange: false,
    });
  }
  onChangeCity(e) {
    this.setState({
      city: e.target.value,
    });
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  onChangePosition(e) {
    this.setState({
      position: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    const UserEdit = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      country: this.state.country,
      region: this.state.region,
      city: this.state.city,
      phone: this.state.phone,
      position: this.state.position,
    };
    //console.log(restaurantSingup);
    axios
      .put(config.url.NEW_API + "/users/edit/" + decoded.uuid, UserEdit)
      .then((res) => console.log(res.data));

    //this.props.history.push('/restaurant/profile')
    window.location = "/user/profile";
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className={style.userInfoEditContainer}>
          <div className={style.userTitle}>
            <img src={this.state.image} alt="" />
            <h1>My Profile</h1>
          </div>

          <form onSubmit={this.onSubmit}>
            <div className={style.gridtwo}>
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={this.state.first_name}
                  onChange={this.onChangeFirstName}
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={this.state.last_name}
                  onChange={this.onChangeLastName}
                />
              </div>
            </div>

            <div className={style.gridtwo}>
              <div className="form-group">
                <label htmlFor="countryInput">Country</label>
                <select
                  className="form-control"
                  id="countryInput"
                  defaultValue={this.state.country}
                  onChange={this.onChangeCountry}
                >
                  <option value={"Taiwan"}>Taiwan</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="RegionInput">{t("region")}</label>
                <select
                  className="form-control"
                  id="RegionInput"
                  value={this.state.region}
                  onChange={this.onChangeRegion}
                >
                  <option>請選擇</option>
                  <option value={"north"}>北區</option>
                  <option value={"east"}>東區</option>
                  <option value={"middle"}>中區</option>
                  <option value={"south"}>南區</option>
                  <option value={"external"}>外島</option>
                </select>
              </div>
            </div>

            <div className={style.gridtwo}>
              <div>
                <div>
                  <label htmlFor="inputCity">City</label>
                  <select
                    className="form-control"
                    id="inputCity"
                    value={this.state.city}
                    onChange={this.onChangeCity}
                  >
                    <option selected>Choose...</option>
                    {this.state.regionCity.map((city) => {
                      return (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="positionInput">角色</label>
              <select
                className="form-control"
                id="positionInput"
                value={this.state.position}
                onChange={this.onChangePosition}
              >
                <option>請選擇</option>
                <option value={"farmer"}>小農</option>
                <option value={"specialist"}>學者</option>
                <option value={"indestry"}>廠商</option>
                <option value={"general"}>民眾</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="phoneInput">Phone Number</label>
              <input
                id="phoneInput"
                type="text"
                className="form-control"
                placeholder=""
                value={this.state.phone}
                onChange={this.onChangePhone}
              />
            </div>

            <div className={style.buttonContainer}>
              <Link className={style.cancelbtn} to={"/user/profile"}>
                Cancle
              </Link>
              <input
                className={style.submitbtn}
                type="submit"
                value="Save Change"
              />
            </div>
          </form>
        </div>

        <Link to={"/user/resetpassword/" + this.state.uuid}>
          password reset
        </Link>
      </div>
    );
  }
}

let userProfileEdit = withTranslation()(UserProfileEdit);

export default userProfileEdit;
