import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import EmailVerificationSuccess from './EmailVerificationSuccess'
import EmailVerificationFail from './EmailVerificationFail'
import { config } from '../../setting'

export const EmailverifyLandingPage = () => {

  const [isLoading , setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const {verificationString} = useParams();

  useEffect(() =>{
    const loadVerification = async () =>{
      try {
        console.log(verificationString)
        await axios.put(config.url.NEW_API+'/users/verify', {verificationString})
        setIsSuccess(true);
        setIsLoading(false)
      } catch (error) {
        setIsSuccess(false);
        setIsLoading(false)
      }
    }
    loadVerification()
  },[verificationString])

  if(isLoading) return <p>Loading ...</p>
  if(!isSuccess) return <EmailVerificationFail/>
  return <EmailVerificationSuccess/>

  

}
