import React from 'react'
import style from './homepage.module.css'
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next';

const Footer = () => {
    return (
        <div className={style.footer}>
            <div className={style.footer_area}>
                <div className={style.footer_box} data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
                    <h1>AGROLLY</h1>
                    <p>{t('footer-1')}</p>
                    <div className={style.footer_social_links}>
                        <a href="#"><FontAwesomeIcon icon="fa-brands fa-twitter" /></a>
                        <a href="#"><FontAwesomeIcon icon="fa-brands fa-instagram" /></a>
                        <a href="#"><FontAwesomeIcon icon="fa-brands fa-facebook" /></a>
                    </div>
                </div>
                <div className={ style.footer_company} data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
                    <li><Link href="/">{t('home')}</Link></li>
                    <li>
                        <LinkScroll
                            activeClass="active"
                            to="about"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={1000}>
                            {t('about-1')}
                        </LinkScroll>
                    </li>
                    <li><LinkScroll
                        activeClass="active"
                        to="service"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={1000}>
                        {t('service')}
                    </LinkScroll>
                    </li>
                    <li><LinkScroll
                        activeClass="active"
                        to="team"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={1000}>
                       {t('ourteam-1')}
                    </LinkScroll>
                    </li>
                    <li><LinkScroll
                        activeClass="active"
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={1000}>
                        {t('contact')}
                    </LinkScroll>
                    </li>
                </div>
                <div className={ style.footer_services} data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                    <h1>{t('footer-2')}</h1>
                    <li><a href="">{t('footer-3')}</a></li>
                    <li><a href="">{t('footer-4')}</a></li>
                    <li><a href="">{t('footer-5')}</a></li>

                </div>
                <div className={style.footer_address} data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                    <h1>{t('contact')}</h1>
                    <p>agrollytaiwan@gmail.com</p>
                    <p>New York ,USA</p>
                    {/* <p>(+1) 656 876565</p> */}
                </div>
            </div>
        </div>

    )
}

export default Footer
