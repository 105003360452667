import React, { Component } from 'react'
import axios from 'axios'
import EditCropCard from './editCropCard'
import { config } from '../../setting';

export default class EditCrops extends Component {

    constructor(props) {
        super(props);
        this.state =
        {
            crops: [],
        };
    }

    //got data from the database
    componentDidMount() {

        axios.get(config.url.API_URL+'/crops/')
            .then(response => {
                console.log(response.data)
                const crops = response.data;
                this.setState({ crops: crops });
            })
            .catch((error) => {
                console.log(error);
            })

    }

    CropsList() {
        return this.state.crops.map(crop => {
            if (crop.crop_id !== null) {
                return <EditCropCard key={crop.crop_id} crops={crop} />;
            }
        })
    }



    render() {

        return (
            <div >

                <div>
                    {this.CropsList()}
                </div>

            </div>
        );
    }
}
