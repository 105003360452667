const cities = {
    north: [
      "Keelung",
      "Taipei",
      "Yilan",
      "Hsinchu",
      "Taoyuan",
    ],
    south: [
      "Jiayi",
      "Tainan",
      "Kaohsiung",
      "Pingtung"
    ],
    middle: [
      "Miaoli",
      "Taichung",
      "Zhanghua",
      "Yunlin",
      "Nantou",
    ],
    east: [
      "Taitung",
      "Hualian",
    ],
    external: [
      "Penghu",
      "Kinmen"
    ],
  };
  
  export default cities;