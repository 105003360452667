import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'

const PleaseVerifyEmail = () => {
    const history = useHistory();

    useEffect(()=>{
        setTimeout(() =>{
            history.push('/user/login')
        },3000)
    },[history])

  return (
    <div>
       <h1>Thanks for Signing up</h1>
       <p>A verification email has been sent to the email addree you provided.  Please verify your eamil to unlock full site features. </p> 
    </div>
  )
}

export default PleaseVerifyEmail