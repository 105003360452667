import React, { useEffect } from 'react'
import style from './twhomepage.module.css'
import AOS from 'aos';

const TwService = () => {
    useEffect(() => {
        AOS.init({
            duration: 1500
        });
    }, []);

    return (
        <div className={style.our_services} id="service">
            <h1>服務項目</h1>
            <hr className={style.divider_services} />
            <p>我們提供的服務</p>
            <div className={style.service_item} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
                <img src="/asset/FarmerForum.png" />
                <h2>農業討論區</h2>
                <p>您並不用獨自面對，Agrolly Taiwna 建造農用社群，你可以問問題，回答別人的問題，得到幫助中也幫助其他小農</p>
            </div>
            <div className={style.service_item} data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
                <img src="/asset/CropManagement.png" />
                <h2>作物管理</h2>
                <p>作物管理使用國際 FAO 標準計算，計算作物灌溉所需水量, 檢視作物適合溫度，也提供作物病蟲害及肥料相關資訊，讓辛苦的農夫都能最快得到第一手資訊</p>
            </div>
            <div className={style.service_item} data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
                <img src="/asset/weatherForcast.png" />
                <h2>天氣預測</h2>
                <p>Agrolly 使用 NASA power 資料進行氣候大數據分析，並進行氣候長期預測，也使用ＩＢＭ weather company 提供的天氣資料，提供短期，中期，長期等天氣預測.</p>
            </div>
            <div style={{ clear: "both" }}></div>
        </div>
    )
}

export default TwService