import React, { useState } from "react";
import style from "./homepage.module.css";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(null);

  // "https://gmail.us14.list-manage.com/subscribe/post?u=ee813c064d55e1c9b3d5490ae&amp;id=7bf5577de0&amp;f_id=006695e0f0"
  return (
    <div className={style.news_letter} id="contact">
      <div className={style.main_box}>
        <div
          className={style.left_area_box}
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <img src="/asset/subscribeimage2.jpg" alt="newsletter image" />
        </div>
        <div
          className={style.right_area_box}
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <h1>{t("subscribe")} Agrolly Taiwan</h1>
          <p>comming up informations send to you by email</p>
          <form
            action="https://gmail.us14.list-manage.com/subscribe/post"
            method="POST"
            noValidate
          >
            <input type="hidden" name="u" value="ee813c064d55e1c9b3d5490ae" />
            <input type="hidden" name="id" value="7bf5577de0" />

            <input
              type="email"
              name="EMAIL"
              id="MERGE0"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              autoCapitalize="off"
              autoCorrect="off"
              placeholder="Your email address"
              className={style.sub_input}
            />
            <div>
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className={style.sub_btn}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
