import React from 'react'
import style from './twhomepage.module.css'

const TwHowitwork = () => {
    return (
        <div className={style.Gridlove_section}>
            <div className={style.number_title} data-aos="flip-down" data-aos-duration="2000">
                <h1>如何使用</h1>
            </div>
            <div className={style.main_grid_love} data-aos="fade-right" data-aos-duration="2000">
                <div className={style.left_girdlove}>

                    <div className={style.circle_sidelove}>1</div>
                    <div className={style.description_sidelove}>
                        <h3>選擇你想種植的作物</h3>
                        <p>so far agrolly Taiwan have 40 crop for you to choose, and we keep collecting more</p>
                    </div>

                    <div className={style.circle_sidelove}>2</div>
                    <div className={style.description_sidelove}>
                        <h3>選擇你所在的地點</h3>
                        <p>Agrolly use the longitude and latitude to predict crop reqirment</p>
                    </div>

                    <div className={style.circle_sidelove}>3</div>
                    <div className={style.description_sidelove}>
                        <h3>選擇種植日期</h3>
                        <p>choose a starting day you want to start plant you crop</p>
                    </div>

                    <div className={style.circle_sidelove}>4</div>
                    <div className={style.description_sidelove}>
                        <h3>開始計算</h3>
                        <p>Agrolly Taiwan do the heavy lifting to calculate the reqirement for your crop</p>
                    </div>

                </div>
            </div>
            <div className={style.right_sidelove} data-aos="fade-left" data-aos-duration="2000">
                <img src="/asset/4step.png" />
            </div>
            <div style={{ clear: "both" }}></div>
        </div>
    )
}

export default TwHowitwork