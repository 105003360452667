import { t } from 'i18next'
import React from 'react'
import style from './ibmdata.module.css'

const HourlyCard = (props) => {
    const iconPath = `/weatherIcon/${props.daydata.icon_code}.svg`
    const time = new Date(props.daydata.fcst_valid_local)
    return (
        <div className={style.weaterCard}>
            <p>{time.getHours()}:00</p>
            <div className={style.weatherImage}>
                <img src={iconPath} alt="" />
            </div>
            <p>{props.daydata.phrase_32char}</p>
            {/* <p>{props.daydata.dow} {props.daydata.daypart_name}</p> */}
            <p>{props.daydata.temp} &deg;</p>
           
        </div>

    )
}

export default HourlyCard
