import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll"
import { withTranslation } from "react-i18next";
import style from './Navbar.module.css'
import { HashLink } from 'react-router-hash-link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class Navbar extends Component {

    constructor(props) {
        super();
        this.handleClick = this.handleClick.bind(this);
        this.state = { 
            isToggleOn: false 
        };
    }


    handleClick() {
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn
        }));
       // console.log(this.state.isToggleOn)
    }


    logOut(e) {
        localStorage.removeItem("usertoken");
        window.location = "/";
    }

    render() {
        const { t } = this.props;
        var loginRegLink = (
            <div className={style.links} id={this.props.isMenuOpen ? style.hidden : ""}>
                <Link to="/" onClick={this.props.onToggleMenu}>
                    {t("home")}
                </Link>
                <HashLink smooth to="/#about" onClick={this.props.onToggleMenu}>
                    {t("about")}
                </HashLink>
                <HashLink smooth to="/#service"onClick={this.props.onToggleMenu}>
                    {t("service")}
                </HashLink>
                <HashLink smooth to="/#contact" onClick={this.props.onToggleMenu}>
                    {t("contact")}
                </HashLink>
                <Link to="/user/login" onClick={this.props.onToggleMenu}>
                    {t("login")}
                </Link>

            </div>
        );

        var userLink = (
            <div className={style.links} id={this.props.isMenuOpen ? style.hidden : ""}> 
                <Link  to="/" onClick={this.props.onToggleMenu}>
                    {t("home")}
                </Link>
                <Link  to="/weather" onClick={this.props.onToggleMenu}>
                    {t("weather-data")}
                </Link>
                <Link  to="/questions" onClick={this.props.onToggleMenu}>
                    {t("forum")}
                </Link>
                <Link  to="/cropInfo" onClick={this.props.onToggleMenu}>
                    {t("crop-risk")}
                </Link>
                <Link  to="/usercrop/garden" onClick={this.props.onToggleMenu}>
                    {t("profile")}
                </Link>
                <a onClick={this.logOut.bind(this)} href="/">
                    {t("logout")}
                </a>
            </div>
        );



        return (
            <div className={style.NavbarContainer}>
                <div className={style.leftNav}>
                    <div>
                        <img className={style.logoImage} src="/asset/logo.png" />
                    </div>
                </div>
                <div className={style.rightNav} >
                        {localStorage.usertoken ? userLink : loginRegLink}
                    <button onClick={this.props.onToggleMenu}><FontAwesomeIcon icon="fas fa-bars" /></button>
                </div>
            </div>
        );
    }
}
let Nav = withTranslation()(Navbar);

export default Nav;