import React, { useEffect, useState } from 'react'
import axios from 'axios';
import WeeklyCard from './weeklyCard';
import style from './ibmdata.module.css';
import HourlyCard from './HourlyCard';
import jwt_decode from 'jwt-decode';

import { useTranslation } from 'react-i18next';

const IbmWeeklyData = (props) => {
    const { t } = useTranslation();

    const [DayArray, setDayArray] = useState([])
    const [HourlyArray, setHourlyArray] = useState([]);
    const [userlocaion, setUserlocation] = useState({});

    const [today, setToday] = useState("")
    const [tomorrow, setTomorrow] = useState("")
    const [tomorrowHourly, setTomorrowHourly] = useState([]);


    const handleIBMWeekly = async (location) => {

        var IBMCallApi = await IBMAPICall(location);
        // console.log(IBMCallApi)
        var toArrayofObject = await WeekArrayToDayObject(IBMCallApi.data)
        // console.log(toArrayofObject)
        setDayArray(toArrayofObject)

    };

    const handleIBMData = async (citylatlng) => {

        var IBMCallApi = await IBMAPIDailyCall(citylatlng);
        //console.log(IBMCallApi.data.forecasts)
        const todayHourlyWeather = [];
        const tomorrowWeather = [];

        for (var i = 0; i < 4; i++) {
            todayHourlyWeather.push(IBMCallApi.data.forecasts[i])
        }

        setHourlyArray(todayHourlyWeather)
        var todayTime = todayHourlyWeather[0].fcst_valid_local
        //console.log(todayTime)
        var today = new Date(todayTime).toLocaleDateString();
        setToday(today)
        //console.log(today)

        for (var i = 4; i < 8; i++) {
            tomorrowWeather.push(IBMCallApi.data.forecasts[i])
        }

        setTomorrowHourly(tomorrowWeather)

        var tomorrowTime = todayHourlyWeather[1].fcst_valid_local
        var tomorrow = new Date(tomorrowTime).toLocaleDateString();
        setTomorrow(tomorrow)

    };

    const IBMAPIDailyCall = async (citylatlng) => {
        //console.log(citylatlng)
        const languageList = "zh-TW"
        const APIkey = "ac5187d09e6a438a9187d09e6a138a16"
        const base_url = `https://api.weather.com/v1/geocode/${citylatlng.Latitude}/${citylatlng.Longitude}/forecast/intraday/3day.json?units=m&language=${languageList}&apiKey=${APIkey}`
        return await axios.get(base_url)

    }

    const IBMAPICall = async (citylatlng) => {
        //console.log(userlocaion)
        const languageList = "zh-TW"
        const APIkey = "ac5187d09e6a438a9187d09e6a138a16"
        const base_url = `https://api.weather.com/v3/wx/forecast/daily/7day?geocode=${citylatlng.Latitude},${citylatlng.Longitude}&format=json&units=e&language=${languageList}&apiKey=${APIkey}`
        return await axios.get(base_url)

    }

    const WeekArrayToDayObject = (CallIBMapi) => {
        const weatherData = CallIBMapi
        var datalong = 8;
        var dayData = [];
        for (var i = 1; i < datalong; i++) {
            var dayAndNigth = i * 2
            dayData.push({
                dayOfWeek: weatherData.dayOfWeek[i],
                sunriseTimeLocal: weatherData.sunriseTimeLocal[i],
                sunsetTimeLocal: weatherData.sunsetTimeLocal[i],
                temperatureMax: weatherData.temperatureMax[i],
                temperatureMin: weatherData.temperatureMin[i],
                precipitation: weatherData.qpf[i],
                narrative: weatherData.narrative[i],
                daypartNameDay: weatherData.daypart[0].daypartName[dayAndNigth],
                daypartNameNight: weatherData.daypart[0].daypartName[dayAndNigth + 1],
                iconDay: weatherData.daypart[0].iconCode[dayAndNigth],
                iconNight: weatherData.daypart[0].iconCode[dayAndNigth + 1],
                narrativeDay: weatherData.daypart[0].narrative[dayAndNigth],
                narrativeNight: weatherData.daypart[0].narrative[dayAndNigth + 1],
                windSpeedDay: weatherData.daypart[0].windSpeed[dayAndNigth],
                windSpeedNight: weatherData.daypart[0].windSpeed[dayAndNigth + 1],
                windDirectionDay: weatherData.daypart[0].windDirectionCardinal[dayAndNigth],
                windDirectionNight: weatherData.daypart[0].windDirectionCardinal[dayAndNigth + 1],
            })
        }

        console.log(dayData)
        return dayData
    }

    const getUserInformationn = async () => {
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);

        const userlatlng = {
            Taitung: { city: 'Taitung', Latitude: 22.7613, Longitude: 121.1438 },
            Hualian: { city: 'Hualian', Latitude: 23.9911, Longitude: 121.6112 },
            Miaoli: { city: 'Miaoli', Latitude: 24.5602, Longitude: 120.8214 },
            Taichung: { city: 'Taichung', Latitude: 24.1477, Longitude: 120.6736 },
            Zhanghua: { city: 'Zhanghua', Latitude: 24.0717, Longitude: 120.5624 },
            Yunlin: { city: 'Yunlin', Latitude: 23.7092, Longitude: 120.4313 },
            Nantou: { city: 'Nantou', Latitude: 23.918, Longitude: 120.6775 },
            Keelung: { city: 'Keelung', Latitude: 25.1276, Longitude: 121.7392 },
            Taipei: { city: 'Taipei', Latitude: 25.033, Longitude: 121.5654 },
            Yilan: { city: 'Yilan', Latitude: 22.6158, Longitude: 120.712 },
            Hsinchu: { city: 'Hsinchu', Latitude: 24.8138, Longitude: 120.9675 },
            Taoyuan: { city: 'Taoyuan', Latitude: 24.9554, Longitude: 121.23 },
            Jiayi: { city: 'Jiayi', Latitude: 23.4801, Longitude: 120.4491 },
            Tainan: { city: 'Tainan', Latitude: 22.9999, Longitude: 120.2269 },
            Kaohsiung: { city: 'Kaohsiung', Latitude: 22.6273, Longitude: 120.3014 },
            Pingtung: { city: 'Pingtung', Latitude: 22.6558, Longitude: 120.4703 },
            Penghu: { city: 'Penghu', Latitude: 23.5833, Longitude: 119.5833 },
            Kinmen: { city: 'Kinmen', Latitude: 24.4943, Longitude: 118.4163 }
        }

        //console.log(userlatlng[decoded.city])
        setUserlocation(userlatlng[decoded.city])
        return await userlatlng[decoded.city]
        // return axios.get(config.url.API_URL + '/user/' + decoded.user_id)
        // .then((res)=>{
        //     
        // })

    }



    useEffect(async () => {
        const citylatlng = await getUserInformationn();
        // var citylatlng = props.location
        setTimeout(async () => {
            await handleIBMData(citylatlng)
            await handleIBMWeekly(citylatlng)
        }, 1000);

    }, []);

    return (

        <div>

            <div className={style.IBMWeatherContent}>
                <div className={style.dayWeatherData}>
                    <h4>today</h4>
                    <div className={style.hourlyContainer}>
                        {
                            HourlyArray.map((day, index) => {
                                return <HourlyCard key={index} daydata={day} />
                            })}
                    </div>
                    {/* <div className={style.moredailyinfo}>
                    <small>{t('sun-rise')}: {new Date(DayArray[0].sunriseTimeLocal).getHours() + ":" + new Date(DayArray[0].sunriseTimeLocal).getMinutes()}</small>
                    <small>{t('sun-set')}: {new Date(DayArray[0].sunsetTimeLocal).getHours() + ":" + new Date(DayArray[0].sunsetTimeLocal).getMinutes()}</small>
                    <small>{t('rain')}: {DayArray[0].precipitation} mm</small>
                    <small>{t('windspeed')}: {DayArray[0].windSpeedDay}</small>
                    <small>{t('wind_direction')}: {DayArray[0].windDirectionDay}</small>
                </div> */}

                    <h4>tomorrow</h4>
                    <div className={style.hourlyContainer}>
                        {
                            tomorrowHourly.map((day, index) => {
                                return <HourlyCard key={index} daydata={day} />
                            })}
                    </div>

                    {/* <div className={style.moredailyinfo}>
                    <small>{t('sun-rise')}: {new Date(DayArray[1].sunriseTimeLocal).getHours() + ":" + new Date(DayArray[0].sunriseTimeLocal).getMinutes()}</small>
                    <small>{t('sun-set')}: {new Date(DayArray[1].sunsetTimeLocal).getHours() + ":" + new Date(DayArray[0].sunsetTimeLocal).getMinutes()}</small>
                    <small>{t('rain')}: {DayArray[1].precipitation} mm</small>
                    <small>{t('windspeed')}: {DayArray[1].windSpeedDay}</small>
                    <small>{t('wind_direction')}: {DayArray[1].windDirectionDay}</small>
                </div> */}
                </div>

                <hr />

                <h4>this week </h4>
                <div className={style.weekdataContainer}>
                    {
                        DayArray.map((day, index) => {
                            return <WeeklyCard key={index} daydata={day} />
                        })}
                </div>
                <hr />
            </div>
        </div>

    )
}

export default IbmWeeklyData