import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

import style from './question.module.css'
import AnswerContantCard from './answerContantCard';
import UserImageCard from './userCard';
import { config } from '../../setting';

import { useTranslation } from 'react-i18next';

const QuestionCard = (props) => {

    const { t } = useTranslation();
    const [answer, setAnswer] = useState("")
    const [ansArray, setAnsArray] = useState([])
    const [show, setShow] = useState(false)
    const [answerlen, setAnswerlen] = useState(0)

    function questionAnswer() {
        axios.get(config.url.NEW_API+'/message/answer/' + props.q.uuid)
            .then(res => {
                // console.log(res.data)
                setAnsArray(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    function showClick() {
        if(show === false){
            questionAnswer()
        }
        setShow(!show)
    }


    async function onSubmit(e) {
        e.preventDefault();
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);

        const answerComment = {
            questionId: props.q.uuid,
            UserUuid: decoded.uuid,
            message: answer
        }

        await axios.post(config.url.NEW_API+'/message/answerpost', answerComment)
            .then(res => {
                // console.log(res.data)
                var addnewpost = res.data.post
                addnewpost.user = {
                    first_name:decoded.first_name,
                    user_image:decoded.user_image
                }
                // console.log(addnewpost) 
                setAnsArray([...ansArray, addnewpost])
                setAnswerlen(answerlen+1)
            })
            .catch(err => {
                console.log(err)
            })

        setAnswer("");

    }


    useEffect(() => {
        setAnswerlen(props.q.messages.length)
    }, []);


    return (
        <div>
            <button className={style.questionnbutton} onClick={showClick}>
                <div className={style.textContent}>
                    <div>
                        <UserImageCard user={props.q.user} />
                    </div>

                    <div className={style.contentOnly}>
                        <h5>{props.q.content}</h5>
                        {/* <small>{new Date(props.q.createdAt).toLocaleDateString()}</small> */}
                        <div >
                            <img className={style.questionImage} src={props.q.image_path} alt="" />
                        </div>

                        <div className={style.commonCount}>
                            <div className={style.commonCountinner}>
                                <i className="far fa-comment fa-lg"></i> {answerlen}
                            </div>
                        </div>
                    </div>

                </div>

            </button>

            {show ?
                <div>
                    {ansArray.map((ans, i) => {
                        return <AnswerContantCard key={i} ans={ans} />
                    })}
                </div> 
            : null}

            <div>
                <form onSubmit={onSubmit} >
                    <div className={style.answertextarea}>
                        <textarea className="form-control" value={answer} onChange={e => setAnswer(e.target.value)} placeholder={t('answer-question')} ></textarea>
                    </div>
                    <div className={style.submitbtn}>
                        <input type="submit" value="Send" align="center" />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default QuestionCard;