import React, { useEffect, useState } from "react";
import InfoTitleCard from "./InfoTitleCard";
import style from "./croplist.module.css";
import axios from "axios";
import { useTranslation } from "react-i18next";


import jwt_decode from 'jwt-decode';
import { config } from "../../setting";

const Croplist = () => {
  const [crops, setCrop] = useState([]);
  const [type, setType] = useState(""); 
  const [waterSensitive, setWaterSensity] = useState(0);
  const [month, setMonth] = useState(0);
  const [season, setSeason] = useState('')
  const [likeList, setLikeList] = useState([])

  const getCropList = () => {
    axios
      .get(config.url.NEW_API + "/crops/list")
      .then((response) => {
        var cropsList = response.data;
        setCrop([])
        setCrop(cropsList);
        setType('')
        setWaterSensity(0)
        setSeason('')
        console.log(cropsList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLikeList = () =>{
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);

    axios.get(config.url.NEW_API +'/like/'+ decoded.uuid)
    .then((response) =>{
        // console.log(response.data)
        setLikeList(response.data)
    })
  }

  const OnClickSearch = () => {
    
    var params = {
        type: type,
        water_sensitive: parseInt(waterSensitive),
        season: season
    }
    console.log(params)

    // if(params.type !== '' && (params.water_sensitive !== 0 || params.water_sensitive !== null) && params.season !==''){

      console.log(params)

      axios.get(config.url.NEW_API +'/crops/search',{params})
      .then((response) =>{
          console.log(response.data)
          setCrop([])
          setCrop(response.data)
          setType('')
          setWaterSensity(0)
          setSeason('')


      })
  // }
  };

  useEffect(() => {
    getCropList();
    getLikeList()
  }, []);

  return (
    <div className={style.main_search_wrapper}>
      <h1>Search my crop</h1>
      <div className={style.search_wrapper}>
        <div className={style.search_filter}>
        
            <div className={style.search_section_1}>
              <div className={style.search_heading}>
                <h1>Filter</h1>
              </div>
              {/* <div className={style.search_timing}>
                <p>Planting Timing</p>
                <a href="">
                  {" "}
                  <i className="far fa-smile" aria-hidden="true"></i>
                </a>
                <a href="">
                  <i className="far fa-frown" aria-hidden="true"></i>
                </a>
              </div> */}
              <div className={style.each_search_field}>
                <label htmlFor="regionSelect">Water sensitivity:</label>
                <select style={{ backgroundImage: "url(/asset/vector.png)" }} id={style.city} value={waterSensitive} onChange={(e)=>{setWaterSensity(e.target.value)}}>
                  <option value="0" >Choose...</option>
                  <option value="1">low</option>
                  <option value="2">medium</option>
                  <option value="3">high</option>
                </select>
              </div>

              <div className={style.each_search_field}>
                <label htmlFor="regionSelect">season:</label>
                <select style={{ backgroundImage: "url(/asset/vector.png)" }} id={style.city} value={season} onChange={(e)=>{setSeason(e.target.value)}}>
                  <option value="">Choose...</option>
                  <option value="春">春</option>
                  <option value="夏">夏</option>
                  <option value="秋">秋</option>
                  <option value="冬">冬</option>
                </select>
              </div>
            </div>

            <div className={style.search_section_2}>
              <div className={style.search_form}>
                <div className={style.search_corp_form}>
                  <div className={style.each_search_field}>
                    <label htmlFor="regionSelect">Type</label>
                    <select style={{ backgroundImage: "url(/asset/vector.png)" }}id={style.city} value={type} onChange={(e) => setType(e.target.value)}>
                      <option value="">Choose...</option>
                      <option value="Small Vegetables">Small Vegetables</option>
                      <option value="Vegetables - Solanum Family"> Vegetables - Solanum Family</option>
                      <option value="Vegetables - Cucumber Family"> Vegetables - Cucumber Family</option>
                      <option value="Roots and Tubers">Roots and Tubers</option>
                      <option value="Legumes (Leguminosae)">Legumes (Leguminosae)</option>
                      <option value="Perennial Vegetables">Perennial Vegetables</option>
                      <option value="Fibre Crop">Fibre Crop</option>
                      <option value="Oil Crop">Oil Crop</option>
                      <option value="Cereals">Cereals</option>
                      <option value="Forages">Forages</option>
                      <option value="Sugar Cane">Sugar Cane</option>
                      <option value="Tropical Fruits and Trees">Tropical Fruits and Trees</option>
                      <option value="Grapes and Berries">Grapes and Berries</option>
                      <option value="Fruit Trees">Fruit Trees</option>
                      <option value="Wetlands - Temperate Climate">Wetlands - Temperate Climate</option>
                    </select>
                  </div>
                  {/* <div className={style.each_search_field}>
                    <label htmlFor="regionSelect">Planting Month:</label>
                    <select
                      style={{ backgroundImage: "url(/asset/vector.png)" }}
                      id={style.city}
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option selected>Choose...</option>
                      <option value="0">January</option>
                      <option value="1">February</option>
                      <option value="2">March</option>
                      <option value="3">April</option>
                      <option value="4">May</option>
                      <option value="5">June</option>
                      <option value="6">July</option>
                      <option value="7">August</option>
                      <option value="8">September</option>
                      <option value="9">November</option>
                      <option value="10">December</option>
                    </select>
                  </div> */}
                </div>
              </div>
            </div>
            <div>
              <button
                className={style.searchBtn}
                type="submit"
                id="sendIcon"
                value="Search"
                align="center"
                onClick={OnClickSearch}
              > search</button>
          
              <span>{" "}</span>
            <button
                className={style.searchBtn}
                type="submit"
                id="sendIcon"
                value="Search"
                align="center"
                onClick={getCropList}
              > clear</button>
            </div>
         
        </div>

        <div className={style.search_content}>
          {crops.map((crop, index) => {
            return <InfoTitleCard key={index} crops={crop} likeList={likeList}/>;
          })}
        </div>
      </div>
    </div>
  );
};

export default Croplist;
