import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import style from './forgotpassword.module.css'
import backgroundImage from './forgetpasswordBack.png'


const PasswordResetSuccess = () => {
  const history = useHistory()
  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }} className={style.forgotpasswordback} >
      <div className={style.forgetpassword_Container}>
        <h1>Reset your password</h1>
        <p><b>Reset password successfully. Please log in</b></p>
        <p><b>你已成功更新密碼. 你可以開始用新密碼登入</b></p>
        <img style={{ margin: "4%" }} src="/asset/correctImage.png" alt="" />
        <div>
          <button className={style.sendEmailbtn} onClick={() => history.push('/user/login')}>Go to login</button>
        </div>

      </div>
    </div>
  )
}

export default PasswordResetSuccess