//https://www.youtube.com/watch?v=75aTZq-qoZk

import React, { useState } from 'react'
import GoogleLogin from 'react-google-login';
import axios from 'axios';
import style from './user/login.module.css'
import { config } from '../setting';

const Google = () => {

  const [loginData, setLoginData] = useState(
    localStorage.getItem('usertoken') ? JSON.parse(localStorage.getItem('usertoken')) : null
  );

  const handleFailure = (reslult) => {
    console.log(reslult)
    alert(reslult);
  }

  const handleLogin = async (googleData) => {
    console.log(googleData)
    const res = axios.post(config.url.NEW_API+'/users/google-login', {
      token: googleData.tokenId
    }, {
      headers: { 'Content-Type': 'application/json' }
    });

    const data = await res
    console.log(data.data.token)
    setLoginData(data.token);
    localStorage.setItem('usertoken', data.data.token)
    window.location = "/user/profile"
  }

  return (
    
      <GoogleLogin
        clientId={"88082592858-sd3aeh7i1kah1uqrthdc3eugitmv82l2.apps.googleusercontent.com"}
        buttonText="Log in with Google"
        onSuccess={handleLogin}
        onFailure={handleFailure}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <button className={style.sighupwith} onClick={renderProps.onClick} > <img src={'/asset/SighUpwithgoogle.png'} alt="" /></button>
        )}
      >

      </GoogleLogin>
  )
}

export default Google
