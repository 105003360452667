import React, { useEffect, useState } from 'react';
import style from './homepage.module.css'


const TeamMemberCard = (props) => {


    return (
        <div className={style.main_profile} data-aos="zoom-in-up" data-aos-duration="2000">
            <div className={style.profile_image}>
                <img src={props.image} width="180" height="180" />
                <p className={style.profile_name}>{props.name}</p>
                <p className={style.profile_designation}>{props.position}</p>
            </div>
            <div className={style.profile_social_links}>
                <span><img src="/asset/facebook.png" style={{ height: "24px", width: "24px" }} /></span>
                <span><img src="/asset/twitter.png" style={{ height: "24px", width: "24px" }} /></span>
                <span><img src="/asset/instagram.png" style={{ height: "24px", width: "24px" }} /></span>
            </div>
        </div>
    );
}

export default TeamMemberCard;