import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import style from './question.module.css'


const UserImageCard = (props) => {

    const user = props.user;
   


    return (
        <div>
            <div style={{textAlign:'center'}}>
                <img style={{width:"80px", height:"80px", borderRadius:"100%", display:"inline-block"}} src={user.user_image} alt="" />
                <p>{user.first_name}</p>
            </div>
            {/* {(userId !== decoded.user_id)?<button>+ fellow</button>: null} */}
            {/* <button>+ fellow</button> */}

        </div>
    );
}

export default UserImageCard;