import './App.css';
import { useState} from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'dotenv/config'

import homePage from "./component/homepage/mainhomepage";
import about from './component/homepage/about'
import service from './component/homepage/service'
import contact from './component/homepage/contact'
import team from './component/homepage/team'
import infoList from "./component/cropInfo/croplist";
import commingup from './component/homepage/commingup'

import twhomePage from './component/homepage/homepageTW/twhome'

// import cropCreate from './component/cropCreate/cropCreate'
import cropPage from './component/cropInfo/cropPage'
import EditCrops from './component/cropCreate/editCrops';
import EditCropTemplate from './component/cropCreate/editCropTempate';

import register from './component/user/register'
import login from './component/user/login'
import profile from './component/user/userProfile'
import profileEdit from './component/user/profileEdit'
import forgetPassword from './component/user/Forgotpassword'
import passwordResetLanding from './component/user/passwordResetLanding';
import passwordResetSuccess from './component/user/passwordResetSuccess'
import passwordResetFail from './component/user/passwordResetFail';
import userUpdatePassword from './component/user/userUpdagePassword'

import farmerQuestions from './component/farmerQuestions/farmerQuestions'
import UserQuestion from './component/farmerQuestions/userQuestion'
import UserCropAdd from './component/userCrop/userCropAdd'
import UserGarden from './component/userCrop/userGarden';

import Weather from './component/weatherPages/weatherPage'

import Loader from './component/loader/loader2';

import Navbar from './component/navbar';
import Langage from './component/langage/langage'
import PleaseVerifyEmail from './component/user/PleaseVerifyEmail';
import PlantInstraction from './component/userCrop/PlantedCropDetail'
import { EmailverifyLandingPage } from './component/user/EmailverifyLandingPage';

import PestProfile from './component/PestProfile/pestbaseInfo'

function App(props) {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  


  return (
    <div className="App">

      <Router>
        <Navbar isMenuOpen={isMenuOpen} onToggleMenu={() => setIsMenuOpen(!isMenuOpen)}/>
        <Langage />
        <Route path="/" exact component={homePage} />
        <Route path="/about" component={about} />
        <Route path="/service" component={service} />
        <Route path="/contact" component={contact} />
        <Route path="/team" component={team} />
        <Route path="/commingup" component={commingup} />

        <Route path="/tw/home" exact component={twhomePage} />

        <Route path="/user/register" component={register} />
        <Route path="/user/please-verify" component={PleaseVerifyEmail} />
        <Route path="/user/verify-email/:verificationString" component={EmailverifyLandingPage} />
        <Route path="/user/login" component={login} />
        <Route path="/user/profile" component={profile} />
        <Route path="/user/edit" component={profileEdit} />
        <Route path="/user/forgotpassword" component={forgetPassword} />
        <Route path="/user/passwordReset/:passwordResetCode" component={passwordResetLanding} />
        <Route path="/user/passwordResetSuccness" component={passwordResetSuccess} />
        <Route path="/user/passwordResetFail" component={passwordResetFail} />
        <Route path="/user/uploadPassword/:id" component={userUpdatePassword} />
      

        <Route path="/cropInfo" component={infoList} />

        {/* <Route path="/cropCreate" component={cropCreate} /> */}
        <Route path="/cropPage/:id" component={cropPage} />
        <Route path="/editcrop" component={EditCrops} />
        <Route path="/editPage/:id" component={EditCropTemplate} />

        <Route path="/pestprofile/:id" component={PestProfile} />

        <Route path="/questions" component={farmerQuestions} />
        <Route path="/user/question" component={UserQuestion} />

        <Route path="/usercrop/add/:id" component={UserCropAdd} />
        <Route path="/usercrop/garden" component={UserGarden} />
        <Route path="/plantedcrop/detail/:id" component={PlantInstraction}/>
        
        <Route path="/weather" component={Weather} />

        <Route path='/loader' component={Loader} />
      </Router>
    </div>
  );
}

export default App;
