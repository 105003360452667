import React from 'react'
import { Line, Bar } from 'react-chartjs-2'
import { Chart as ChartJS } from "chart.js/auto"
import style from './annual.module.css'

const AnnualLineChart = (props) => {
    return (
        <div className={style.charContainer} >
            <div>
                <button className={style.cardsBtn} onClick={props.ChartDisplay}>chart</button>
                <button className={style.chartBtn} onClick={props.CardsDispay}>card</button>
            </div>
            <div className={style.graphBoarder}>
                <Line data={props.chartdata} />
            </div>
            <div className={style.graphBoarder}>
                <Bar data={props.bardata} />
            </div>


        </div>

    )
}

export default AnnualLineChart
