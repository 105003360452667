import React from 'react';
import style from './card.module.css'
import { useTranslation } from 'react-i18next';

const IrrigationDisplayCard = (props) => {

    const { t } = useTranslation();
    const thismouthData = props.thismouth
    const squareSize = props.unit
    const unit = squareSize != 0 ? Number.parseInt(squareSize * thismouthData.irrigation) : thismouthData.irrigation

    return (
        <div className={style.stage_no}>
            <div className={style.monthContentCards}>
                <p>{thismouthData.thisMount} <small>({thismouthData.days} days)</small></p>
                <p>{t('temperature')} :
                    <span style={thismouthData.mintemp > props.minT ? { color: "#1E90FF" } : { color: "red" }}> {thismouthData.mintemp}&deg;C</span><span>-</span>
                    <span style={thismouthData.maxtemp < props.maxT ? { color: "#1E90FF" } : { color: "red" }}> {thismouthData.maxtemp}&deg;C</span>
                </p>
                <p>{t('rain')}: {thismouthData.rainfall} <small>mm</small></p>
                <p style={thismouthData.irrigation < thismouthData.rainfall ? { color: "#1E90FF" } : { color: "red" }} >{t('water-need-unit')}:{thismouthData.irrigation} <small>mm</small></p>
                {
                    squareSize == null ?  "": <p>{props.area} {t('square-meter')}:{unit}<small>mm</small></p>
                }
                
            </div>
        </div>
    );
}

export default IrrigationDisplayCard;