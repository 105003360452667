import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
//import FacebookLogin from 'react-facebook-login';
import style from './user/login.module.css'
import axios from 'axios';
import { config } from '../setting';

const Facebook = () => {
    const [login, setLogin] = useState(false);
    const [data, setData] = useState({});
    const [picture, setPicture] = useState('');

    const responseFacebook = async (response) => {
        console.log(response);
        setData(response);
        setPicture(response.picture.data.url);
        if (response.accessToken) {

            var name = response.name.split(' ')
            const UserData = {
                first_name: name[0],
                last_name: name[1],
                email: response.email,
                user_image: response.picture.data.url,
                token: response.userID
            }

            console.log(UserData)

            setLogin(true);
            const res = axios.post(config.url.NEW_API+'/users/facebook-login', UserData, {
                headers: { 'Content-Type': 'application/json' }
            });

            const data = await res
            console.log(data)

            localStorage.setItem('usertoken', data.data.token)
            window.location = "/user/profile"

        } else {
            setLogin(false);
        }
    }

    const onClickFacebook = async (response) => {
        console.log("click")
    }


    return (

        <FacebookLogin
            appId="145679258589798"
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            onClick={() => onClickFacebook}
            render={renderProps => (
                <button className={style.sighupwith} onClick={renderProps.onClick}><img src={'/asset/SighUpwithfacebook.png'} alt="" /></button>
            )} />
    )
}

export default Facebook
