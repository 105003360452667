import React from 'react'
import { useTranslation } from 'react-i18next';
import style from './ibmdata.module.css'

const WeeklyCard = (props) => {

    const { t } = useTranslation();

    function fToC(fahrenheit) {
        var fTemp = fahrenheit;
        var fToCel = (fTemp - 32) * 5 / 9;
        var message = fTemp + '\xB0F is ' + fToCel + '\xB0C.';
        return fToCel.toFixed(0);

    }

    const weathercontent = props.daydata.narrative.split('。');


    const DayIconPath = `/weatherIcon/${props.daydata.iconDay}.svg`
    const NightIconPath = `/weatherIcon/${props.daydata.iconNight}.svg`


    const MaxTempInC = fToC(props.daydata.temperatureMax)
    const MinTempInC = fToC(props.daydata.temperatureMin)
    const sunRisetime = new Date(props.daydata.sunriseTimeLocal).getHours() + ":" + new Date(props.daydata.sunriseTimeLocal).getMinutes()
    const sunSetime = new Date(props.daydata.sunsetTimeLocal).getHours() + ":" + new Date(props.daydata.sunsetTimeLocal).getMinutes()
    return (
        <div>
            <p>{props.daydata.dayOfWeek}</p>
            <div className={style.IconContainer}>
                <div className={style.weatherImage}>
                    <img src={DayIconPath} alt="" />
                </div>
                <div className={style.nightImage}>
                    <img src={NightIconPath} alt="" />
                </div>
            </div>

            <p>{weathercontent[0]}</p>
            <p> {MinTempInC} - {MaxTempInC} &deg;C</p>
            <p>{props.daydata.precipitation} mm</p>
            <p>{sunRisetime}-{sunSetime}</p>
            <p>{props.daydata.windSpeedDay}</p>
            <p>{props.daydata.windDirectionDay}</p>


        </div>
    )
}

export default WeeklyCard
