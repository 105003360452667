import React from 'react'
import style from './twhomepage.module.css'
import TeamMemberCard from '../teamMemberCard';

const Twteam = () => {
 
    return (
     <div className={style.our_team} style={{ height: "auto" }} id='team'>
        <h1 data-aos="flip-down" data-aos-duration="2000">團隊</h1>
        <hr className={style.divider_team} />
        <div className={style.profile_area}>
          <TeamMemberCard name={"Ajinkya Datalkar"} position={"顧問"} image={"/asset/teamMember/AjinkyaDatalkar.png"} />
          <TeamMemberCard name={"Helen Tsai"} position={"軟體開發"} image={"/asset/teamMember/HelenTsai.png"} />
          <TeamMemberCard name={"Te-Hsin Tsai"} position={"UI UX 設計"} image={"/asset/teamMember/GraceTsai.png"} />
          <TeamMemberCard name={"Luke Lin"} position={"數據分析師"} image={"/asset/teamMember/LukeLin.png"} />
        </div>
      </div>
  )
   
}

export default Twteam
