import React from "react";

const MetaDisplay = (props) => {

    const rain = (props.week.weekRainfall).toFixed(2)
  return (
    <div>
      <div>
        <p>
          {props.week.StartDay} - {props.week.EndDay} ({props.week.Days})
        </p>
        <p>
          Temperature : {props.week.MinWeekTemp} ~ {props.week.MaxWeekTemp}{" "}
        </p>
        <p>Week Total Rainfall : {rain} </p> 
        <p>crop water requirement : {props.requireIrrigation}</p>
      </div>
    </div>
  );
};

export default MetaDisplay;
