import axios from 'axios';
import React, { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import style from './question.module.css'
import UserProfile from './userProfile';
import QuestionCard from './questionCard';
import { Link } from 'react-router-dom';
import { config } from '../../setting';
import { t } from 'i18next';

const UserQuestion = (props) => {
    const [myQuestion, SetMyQuestions] = useState([]);

    const getuserQuestion = () => {
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);
        axios.get(config.url.NEW_API+'/question/posts/' + decoded.uuid)
            .then((q) => {
                SetMyQuestions(q.data)
                console.log(q.data)
            })
    }


    useEffect(async () => {
        await getuserQuestion()
    }, []);


    return (
        <div className={style.forum_wrapper}>
            <div className={style.forum}> 
                <div className={style.left_forum}>
                    <div >
                        <UserProfile />
                    </div>
                </div>
                <div className={style.forum_questions}>
                    <h1>{t('forum')}</h1>
                    <div className={style.lets_talk}>
                        <div>
                             <h4>My Question</h4>
                        </div>
                        <div >
                            <Link className={style.myquestionInAll} to={"/questions"}>{t('all')}</Link>
                            <Link className={style.allquestioninAll}  to={"/user/question"}>{t('my-questions')}</Link>
                        </div>
                    </div>
                    
                    <div>
                        {myQuestion.length === 0 ? <p>{t('no-question')}</p>
                            : <div>
                            {
                                myQuestion.map((q, i) => { 
                                    return <QuestionCard q={q} key={i} />
                                })
                            }
                        </div>}
                    </div>


                </div>
            </div>
        </div>
    );
}

export default UserQuestion;