import React from "react";
import style from "./userGarden.module.css";
import { useHistory } from "react-router-dom";

const GardenCropCard = (props) => {
  const gardencrop = props.crop;
  const history = useHistory();

  const clicktoReadMore = () => {
    history.push("/plantedcrop/detail/" + gardencrop.uuid);
  };

  return (
    <div>
      <div className={style.garden_details}>
        <div className={style.top_garden}>
          <div className={style.top_garden_name}>
            <p>{gardencrop.cropbase.common_name}</p>
          </div>
          <div className={style.top_garden_btn}>
            <button onClick={clicktoReadMore} type="submit">
              Read more
            </button>
          </div>
        </div>
        <div className={style.bottom_garden}>
          <div className={style.bottom_garden_img}>
            <img
              src={gardencrop.cropbase.crop_image}
              width="150px"
              height="150px"
              alt="your crop"
            />
          </div>
          <div className={style.bottom_garden_details}>
            <p>your start date: {gardencrop.start_date}</p>
            <p>field size:{gardencrop.field_size} hectare</p>
            {/* <p>Growing Stage: Stage 2 Week 1</p>&nbsp; */}
            <div className={style.stageContainer}>
              {gardencrop.meta.InitWeeks ? (
                <div className={style.in_same_line}>
                  <div className={style.stageCard}>
                    <img
                      src="/gardenImage/Initalstage.svg"
                      alt="Inital"
                    />
                    {gardencrop.meta.InitWeeks[0].StartDay}
                  </div>
                </div>
              ) : null}
              {gardencrop.meta.developWeeks ? (
                <div className={style.in_same_line}>
                  <div className={style.stageCard}>
                    <img
                      src="/gardenImage/developstage.svg"
                      alt="develop"
                    />
                    {gardencrop.meta.developWeeks[0].StartDay}
                  </div>
                </div>
              ) : null}
              {gardencrop.meta.midWeeks ? (
                <div className={style.in_same_line}>
                  <div className={style.stageCard}>
                    <img src="/gardenImage/midstage.svg" alt="mid stage" />
                    {gardencrop.meta.midWeeks[0].StartDay}
                  </div>
                </div>
              ) : null}
              {gardencrop.meta.lastweeks ? (
                <div className={style.in_same_line}>
                  <div className={style.stageCard}>
                    <img src="/gardenImage/laststage.svg" alt="last stage" />
                    {gardencrop.meta.lastweeks[0].StartDay}
                  </div>
                </div>
              ) : null}
              {gardencrop.meta.harvestWeeks ? (
                <div className={style.in_same_line}>
                  <div className={style.stageCard}>
                    <img src="/gardenImage/harvest-stage.png" alt="harvest" />
                    {gardencrop.meta.harvestWeeks[0].StartDay}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GardenCropCard;
