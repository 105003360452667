import React, { useState } from 'react';
import style from './cropCreate.module.css'

const KCvalueCal = (props) => {

    const [reco_month, setReco_month] = useState(0);
    const [country, setCountry] = useState('');
    const [location, setLocation] = useState('');
    const [status, setStatus] = useState('');
    const [version, setVersion] = useState('');

    const [ISkc, SetIS] = useState(0.0);
    const [DSkc, SetDS] = useState(0.0);
    const [Midkc, SetMid] = useState(0.0);
    const [Latekc, SetLate] = useState(0.0);

    const [ISday, SetISDay] = useState(0);
    const [DSday, SetDSDay] = useState(0);
    const [Midday, SetMidDay] = useState(0);
    const [Lateday, SetLateDay] = useState(0);

    function KCvalueCalulate() {
        var today = new Date();
        var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        var getTodayDate = today.getDate();
        var thismouth = lastDayOfMonth - getTodayDate

        const growingArray = []
        if (ISday !== 0) {
            growingArray.push(ISday)
        }

        if (DSday !== 0) {
            growingArray.push(DSday)
        }

        if (Midday !== 0) {
            growingArray.push(Midday)
        }

        if (Lateday !== 0) {
            growingArray.push(Lateday)
        }

        //console.log(growingArray)

        const kcArray = [];
        if (ISkc !== 0) {
            kcArray.push(ISkc)
        }

        if (DSkc !== 0) {
            kcArray.push(DSkc)
        }

        if (Midkc !== 0) {
            kcArray.push(Midkc)
        }

        if (Latekc !== 0) {
            kcArray.push(Latekc)
        }

        //console.log(kcArray)


        periodToMouth(growingArray, kcArray);

        // const CrateKCValue = {
        //     reco_month: reco_month,
        //     country: country,
        //     location: location,
        //     status: status,
        //     inital_kc: ISkc,
        //     dev_kc: DSkc,
        //     mid_kc: Midkc,
        //     last_kc: Latekc,
        //     inital_days: ISday,
        //     dev_days: DSday,
        //     mid_days: Midday,
        //     last_days: Lateday,
        //     version: version,
        // }


    }

    function periodToMouth(growingArray, kcArray) {

        var state = 0

        var eachmouth = [];
        //get the first mouth's remain day
        var today = new Date();
        var FristMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        var getTodayDate = today.getDate();
        var mouthsRemain = FristMonth - getTodayDate

        eachmouth.push(mouthsRemain)


        var kcToMouth = []

        var preKcSum = 0
        var mouthCount = 1;

        var periodgrow = growingArray[state]
        var currentKc = kcArray[state]

        while (state < growingArray.length) {

            if (mouthsRemain <= periodgrow) { //當期比較多天的狀況下

                //所有當月的天數乘上 Kc
                var mouthKc = parseFloat((mouthsRemain * currentKc).toPrecision(4)) + preKcSum
                kcToMouth.push(mouthKc)

                preKcSum = 0;

                periodgrow = periodgrow - mouthsRemain

                mouthsRemain = getNextMouthDate(mouthCount)
                if (state === growingArray.length - 1) {
                    eachmouth.push(periodgrow)
                } else {
                    eachmouth.push(mouthsRemain)
                }

                mouthCount++

            } else { //當月剩下的天數(最多31天) 大於本期所需要的天數
                preKcSum = preKcSum + parseFloat((periodgrow * currentKc).toPrecision(4))
                mouthsRemain = mouthsRemain - periodgrow
                state++
                periodgrow = growingArray[state]
                currentKc = kcArray[state]

            }

        }
        kcToMouth.push(preKcSum)
        console.log(kcToMouth)
        console.log(eachmouth)
        eachMouthKc(kcToMouth, eachmouth)
    }

    function getNextMouthDate(mouthCount) {
        var today = new Date();
        var MonthDate = new Date(today.getFullYear(), today.getMonth() + 1 + mouthCount, 0).getDate();

        return MonthDate;
    }

    function eachMouthKc(kcToMouth, eachmouth) {
        var mouthKc = []
        for (let i = 0; i < eachmouth.length; i++) {
            mouthKc.push(parseFloat((kcToMouth[i] / eachmouth[i]).toPrecision(3)))
        }
        console.log(mouthKc)

    }


    return (
        <div >
            <div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="inputGroup-sizing-default">Country</span>
                    <input value={country} onChange={e => setCountry(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="inputGroup-sizing-default">Location</span>
                    <input value={location} onChange={e => setLocation(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                </div>
                <div className="input-group mb-3">
                    <label className="input-group-text" htmlFor="inputGroupSelect01">Recomment Growing month</label>
                    <select value={reco_month} onChange={e => setReco_month(e.target.value)} defaultValue={"0"} className="form-control" id="inputGroupSelect01">
                        <option value="0" >January</option>
                        <option value="1" >February</option>
                        <option value="2" >March</option>
                        <option value="3" >April</option>
                        <option value="4" >May</option>
                        <option value="5" >June</option>
                        <option value="6" >July</option>
                        <option value="7" >August</option>
                        <option value="8" >September</option>
                        <option value="9" >October</option>
                        <option value="10" >November</option>
                        <option value="11" >December</option>
                    </select>
                </div>
                <div className="input-group mb-3">
                    <label className="input-group-text" htmlFor="inputStatusSelect">Status</label>
                    <select value={status} onChange={e => setStatus(e.target.value)} defaultValue={"0"} className="form-control" id="inputStatusSelect">
                        <option value="General" >General</option>
                        <option value="Dry" >Dry</option>
                    </select>
                </div>
            </div>
            <div className={style.growingAndKc}>
                <div className={style.databox}>
                    <h3>
                        growing period
                    </h3>

                    <div className="input-group mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-default">IS</span>
                        <input value={ISday} onChange={e => SetISDay(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-default">DS</span>
                        <input value={DSday} onChange={e => SetDSDay(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-default">Mid</span>
                        <input value={Midday} onChange={e => SetMidDay(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-default">Late</span>
                        <input value={Lateday} onChange={e => SetLateDay(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                    </div>
                </div>



                <div className={style.databox}>
                    <h3>
                        Kc
                    </h3>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-default">IS</span>
                        <input value={ISkc} onChange={e => SetIS(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-default">DS</span>
                        <input value={DSkc} onChange={e => SetDS(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-default">Mid</span>
                        <input value={Midkc} onChange={e => SetMid(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-default">Late</span>
                        <input value={Latekc} onChange={e => SetLate(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                    </div>

                </div>
            </div>

            <div className="input-group mb-3">
                <span className="input-group-text" id="inputGroup-sizing-default">Version</span>
                <input value={version} onChange={e => setVersion(e.target.value)} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
            </div>



            <div className={style.calculateContainer}>
                <button onClick={() => KCvalueCalulate()} type="button" className="btn btn-success">calculate Kc</button>
            </div>


        </div>
    )
}



export default KCvalueCal;