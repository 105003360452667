

const prod = {
  url: {
    API_URL: "https://www.agrollytaiwan.com/cropdb",
    NEW_API: "https://www.agrollytaiwan.com/cropdb"
  },
};

const dev = {
  url: {
    API_URL: "http://localhost:5000",
    NEW_API: "http://localhost:5000",
    FACEBOOK : "3448311322094815"
  },
};

export const config = prod
