import React, { useEffect, useState } from "react";
import axios from "axios";

import { useTranslation } from "react-i18next";
import style from "./cropPageStyle.module.css";
import { Link } from "react-router-dom";

import Irrigation from "./CropInfoCard/irrigation";
import PestCard from "./CropInfoCard/pestCard";
import FertilizeCard from "./CropInfoCard/fertilizeCard";
import CropComment from "./CropInfoCard/comment";

import CropBase from "./CropInfoCard/cropBase";

import { config } from "../../setting";

const CropPage = (props) => {
  const { t } = useTranslation();
  const [cropBaseData, SetCropBaseData] = useState({});
  const [show, setShow] = useState("");
  const [passdata, setpassData] = useState({
    text: "",
  });

  const irrigationHandleClick = () => {
    setShow("irrigation");
  };

  const pestHandleClick = () => {
    setShow("pest");
  };

  const fertilizeHandleClick = () => {
    setShow("fertilize");
  };

  const commentHandleClick = () => {
    setShow("comment");
  };

  const contentShow = () => {
    if (show === "irrigation") {
      return (
        <Irrigation
          kcValue={cropBaseData.kcvalue}
          cropId={cropBaseData.id}
          maxT={cropBaseData.max_temp}
          minT={cropBaseData.min_temp}
          distance={cropBaseData.distance}
        />
      );
    } else if (show === "pest") {
      return <PestCard croppest={cropBaseData.croppest} />;
    } else if (show === "fertilize") {
      return <FertilizeCard cropfer={cropBaseData.fertilize} />;
    } else if (show === "comment") {
      return <CropComment cropID={cropBaseData.fertilize} />;
    } else {
      return <CropBase seasonPlant={cropBaseData.seasonplant} cropBaseContent={cropBaseData.Description}  />;
    }
  };

  useEffect(() => {
    getCropBaseInfo();
    window.scrollTo(0, 0);
  }, []);

  const getCropBaseInfo = () => {
    axios
      .get(config.url.NEW_API + "/crops/detailinfo/" + props.match.params.id)
      .then((response) => {
        console.log(response.data);
        SetCropBaseData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={style.main_wrapper_item}>
      <div className={style.top_item}>
        <div className={style.item_image}>
          <img src={cropBaseData.crop_image} alt="" />
        </div>
        <div className={style.item_details}>
          <h2 style={{ fontSize: "40px" }}>
            {cropBaseData.chinese_name} {cropBaseData.common_name}
          </h2>
          <p>
            {t("scientific-name")} : <span>{cropBaseData.bio_name}</span>
          </p>
          <p>
            {t("type")} : <span>{cropBaseData.type}</span>
          </p>
          <p>
            {t("water-sensitivity")} :{" "}
            <span>{cropBaseData.water_sensitive}</span>
          </p>
          <p>
            {t("adapt-temp")} :{" "}
            <span>
              {" "}
              {cropBaseData.min_temp}&deg;C ~{cropBaseData.max_temp}&deg;C
            </span>
          </p>
          <p>
            {t("best-timing")} :{" "}
            <span>
              {cropBaseData.reco_start} to {cropBaseData.reco_end}
            </span>
          </p>
          <p>
            {t("grow-day")} : <span>{cropBaseData.total_grow_time}</span>{" "}
            {/* {t("day")} */}
          </p>
          
        </div>
      </div>

      <div className={style.middle_tabs}>
        <button
          className={show === "irrigation" ? style.tabs_toggle : style.tab_btn}
          onClick={irrigationHandleClick}
        >
          {t("irrigation")}
        </button>
        <button
          className={show === "pest" ? style.tabs_toggle : style.tab_btn}
          onClick={pestHandleClick}
        >
          {t("pest-disease")}
        </button>
        <button
          className={show === "fertilize" ? style.tabs_toggle : style.tab_btn}
          onClick={fertilizeHandleClick}
        >
          {t("fertilizer")}
        </button>
        <button
          className={show === "comment" ? style.tabs_toggle : style.tab_btn}
          onClick={commentHandleClick}
        >
          {t("comment")}
        </button>
      </div>

      <div>{contentShow()}</div>

      <div className={style.bottom_tabs}>
        <Link className={style.active_btn2} to={"/cropInfo"}>
          {t("not-my-veg")}
        </Link>
        <Link
          className={style.tab_btn2}
          to={{
            pathname: "/usercrop/add/" + cropBaseData.id,
            state: {
              data: passdata,
            },
          }}
        >
          {t("add-to-garden")}
        </Link>
      </div>
    </div>
  );
};

// let cropPage = withTranslation()(CropPage);
export default CropPage;
// export default cropPage;
