import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const kcDisplay = (props) => {


    return (

        <div >
            <Link >edit</Link>
            <table>
                <tr>
                    <th></th>
                    <th>Kc</th>
                    <th>Day</th>
                </tr>
                <tr>
                    <td>Inital</td>
                    <td>{props.kc.inital_days}</td>
                    <td>{props.kc.inital_kc}</td>
                </tr>
                <tr>
                    <td>Develop</td>
                    <td>{props.kc.dev_days}</td>
                    <td>{props.kc.dev_kc}</td>
                </tr>
                <tr>
                    <td>Middle</td>
                    <td>{props.kc.mid_days}</td>        
                    <td>{props.kc.mid_kc}</td>
                </tr>
                <tr>
                    <td>Last</td>
                    <td>{props.kc.last_days}</td>
                    <td>{props.kc.last_kc}</td>
                </tr>

            </table>

        </div>

    );
}

export default kcDisplay;