import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import HttpApi from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";

const Languages = ["en", "ch", "in"];

//check here https://www.youtube.com/watch?v=w04LXKlusCQ

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "ch",
    supportedLngs: Languages,
    detection:{
        order: ['cookie', 'htmlTag' , 'localStorage', 'path', 'subdomain'],
        caches: ['cookie']
    },
    backend:{
        loadPath: '/locales/{{lng}}/translation.json',
    },

  });

export default i18n;
