import { t } from 'i18next';
import React from 'react'
import style from './homepage.module.css'
import TeamMemberCard from './teamMemberCard';

const team = () => {
  return (
     <div className={style.our_team} style={{ height: "auto" }} id='team'>
        <h1 data-aos="flip-down" data-aos-duration="2000">{t('ourteam-1')}</h1>
        <hr className={style.divider_team} />
        <div className={style.profile_area}>
          <TeamMemberCard name={"Ajinkya Datalkar"} position={t('ourteam-2')} image={"/asset/teamMember/AjinkyaDatalkar.png"} />
          <TeamMemberCard name={"Helen Tsai"} position={t('ourteam-3')}  image={"/asset/teamMember/HelenTsai.png"} />
          <TeamMemberCard name={"Te-Hsin Tsai"} position={t('ourteam-4')}  image={"/asset/teamMember/GraceTsai.png"} />
          <TeamMemberCard name={"Luke Lin"} position={t('ourteam-5')}  image={"/asset/teamMember/LukeLin.png"} />
        </div>
      </div>
  )
}

export default team