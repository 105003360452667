import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { config } from '../../setting';
import AnnualLineChart from './annualLineChart';
import style from './annual.module.css'
import jwt_decode from 'jwt-decode';
import AnnualCard from './annualCard';

const Annual = (props) => {
    const { t } = useTranslation();
    const [region, setRegion] = useState("")
    const [cities, setCities] = useState(["Keelung", "Taipei", "Yilan", "Hsinchu", "Taoyuan", "Miaoli", "Taichung", "Zhanghua", "Yunlin", "Nantou"])
    const [city, setCity] = useState("")
    const [searchMonth, setSearchMonth] = useState(0)

    const [dispaly, setDisplay] = useState("chart")

    const [forecast, setForecast] = useState([])
    const [po_place, setPo_place] = useState(0)

    const [MaxTemp, setMaxTemp] = useState({
        labels: [],
        datasets: []
    })

    const [rainfall, setRainfall] = useState({
        labels: [],
        datasets: []
    })

    const monthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


    const findCitiesAndPoCode = async () => {
        if (region != "") {
            axios.get(config.url.NEW_API + '/forecast/cities/' + region)
                .then(response => {
                    //console.log(response.data)
                    var city = []
                    for (let i = 0; i < response.data.length; i++) {
                        city.push({ City: response.data[i].City, Code: response.data[i].Code, po: response.data[i].po_place })
                    }
                    setCities(city)
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const getforcaseDataOrigin = async (usercity) => {
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);
        const userRegion = decoded.region
        const userCity = decoded.city


        await axios.get(config.url.NEW_API + '/forecast/cities/' + userRegion)
            .then(response => {
                //console.log(response.data)
                var city = []
                for (let i = 0; i < response.data.length; i++) {
                    city.push({ City: response.data[i].City, Code: response.data[i].Code, po: response.data[i].po_place })
                }
                setCities(city)
            })
            .catch((error) => {
                console.log(error);
            })

        await cities.map(async (city) => {
            if (userCity == city.City) {
                console.log(city)
                await setCity(city)
            }
        })

        if (city !== "") {
            var thisyear = 0

            await axios.get(config.url.NEW_API + '/forecast/' + userRegion)
                .then(response => {
                    const date = "";
                    var forcase = []
                    var responseForcaseData = []
                    responseForcaseData = response.data
                    console.log(responseForcaseData);
                    var jsonCity = JSON.parse(city).Code
                    //console.log(jsonCity)

                    var po_place = JSON.parse(city).po
                    setPo_place(po_place)


                    responseForcaseData.map((data, index) => {
                        const date = data["Date.fcst"]
                        const AVAtemperature = "TEMPMEDIA_fcast_" + jsonCity
                        const rainfall = "SNfcast_" + jsonCity + ".mean"
                        const maxTemp = "TEMPMAX_fcast_" + jsonCity
                        const minTemp = "TEMPMIN_fcast_" + jsonCity
                        const midTemp = "TEMPMEDIA_fcast_" + jsonCity
                        const Icon = "icon_" + jsonCity
                        const rainorNot = "rained_" + jsonCity + "_yN"
                        const rainmm = "fcast_class_" + jsonCity

                        let dateFormate = new Date(date)


                        // console.log(data[minTemp])
                        if (dateFormate.getMonth() + 1 == searchMonth && (thisyear == 0 || thisyear == dateFormate.getFullYear())) {
                            if (thisyear == 0) {
                                thisyear = dateFormate.getFullYear();
                            }
                            forcase.push({
                                Index: index,
                                Date: date,
                                avgTemp: data[AVAtemperature],
                                rainfall: data[rainfall],
                                maxTemp: data[maxTemp],
                                minTemp: data[minTemp],
                                midTemp: data[midTemp],
                                Icon: data[Icon],
                                rainorNot: data[rainorNot],
                                rainmm: data[rainmm]
                            })
                        }

                        setForecast(forcase)

                        setMaxTemp({
                            labels: forcase.map((theDate) => theDate.Date),
                            datasets: [{
                                label: `${t('max-temp')}`,
                                data: forcase.map((Temp) => Temp.maxTemp),
                                backgroundColor: ["#EF4D3C"],
                                borderColor: ["#EF4D3C"],
                                tension: 0.3
                            }, {
                                label: `${t('min-temp')}`,
                                data: forcase.map((Temp) => Temp.minTemp),
                                backgroundColor: ["rgb(75, 192, 192)"],
                                borderColor: ["rgb(75, 192, 192)"],
                                tension: 0.3
                            }]

                        })

                        setRainfall({
                            labels: forcase.map((theDate) => theDate.Date),
                            datasets: [{
                                label: `${t('rain')} (mm)`,
                                data: forcase.map((Temp) => Temp.rainfall),
                                backgroundColor: ["#0064E5"],
                                tension: 0.1
                            }]

                        })

                    })
                    console.log(forcase)


                    // console.log(po_place)

                })
                .catch((error) => {
                    console.log(error);
                })

        }


    }

    const getforcaseData = async () => {


        if (city !== "") {
            var thisyear = 0
            axios.get(config.url.NEW_API + '/forecast/' + region)
                .then(response => {
                    const date = "";
                    var forcase = []
                    var responseForcaseData = []
                    responseForcaseData = response.data
                    //console.log(responseForcaseData);
                    var jsonCity = JSON.parse(city).Code
                    //console.log(jsonCity)

                    var po_place = JSON.parse(city).po
                    setPo_place(po_place)

                    responseForcaseData.map((data, index) => {
                        const date = data["Date.fcst"]
                        const AVAtemperature = "TEMPMEDIA_fcast_" + jsonCity
                        const rainfall = "SNfcast_" + jsonCity + ".mean"
                        const maxTemp = "TEMPMAX_fcast_" + jsonCity
                        const minTemp = "TEMPMIN_fcast_" + jsonCity
                        const midTemp = "TEMPMEDIA_fcast_" + jsonCity
                        const Icon = "icon_" + jsonCity
                        const rainorNot = "rained_" + jsonCity + "_yN"
                        const rainmm = "fcast_class_" + jsonCity

                        let dateFormate = new Date(date)


                        // console.log(data[minTemp])
                        if (dateFormate.getMonth() + 1 == searchMonth && (thisyear == 0 || thisyear == dateFormate.getFullYear())) {
                            if (thisyear == 0) {
                                thisyear = dateFormate.getFullYear();
                            }
                            forcase.push({
                                Index: index,
                                Date: date,
                                avgTemp: data[AVAtemperature],
                                rainfall: data[rainfall],
                                maxTemp: data[maxTemp],
                                minTemp: data[minTemp],
                                midTemp: data[midTemp],
                                Icon: data[Icon],
                                rainorNot: data[rainorNot],
                                rainmm: data[rainmm]
                            })
                        }

                        setForecast(forcase)

                        setMaxTemp({
                            labels: forcase.map((theDate) => theDate.Date),
                            datasets: [{
                                label: `${t('max-temp')}`,
                                data: forcase.map((Temp) => Temp.maxTemp),
                                backgroundColor: ["#EF4D3C"],
                                borderColor: ["#EF4D3C"],
                                tension: 0.3
                            }, {
                                label: `${t('min-temp')}`,
                                data: forcase.map((Temp) => Temp.minTemp),
                                backgroundColor: ["rgb(75, 192, 192)"],
                                borderColor: ["rgb(75, 192, 192)"],
                                tension: 0.3
                            }]

                        })

                        setRainfall({
                            labels: forcase.map((theDate) => theDate.Date),
                            datasets: [{
                                label: `${t('rain')} (mm)`,
                                data: forcase.map((Temp) => Temp.rainfall),
                                backgroundColor: ["#0064E5"],
                                tension: 0.1
                            }]

                        })

                    })
                    console.log(forcase)


                    // console.log(po_place)

                })
                .catch((error) => {
                    console.log(error);
                })

        }
    }

    const handleDisplayChart = () => {
        setDisplay("chart")
    }

    const handleDisplayCards = () => {
        setDisplay("cards")
    }


    useEffect(async () => {
        await findCitiesAndPoCode()
        // setDateUpdate(true)
    }, [region]);

    useEffect(async () => {
        setTimeout(async () => {
            await getforcaseDataOrigin()
        }, 1000);

    }, []);




    return (
        <div style={{ "textAlign": "center" }}>
            <h1>Annual weather forcast</h1>
            <div className={style.inputContainer}>
                <div >
                    <label htmlFor="regionSelect">{t('region')}</label>
                    <select value={region} onChange={e => setRegion(e.target.value)} id="regionSelect">
                        <option selected>Choose...</option>
                        <option value="north">北部</option>
                        <option value="middle">中部</option>
                        <option value="south">南部</option>
                        <option value="east">東部</option>
                        <option value="external">外島</option>
                    </select>
                </div>
                <div>
                    {cities.length === 0 ? null :
                        <div>
                            <label htmlFor="cityselect">{t('city')}</label>
                            <select id="cityselect" value={city} onChange={e => setCity(e.target.value)}>
                                <option selected>Choose...</option>
                                {cities.map((city, index) => { return <option key={index} value={JSON.stringify(city)}>{t(`${city.City}`)}</option> })}
                            </select>
                        </div>
                    }
                </div>
                <div>
                    <div >
                        <label htmlFor="regionSelect">{t('month')}</label>
                        <select value={searchMonth} onChange={e => setSearchMonth(e.target.value)} id="regionSelect">
                            <option selected>Choose...</option>
                            <option value="1">{t('January')}</option>
                            <option value="2">{t('February')}</option>
                            <option value="3">{t('March')}</option>
                            <option value="4">{t('April')}</option>
                            <option value="5">{t('May')}</option>
                            <option value="6">{t('June')}</option>
                            <option value="7">{t('July')}</option>
                            <option value="8">{t('August')}</option>
                            <option value="9">{t('September')}</option>
                            <option value="10">{t('October')}</option>
                            <option value="11">{t('November')}</option>
                            <option value="12">{t('December')}</option>
                        </select>
                    </div>
                </div>
                <br />
                <button className={style.annualConfirmBtn} onClick={getforcaseData}>conform</button>
            </div>

            <div className={style.dispalyContainer}>


                {t(monthName[searchMonth - 1])}

                {
                    dispaly == "cards" ? (forecast.length != 0 ?
                        <div>
                            <button className={style.chartBtn} onClick={handleDisplayChart}>chart</button>
                            <button className={style.cardsBtn} onClick={handleDisplayCards}>card</button>
                            <div className={style.annualContainer}>
                                {forecast.map((data, index) => {
                                    return <AnnualCard key={index} data={data} />
                                })}
                            </div>

                        </div> : ""

                    )
                        : (MaxTemp.labels.length != 0 ? <AnnualLineChart ChartDisplay={handleDisplayChart} CardsDispay={handleDisplayCards} chartdata={MaxTemp} bardata={rainfall} /> : "")
                }

            </div>
            <div className={style.bottomspace}>

            </div>
            {/* {MaxTemp.labels.length != 0 ? <AnnualLineChart chartdata={MaxTemp} bardata={rainfall} /> : ""} */}
        </div>
    )
}

export default Annual


{/* <AnnualDispayCard ChartDisplay={handleDisplayChart} CardsDispay={handleDisplayCards} forecast={forecast}/> : "") */ }