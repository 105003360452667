import { t } from 'i18next'
import React from 'react'

const AnnualCard = (props) => {

    const iconPath = `/weatherIcon/${props.data.Icon}.svg`
    return (
        <div>
            <div>
                {props.data.Date}
            </div>

            <div>
                <img style={{ height: "100px" }} src={iconPath} alt="" />
            </div>
            <p>{t('max-temp')}:{props.data.maxTemp}</p>
            <p>{t('min-temp')}:{props.data.minTemp}</p>
            <p>{t('rain')}:{props.data.rainmm}
            </p>
        </div>
    )
}

export default AnnualCard
