import MailchimpSubscribe from 'react-mailchimp-subscribe';
import NewletterForm from './Newsletter'

const NewsSubscribe = () =>{

    const MailChimpURL = "https://gmail.us14.list-manage.com/subscribe/post?u=ee813c064d55e1c9b3d5490ae&amp;id=7bf5577de0&amp;f_id=006695e0f0"

    return <MailchimpSubscribe
        url ={MailChimpURL}
        render ={(props)=>{
            
            const{subscribe, status, message } = props || {};

            return (
                <NewletterForm 
                status={status}
                message={message}
                onValidated ={FormData => subscribe(FormData)}/>
            )
        }}/>
}



export default NewsSubscribe;