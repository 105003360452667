import { t } from 'i18next'
import React from 'react'
import style from './homepage.module.css'

const service = () => {

  return (
      
    <div className={style.our_services} id="service">
        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
          <h1>{t('service-1')}</h1>
          <hr className={style.divider_services} />
          <p>{t('service-2')}</p>
        </div>
        <div className={style.service_item} data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="1000">
          <img src="asset/FarmerForum.png" />
          <h2>{t('service-3')}</h2>
          <p>{t('service-4')}</p>
        </div>
        <div className={style.service_item} data-aos="fade-up" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="1000">
          <img src="asset/CropManagement.png" />
          <h2>{t('service-5')}</h2>
          <p>{t('service-6')}</p>
        </div>
        <div className={style.service_item} data-aos="fade-left" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="1000">
          <img src="asset/weatherForcast.png" />
          <h2>{t('service-7')}</h2>
          <p>{t('service-8')}</p>
        </div>
        <div style={{clear:"both"}}></div>
      </div>
  )
}

export default service