import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Dialog from '../layout/Dialog'
import style from './userProfile.module.css'
import { config } from '../../setting'
import Compress from 'compress.js';
import TaiwanCityData from '../../helperdata/TaiwanCityData'
import { useTranslation } from 'react-i18next';


const UserProfile = () => {

    const { t } = useTranslation();

    const [userData, setUserData] = useState({})
    

    const [regionEmpty, setRegionEmpty] = useState(true)
    const [region, setRegion] = useState("")
    const [TaiwanCities, SetTaiwanCities] = useState([])
    const [city, setCity] = useState("")

    const [isOpen, setIsOpen] = useState(false)
    const [file, setFile] = useState("")
    const [filename, setFilename] = useState("Choose File")
    const [uploadedFile, setUploadedFile] = useState({})
   
    
    const getUserInfo = async() =>{
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);

        await axios.get(config.url.NEW_API + '/users/' + decoded.uuid)
            .then((res) => {
                setUserData(res.data)
            });
    }

    async function getCities() {
        if (region !== '') {
            var regionCity = TaiwanCityData[region]
            SetTaiwanCities(regionCity)
        }
    }


    const UserimgOnSubmit = async(e) => {
        e.preventDefault();

            const token = localStorage.usertoken;
            const decoded = jwt_decode(token);
       
            const fd = new FormData();
            fd.append('file', file);
        
            try {
                const res = await axios.put(config.url.NEW_API + '/users/uploadimage/' + decoded.uuid, fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                
                console.log(res)
                
                const { fileName, filePath } = res.data

                await setUploadedFile({fileName, filePath})
                setIsOpen(false)
                await getUserInfo()    
        
            } catch (error) {
                if (error.response.status === 500) {
                    console.log('There was a problem with the server')
                } else {
                    //no file upload
                    console.log(error.response.data.msg)
                }
            }
        
        
    }

    const fileSelected = async(event) => {
       const image =  event.target.files[0]
       //console.log(image)
       const compressfile = await resizeImageFn(image)
       setFile(compressfile)
       setFilename(event.target.name)
       //console.log(compressfile)
    }

    async function resizeImageFn(file) {

        const compress = new Compress()

        const resizedImage = await compress.compress([file],{
          size: 1, // the max size in MB, defaults to 2MB
          quality: 0.8, // the quality of the image, max is 1,
          maxWidth: 300, // the max width of the output image, defaults to 1920px
          maxHeight: 300, // the max height of the output image, defaults to 1920px
          resize: true // defaults to true, set false if you do not want to resize the image width and height
        })
        const img = resizedImage[0];
        const base64str = img.data
        const imgExt = img.ext
        const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
        return resizedFiile;
    }

    const submitRegion = async() =>{
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);

        const UserEdit = {
            country: "Taiwan",
            region: region,
            city: city,
        }

       await axios.put( config.url.NEW_API + '/users/region/edit/' + decoded.uuid, UserEdit)
        .then(res => console.log(res.data));
        
       await getUserInfo()
    }


    useEffect(() => {
        getUserInfo()
    },[]);


    useEffect( () => {
         getCities()
    }, [region]);

  return (
   <div className={style.main_profile1} >
               <div className={style.profile_form} >
                    <div className={style.each_form}>
                        <div>
                             {userData.user_image == null ?
                                 <img src='/asset/farmer.png' alt="" /> :
                                 <img src={userData.user_image} alt="" />
                            }
                            <div>
                                 <button className={style.editPicBtn} style={{ float: "right" }} onClick={(e) => setIsOpen(true) }><i className="fas fa-edit"></i></button>
                                 <Dialog isOpen={isOpen} onClose={(e) => setIsOpen(false) }>
                                     {/* <form onSubmit={UserimgOnSubmit}> */} 
                                        <input type='file'  id="customFile" onChange={e => fileSelected(e)} />
                                        <label htmlFor="customFile">
                                            {filename}
                                        </label>
                                        <button onClick={UserimgOnSubmit} className="btn btn-primary">upload image</button>
                                     {/* </form> */}
                                 </Dialog>
                             </div>
                         </div>
                         <div >
                            <h1>My profile</h1>
                             <div >
                                <Link style={{ marginTop: "10%" }} to={'/user/edit/' + userData.uuid}>
                                    Edit
                                </Link>
                             </div>
                        </div>
                    </div>
                             

                    {userData.city == null ?
                                 <Dialog isOpen={regionEmpty} onClose={(e) => setRegionEmpty(false)}>
                                     <h2>{t('enter-region')}</h2>
                                     {/* <form onSubmit={submitRegion}> */}
                                         <div className={style.half_field} >
                                             <label htmlFor="CoutrySelect">region</label>
                                             <select style={{ backgroundImage: "url(/asset/vector.png)" }} id={style.city}
                                                defaultValue={region}
                                                 onChange={e => { setRegion(e.target.value) }}>
                                                 <option value="" disable='true'>請選擇</option>
                                                 <option value={"north"} >{t('north')}</option>
                                                 <option value={"east"} >{t('east')}</option>
                                                 <option value={"middle"} >{t('middle')}</option>
                                                 <option value={"south"} >{t('south')}</option>
                                                 <option value={"external"} >{t('external')}</option>
                                             </select>
                                         </div>

                                         <div>
                                    {TaiwanCities.length === 0 ? null
                                        : <div style={{width: "100%"}} className={style.half_field} >
                                            <label htmlFor="">City</label><br/>
                                            <select style={{ backgroundImage: "url(/asset/vector.png)"}}  id={style.city} 
                                            defaultValue={city} onChange={e => setCity(e.target.value)}>
                                                <option selected>Choose...</option>
                                                {TaiwanCities.map((city) => { return <option key={city} value={city}>{city}</option> })}
                                            </select>
                                        </div>}
                                </div>
                                         <button onClick={submitRegion} className="btn btn-primary"> {t('update')} </button>
                                     {/* </form> */}
                                 </Dialog>
                        : ""}

                     <div className={style.each_form}>
                         <div>
                             <label>{t('first-name')}</label><br />{userData.first_name}
                         </div>
                         <div>
                             <label>{t('last-name')}</label><br />
                             {userData.last_name}
                         </div>
                     </div>
                     <div className={style.each_form}>
                         <div>
                             <label>Email</label><br />
                             <p>{userData.email}</p>
                         </div>
                         <div>
                             <label>{t('phone-number')}</label><br />
                             <p>{userData.phone}</p>
                         </div>
                     </div>

                     <div className={style.each_form}>
                         <div>
                             <label>{t('city')}</label><br />
                             <p>{userData.city}</p>
                         </div>
                         <div>
                             <label>{t('position')}</label><br />
                             <p>{userData.position}</p>
                         </div>
                     </div>
                 </div>
                 {userData.type === "email register" ?
                     <div className={style.profile_form}>
                         <div className={style.each_form}>
                             <div>
                                 <h5><b>{t('reset-password')}</b></h5>
                             </div>
                             <div>
                                 <Link className={style.linkbtn} to={'/user/uploadPassword/' + userData.uuid}>Reset</Link>
                             </div>
                         </div>
                     </div> : ""}
            </div >
  )
}

export default UserProfile


// class UserProfile extends Component {
//     constructor() {
//         super();

//         this.fileSelected = this.fileSelected.bind(this);
//         this.UserimgOnSubmit = this.UserimgOnSubmit.bind(this);
//         this.onchangeRegion = this.onchangeRegion.bind(this)

//         this.state = {
//             userData: {},
//             errors: {},
//             isOpen: false,
//             user_image: "",
//             image_name: "Choose File",
//             file: "",
//             filename: "",
//             regionEmpty: true,
//             country: "",
//             region: "",
//             city: "",
//             TaiwanCities: [],
//             regionChange: false
//         };
//     }

//     onchangeRegion(e) {
//                 this.setState({ region: e.target.value });
//     }
        
//     async componentDidMount() {
//         const token = localStorage.usertoken;
//         const decoded = jwt_decode(token);
//         console.log(decoded)

//         this.setState({
//             userData: decoded
//         })
//         await axios.get(config.url.API_URL + '/user/' + decoded.user_id).then((res) => {
//             this.setState({ userData: res.data })
//         });

       

//     }

//     componentDidUpdate(prevProps, prevState) {
//         if (prevState.region !== this.state.region) {
//             var TaiwanCity = TaiwanCityData;
//             console.log(TaiwanCity)
//             var regionCities = TaiwanCity[this.state.region]
//             console.log(regionCities);
//             this.setState({ TaiwanCities: regionCities })
           
//         }
//     }

//     async UserimgOnSubmit() {

//         const token = localStorage.usertoken;
//         const decoded = jwt_decode(token);
//         const imagefile = this.state.file


//         // // https://www.youtube.com/watch?v=jLCT0RFFeHs
//         if (!imagefile) {
//             return
//         }


//         const fd = new FormData();
//         fd.append('file', imagefile);

//         try {
//             const res = axios
//                 .put(config.url.API_URL + '/user/upload/' + decoded.user_id, fd, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 });
//             const { fileName, filePath } = res.data
//             this.setState({ user_image: filePath, image_name: fileName });

//         } catch (error) {
//             if (error.response.status === 500) {
//                 console.log('There was a problem with the server')
//             } else {
//                 console.log(error.response.data.msg)
//             }
//         }


//     }

//     fileSelected(event) {
//         this.setState({ file: event.target.files[0], filename: event.target.files[0].name });

//     }

//     render() {
//         const { t } = this.props;
//         return (
//             <div className={style.main_profile1} >
//                 <div className={style.profile_form} >
//                     <div className={style.each_form}>
//                         <div>
//                             {this.state.userData.user_image == null ?
//                                 <img src='/asset/farmer.png' alt="" /> :
//                                 <img src={this.state.userData.user_image} alt="" />
//                             }

//                             <div>
//                                 <button className={style.editPicBtn} style={{ float: "right" }} onClick={(e) => this.setState({ isOpen: true })}><i className="fas fa-edit"></i></button>
//                                 <Dialog isOpen={this.state.isOpen} onClose={(e) => this.setState({ isOpen: false })}>
//                                     <form onSubmit={this.UserimgOnSubmit}>
//                                         <input type='file' accept="image/*" id='customFile' onChange={this.fileSelected} />
//                                         <label htmlFor='customFile'>
//                                             {this.state.fileName}
//                                         </label>
//                                         <input type="submit" value="update image" className="btn btn-primary" />

//                                     </form>
//                                     <img style={{ width: '200px' }} src={this.state.userData.user_image} alt="sample file" />
//                                 </Dialog>
//                             </div>

//                             {this.state.city == "" ?
//                                 <Dialog isOpen={this.state.regionEmpty} onClose={(e) => this.setState({ regionEmpty: false })}>
//                                     <form onSubmit={this.UserimgOnSubmit}>
//                                         <div className={style.half_field} >
//                                             <label htmlFor="CoutrySelect">region</label>
//                                             <select style={{ backgroundImage: "url(/asset/vector.png)" }} id={style.city}
//                                                 defaultValue={this.state.region}
//                                                 onChange={this.onchangeregion}>
//                                                 <option >請選擇</option>
//                                                 <option value={"north"} >北區</option>
//                                                 <option value={"east"} >東區</option>
//                                                 <option value={"middle"} >中區</option>
//                                                 <option value={"south"} >南區</option>
//                                                 <option value={"external"} >外島</option>
//                                             </select>
//                                         </div>

//                                         <div>
//                                             {this.state.TaiwanCities.length === 0 ? null
//                                                 : <div style={{ width: "100%" }} className={style.half_field} >
//                                                     <label htmlFor="exampleFormControlSelect1">City</label>
//                                                     <select style={{ backgroundImage: "url(/asset/vector.png)" }} id={style.city}
//                                                         defaultValue={this.state.city} onChange={e => this.setState({ city: e.target.value })}>
//                                                         <option selected>Choose...</option>
//                                                         {this.state.TaiwanCities.map((city) => { return <option key={city} value={city}>{city}</option> })}
//                                                     </select>
//                                                 </div>}
//                                         </div>
//                                         <input type="submit" value="submit" className="btn btn-primary" />
//                                     </form>
//                                 </Dialog>
//                                 : ""}
//                         </div>
//                         <div >
//                             <h1>My profile</h1>
//                             <div >
//                                 <Link style={{ marginTop: "10%" }} to={'/user/edit/' + this.state.userData.user_id}>
//                                     Edit
//                                 </Link>
//                             </div>
//                         </div>
//                     </div>

//                     <div className={style.each_form}>
//                         <div>
//                             <label>First Name</label><br />
//                             {this.state.userData.first_name}
//                         </div>
//                         <div>
//                             <label>Second Name</label><br />
//                             {this.state.userData.last_name}
//                         </div>
//                     </div>
//                     <div className={style.each_form}>
//                         <div>
//                             <label>Email</label><br />
//                             <p>{this.state.userData.email}</p>
//                         </div>
//                         <div>
//                             <label>Phone Number</label><br />
//                             <p>{this.state.userData.phone}</p>
//                         </div>
//                     </div>

//                     <div className={style.each_form}>
//                         <div>
//                             <label>City/Country</label><br />
//                             <p>{this.state.userData.city}</p>
//                         </div>
//                         <div>
//                             <label>{t('position')}</label><br />
//                             <p>{this.state.userData.position}</p>
//                         </div>
//                     </div>
//                 </div>
//                 {this.state.userData.type == "email register" ?
//                     <div className={style.profile_form}>
//                         <div className={style.each_form}>
//                             <div>
//                                 <h5><b>Want to reset password?</b></h5>
//                             </div>
//                             <div>

//                                 <Link className={style.linkbtn} to={'/user/uploadPassword/' + this.state.userData.user_id}>
//                                     Reset
//                                 </Link>
//                             </div>
//                         </div>
//                     </div> : ""}
//             </div >
//         );
//     }
// }


// let userProfile = withTranslation()(UserProfile);

// export default userProfile;

