import React, { useState ,useEffect } from "react";
import { Link } from "react-router-dom";
import style from "./croplist.module.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { config } from '../../setting';
import jwt_decode from "jwt-decode";

import { AiFillHeart } from 'react-icons/ai';
import { AiOutlineHeart } from 'react-icons/ai';

const InfoTitleCard = (props) => {
  const { t } = useTranslation();

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const[like, setLike] = useState(false)

  const sensitivityDispay = () => {
    const water = [];
    for (let i = 0; i < props.crops.water_sensitive; i++) {
      water.push(<i className="fa fa-tint" aria-hidden="true"></i>);
    }
    return <span>{water}</span>;
  };

  const monthinWords = () => {
    var start = props.crops.reco_start;
    var end = props.crops.reco_end;

    const map1 = new Map();
    map1.set(0.25, "start");
    map1.set(0.5, "mid");
    map1.set(0.75, "end");

    var startPeriod = '' 
    var endPeriod = ''

    if (String(start).includes(".")) {
      var startstage = parseFloat("0." + String(start).split(".")[1]);
      if (map1.has(startstage)){
        startPeriod = map1.get(startstage)
      }
    }

    if (String(end).includes(".")) {
      var endstage = parseFloat("0." + String(end).split(".")[1]);
      if (map1.has(endstage)){
        endPeriod = map1.get(endstage)
      }
    }
    var startmonth = month[Math.trunc(start)]
    return  startmonth+ " " + startPeriod + " ~ " + month[Math.trunc(end)] + " " + endPeriod;
  };

  const LikeClick = async(e) =>{

    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);

    if(like === false){
      await axios.post(config.url.NEW_API+'/like/add', {uuid:decoded.uuid, cropId:props.crops.id})
      .then((res) =>{
        setLike(true)
      })  
    }else{
      await axios.delete(config.url.NEW_API+'/like/delete/'+decoded.uuid+'/'+props.crops.id).then((res)=>{
        console.log(res.data)
        setLike(false)
      }).catch((err) =>{
        console.log(err)
      })
    }
  
  }

  const isLike = async() =>{
    props.likeList.forEach(async (element) => {
      if(element.cropId === props.crops.id){
        await setLike(true)
      }
    });
   
  }

  const getLike = async() =>{
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);

    await axios.get(config.url.NEW_API +'/like/'+ decoded.uuid + "/" + props.crops.id)
    .then((response) =>{
        if(response.data.length !== 0){
          setLike(true)
        }
    })
  }


  useEffect(() => {
    getLike()
    isLike()
  }, [like]);

  return (
    <div className={style.each_content}>
      <div className={style.search_image}>
        <img src={props.crops.crop_image} alt={props.commonName} />
      </div>
      <div className={style.image_details}>
        <button onClick={LikeClick} className={style.heart_icon_red}>
          {like === true ? <AiFillHeart size="2rem"/>
          :<AiOutlineHeart size="2rem"/>}
        </button>
        <h5>{props.crops.chinese_name} {props.crops.common_name}</h5>
        <p style={{ margin:0 , padding: 0}}>{t("planting-season")}: {props.crops.season_string}</p>
        {/* <p>{t("best-timing")}: <span>{monthinWords()}</span></p> */}
        <p style={{ margin:0 , padding: 0}}>{t("water-sensitivity")}: {sensitivityDispay()}</p>
        <p style={{ margin:0 , padding: 0}}>{t("adapt-temp")}: <span>{props.crops.min_temp}</span> ~{" "} <span>{props.crops.max_temp}</span><span>&#8451;</span></p>
        <p style = {{ margin: 0, padding: 0}}>預估種植天數:{props.crops.total_grow_time}</p>
        <p style={{ margin:0 , marginBottom:20 , padding: 0}}>{props.crops.short_description}</p>
        <Link className={style.read_more} to={"/cropPage/" + props.crops.id}> {t("read-more")}</Link>

      </div>
    </div>
  );
};

export default InfoTitleCard;
