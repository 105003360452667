import React, { useState } from "react";
import style from "./verifyCard.module.css";
import Dialog from "../layout/Dialog";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { config } from "../../setting";

const VerifyCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [issue_type, setIssueType] = useState("");
  const [issue_state, setIssueState] = useState("");
  const [feedback, setFeedback] = useState("");
  const [location, setLocation] = useState("");
  const [ShowthankyouNote, setThankyouNode] = useState(false);

  const FeedBackOnSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);

    const verifyData = {
      userId: decoded.uuid,
      cropId: props.cropId,
      location: location,
      issue_type: issue_type,
      issue_state: issue_state,
      feedback: feedback,
    };

    console.log(verifyData);

    await axios
      .post(config.url.NEW_API + "/verify", verifyData)
      .then((response) => {
        console.log(response.data);
        setThankyouNode(true);
        setTimeout(() => {
            setThankyouNode(false);
            setIsOpen(false)
        }, 3000);
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  return (
    <div>
      <div>
        <button
          className={style.editPicBtn}
          style={{ float: "right" }}
          onClick={(e) => setIsOpen(!isOpen)}
        >
          <i className="fas fa-edit"></i>
        </button>
        <Dialog isOpen={isOpen} onClose={(e) => setIsOpen(false)}>
          <div>
            {ShowthankyouNote ? (
              <div>
                <p>感謝您的反饋 讓我們能更加進步</p>
                <p>thank you for helping us to improve our website and data</p>
              </div>
            ) : (
              <div>
                <label htmlFor="feedback">情況種類</label>
                <form onSubmit={FeedBackOnSubmit}>
                  <select
                    value={issue_type}
                    onChange={(e) => setIssueType(e.target.value)}
                    className="form-control"
                    id="timeSelect"
                  >
                    <option value=""></option>
                    <option value="預測溫度太高">預測溫度太高</option>
                    <option value="預測溫度太低">預測溫度太低</option>
                    <option value="灌溉水量太多">灌溉水量太多</option>
                    <option value="灌溉水量太少">灌溉水量太少</option>
                    <option value="天數不足, 作物尚未成熟">天數不足, 作物尚未成熟</option>
                    <option value="天數過長, 作物已經過熟">天數過長, 作物已經過熟</option>
                    <option value="其他">其他</option>
                  </select>
                  <br />
                  <label htmlFor="feedback">種植階段</label>
                  <select
                    value={issue_state}
                    onChange={(e) => setIssueState(e.target.value)}
                  >
                    <option value="inital">初期</option>
                    <option value="develop">發展期</option>
                    <option value="mid">中期</option>
                    <option value="late">晚期</option>
                    <option value="harvest">收成期</option>
                  </select>
                  <br />
                  <br />
                  <label htmlFor="feedback">種植地點</label>
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <br />
                  <label htmlFor="feedback"> 請多加解釋讓我們可以進步:</label>
                  <textarea
                    rows="4"
                    cols="40"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    type="text"
                    id="feedback"
                  />
                  <br />
                  <input type="submit" />
                </form>
              </div>
            )}
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default VerifyCard;
