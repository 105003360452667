import React, { useEffect, useState } from 'react';
import style from './card.module.css'
import IrrigationDisplayCard from './irrigationDisplayCard';


const MonthlyCard = (props) => {

    const [kcdata, setKcdata] = useState({})
    const [value, setvalue] = useState([])
    const [monthforcase, setmonthforcase] = useState({})

    function KCvalueCalulate() {
        // console.log(kcdata)

        const growingArray = []
        if (kcdata.inital_days !== 0) {
            growingArray.push(kcdata.inital_days)
        }

        if (kcdata.dev_days !== 0) {
            growingArray.push(kcdata.dev_days)
        }

        if (kcdata.mid_days !== 0) {
            growingArray.push(kcdata.mid_days)
        }

        if (kcdata.last_days !== 0) {
            growingArray.push(kcdata.last_days)
        }

        if (kcdata.harvest_days !== 0) {
            growingArray.push(kcdata.harvest_days)
        }

        // console.log(growingArray)

        const kcArray = [];
        if (kcdata.inital_kc !== 0) {
            kcArray.push(kcdata.inital_kc)
        }

        if (kcdata.dev_kc !== 0) {
            kcArray.push(kcdata.dev_kc)
        }

        if (kcdata.mid_days !== 0) {
            kcArray.push(kcdata.mid_kc)
        }

        if (kcdata.last_kc !== 0) {
            kcArray.push(kcdata.last_kc)
        }

        if (kcdata.harvest_kc !== 0) {
            kcArray.push(kcdata.harvest_kc)
        }

        startfromSelectedDate(growingArray, kcArray)

        // function below is to count from today.
        // periodToMouth(growingArray, kcArray);

    }

    function startfromSelectedDate(growingArray, kcArray){
        var state = 0

        var eachmouth = [];
        //get the first mouth's remain day
        var selectedFristMonth = new Date((JSON.parse(props.date).Date).replace(/-/g, '\/')).getMonth()
        const forcasedata = monthforcase
        var mouthsRemain = forcasedata[selectedFristMonth].days
        // console.log(mouthsRemain)
        eachmouth.push(mouthsRemain)


        var kcToMouth = []

        var preKcSum = 0
        var mouthCount = 1;

        var periodgrow = growingArray[state]
        var currentKc = kcArray[state]

        // 
        while (state < growingArray.length) {

            if (mouthsRemain <= periodgrow) { //當期比較多天的狀況下

                //所有當月的天數乘上 Kc
                var mouthKc = parseFloat((mouthsRemain * currentKc).toPrecision(4)) + preKcSum
                kcToMouth.push(mouthKc)

                preKcSum = 0;
                periodgrow = periodgrow - mouthsRemain

                mouthsRemain = getselectedNextMouthDate(mouthCount)
                if (state === growingArray.length - 1) {
                    eachmouth.push(periodgrow)
                } else {
                    eachmouth.push(mouthsRemain)
                }

                mouthCount++

            } else {
                //當月剩下的天數(最多31天) 大於本期所需要的天數
                preKcSum = preKcSum + parseFloat((periodgrow * currentKc).toPrecision(4))
                mouthsRemain = mouthsRemain - periodgrow
                state++
                periodgrow = growingArray[state]
                currentKc = kcArray[state]

            }

        }
        kcToMouth.push(preKcSum)
        // console.log(kcToMouth)
        // setvalue(kcToMouth)
        //console.log(eachmouth)

        eachMouthKc(kcToMouth, eachmouth)

    }

    function periodToMouth(growingArray, kcArray) {

        var state = 0

        var eachmouth = [];
        //get the first mouth's remain day
        var today = new Date();
        var FristMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        var getTodayDate = today.getDate() - 1;
        var mouthsRemain = FristMonth - getTodayDate

        eachmouth.push(mouthsRemain)


        var kcToMouth = []

        var preKcSum = 0
        var mouthCount = 1;

        var periodgrow = growingArray[state]
        var currentKc = kcArray[state]

        while (state < growingArray.length) {

            if (mouthsRemain <= periodgrow) { //當期比較多天的狀況下

                //所有當月的天數乘上 Kc
                var mouthKc = parseFloat((mouthsRemain * currentKc).toPrecision(4)) + preKcSum
                kcToMouth.push(mouthKc)

                preKcSum = 0;

                periodgrow = periodgrow - mouthsRemain

                mouthsRemain = getNextMouthDate(mouthCount)
                if (state === growingArray.length - 1) {
                    eachmouth.push(periodgrow)
                } else {
                    eachmouth.push(mouthsRemain)
                }

                mouthCount++

            } else {
                //當月剩下的天數(最多31天) 大於本期所需要的天數
                preKcSum = preKcSum + parseFloat((periodgrow * currentKc).toPrecision(4))
                mouthsRemain = mouthsRemain - periodgrow
                state++
                periodgrow = growingArray[state]
                currentKc = kcArray[state]

            }

        }
        kcToMouth.push(preKcSum)
        // console.log(kcToMouth)
        // setvalue(kcToMouth)
        //console.log(eachmouth)

        eachMouthKc(kcToMouth, eachmouth)
    }

    //
    function getselectedNextMouthDate(mouthCount) {

        var NextSelectedMonth = (new Date((JSON.parse(props.date).Date).replace(/-/g, '\/')).getMonth() + mouthCount)%12
        const forcasedata = monthforcase
        var MonthDate = forcasedata[NextSelectedMonth].days

        return MonthDate;
    }

    function getNextMouthDate(mouthCount) {
        var today = new Date();
        var MonthDate = new Date(today.getFullYear(), today.getMonth() + 1 + mouthCount, 0).getDate();

        return MonthDate;
    }

    async function eachMouthKc(kcToMouth, eachmouth) {
        var mouthKc = []
        for (let i = 0; i < eachmouth.length; i++) {
            mouthKc.push(parseFloat((kcToMouth[i] / eachmouth[i]).toPrecision(3)))
        }
        //console.log(eachmouth)

        calETo(mouthKc, eachmouth)
    }

    async function calETo(mouthKc, eachmouth) {

        var today = new Date((JSON.parse(props.date).Date).replace(/-/g, '\/'))
        //console.log(today)
        var pointer = today.getMonth();
        const avgTemp = monthforcase
        const locationla = props.la
        var KcCount = 0
        var ET = []

        //console.log(avgTemp)
        while (KcCount <= mouthKc.length - 1) {
            var getMothName = MouthName(pointer)
            var avaragetemp = avgTemp[pointer].avgTemp
            var rainfall = avgTemp[pointer].Rain
            var maxTemp = avgTemp[pointer].maxTemp
            var minTemp = avgTemp[pointer].minTemp
            var days = avgTemp[pointer].days
            var mountparam = locationla[pointer]
            var vegedata = mouthKc[KcCount]
            var mouthDay = eachmouth[KcCount]

            // console.log(mouthDay)
            var Irrigation = (mountparam * (0.46 * avaragetemp + 8) * vegedata) * mouthDay
            // Irrigation = Math.round(Irrigation);


            ET.push({
                irrigation: parseFloat(Irrigation.toPrecision(3)),
                rainfall: rainfall,
                maxtemp: maxTemp,
                mintemp: minTemp,
                thisMount: getMothName,
                days:mouthDay
            })
          
            // console.log(Irrigation)
            pointer = ((pointer + 1) % 12)
            console.log(pointer)
            KcCount++;
        }

        console.log(ET)

        setvalue(ET)
     
    }

    const MouthName = (number) => {
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return months[number]
    }



    useEffect(() => {
        //console.log(props.kc)
        setKcdata(props.kc)
        setmonthforcase(props.monthforcase)
    
    }, []);

    useEffect(() => {
        if (typeof kcdata != 'undefined') {
            if (Object.keys(kcdata).length !== 0) {
                KCvalueCalulate()
            }
        }
    }, [kcdata])

    


    return (
        <div>
            {(typeof kcdata != 'undefined') ?
                <div className={style.monthlyCard}>
                    {value.map((eachmouth, index) =>
                        <IrrigationDisplayCard
                            key={index}
                            thismouth={eachmouth}
                            maxT={props.cropTmax} minT={props.cropTmin} area={props.area} unit={props.unit}/>
                    )
                    }
                </div>
                : <span>暫無資料</span>
            }
        </div>
    );
}

export default MonthlyCard;