import React from 'react'
import style from './homepage.module.css'


const Commingup = () => {
  return (
    <div className={style.commingupImg}>
      <img src="/asset/comingupsoon.png" alt="" />
    </div>
  )
}

export default Commingup
