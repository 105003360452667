import React, { useEffect } from 'react'
import Twheader from './twheader'
import TwAbout from './twAbout';
import TwService from './twService';
import Twteam from './twteam';
import TwHowitwork from './twHowitwork'
import TwContact from './twContact'
import Footer from '../footer'


const Twhome = () => {

    return (
        <div>
            <Twheader />
            <TwAbout/>
            <TwService/>
            <TwHowitwork/>
            <Twteam/>
            <TwContact/>
            <Footer/>
        </div>
    )
}

export default Twhome
