import axios from 'axios';
import React, { useState, useEffect } from 'react';
import QuestionCard from './questionCard';
import jwt_decode from 'jwt-decode';
import { Link } from 'react-router-dom';

import style from './question.module.css'
import { useTranslation } from 'react-i18next';
import UserProfile from './userProfile';

import { config } from '../../setting';

const FarmerQestions = (props) => {

    const { t } = useTranslation();

    const [newQuestion, setnewQuestion] = useState([]);
    //for image file
    const [file, setFile] = useState('');
    const [filename, setfilename] = useState('');


    const [question, setQuestion] = useState([]);


    function getQuestion() {
        axios.get(config.url.NEW_API+'/question/posts')
            .then(rep => {
                console.log(rep.data)
                setQuestion(rep.data)
            })
    }



    useEffect(async () => {
        getQuestion()
    }, []);



    const onChange = e => {
        setFile(e.target.files[0]);
        setfilename(e.target.files[0].name);
    }

    const onSubmit = async e => {
        // e.preventDefault();
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);


        const postquestion = await axios.post(config.url.NEW_API+ '/question/post', {
            content:newQuestion,
            userUuid:decoded.uuid
        }).then(response =>{
            var responseObject = response.data
            responseObject.user = {
                first_name:decoded.first_name,
                user_image:decoded.user_image
            }
            responseObject.messages = []
            
            setQuestion([...question, responseObject])
            console.log(responseObject)
            return response.data
        }).catch(err => {
            console.log(err)
        })

        console.log(postquestion)


        if(file !== ''){
            const formData = new FormData();
            formData.append("file", file)

            await axios.put(config.url.NEW_API + '/question/uploadimage/' + postquestion.uuid, formData, { headers: { 'Content-Type': 'multipart/form-data' } }) 
            .then(response => {
                console.log(response.data)

            }).catch(err => {
                console.log(err)
            })
        }

        setnewQuestion("")

    }

    return (
        <div className={style.forum_wrapper}>
            <div className={style.forum}>
                <div className={style.left_forum}>
                    <UserProfile />
                    <div className={style.forum_profile_question}>
                        <h5><b>{t('ask-question_2')}</b></h5>
                        <form onSubmit={onSubmit} >
                            <div>
                                <textarea value={newQuestion} onChange={e => setnewQuestion(e.target.value)} placeholder={t("ask-question")}></textarea><br />
                                <div className={style.question_image_btn}>
                                    {filename}
                                    <label style={{marginRight: "10px"}} htmlFor="imagefile"><i className="far fa-image fa-2x"></i></label>
                                    <input style={{ display: "none", visibility: "none" }} onChange={onChange} type="file" id="imagefile" />
                                    <input className={style.questionSubmitbtn} type="submit" value={t('send')} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className={style.forum_questions}>
                    <h1>{t('forum')}</h1>
                        <div className={style.lets_talk}>
                            <div>
                                <h4>Let's Talk here</h4>
                            </div>
                            <div >
                                <Link className={style.allquestioninAll} to={"/questions"}>{t('all')}</Link>
                                <Link className={style.myquestionInAll} to={"/user/question"}>{t('my-questions')} </Link>
                            </div>
                        </div>

                        <div>
                            {question.map(
                                (q, i) => {
                                    return <QuestionCard q={q} key={i} />
                                }
                            )}
                        </div>

                    </div>
                </div>
            
        </div>

    );
}

export default FarmerQestions;