import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import style from "./fertilize.module.css";

const FertilizeCard = (props) => {
  const { t } = useTranslation();


  const fertilizeStageString = ['inital stage','develop stage','mid stage', 'late stage', 'harvest stage', 'In all time']


  return (
    <div className={style.details_container}>
      <div className={style.details}>
        {props.cropfer.length === 0 ? (
          <div>
            <div style={{ dispaly: "block", width: "100%" }}>
              <p className={style.title}>{t("no-data-yet")}</p>
            </div>
            <img
              style={{ width: "300px" }}
              src="/asset/comingupsoon.png"
              alt=""
            />
          </div>
        ) : (
          <div className={style.scroll_area}>
            {props.cropfer.map((fer) => {
              return (
                <div>
                  <p>{fertilizeStageString[fer.stage]}</p>
                  <p>{parse(`${fer.content}`)}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default FertilizeCard;
