import React, { useEffect, useState } from 'react'
import Annual from './annual'
import IbmData from './IbmData'
import style from './weatherPage.module.css'
import { useTranslation } from 'react-i18next';
import { config } from '../../setting';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const WeatherPage = (props) => {

  const { t } = useTranslation();
  const [region, setRegion] = useState("")
  const [cities, setCities] = useState(["Keelung", "Taipei", "Yilan", "Hsinchu", "Taoyuan", "Miaoli", "Taichung", "Zhanghua", "Yunlin", "Nantou"])
  const [city, setCity] = useState("")

  const [userlocaion, setUserlocation] = useState({});


  const findCitiesAndPoCode = async () => {
    if (region != "") {
        axios.get(config.url.NEW_API + '/forecast/cities/' + region)
            .then(response => {
                //console.log(response.data)
                var city = []
                for (let i = 0; i < response.data.length; i++) {
                    city.push({ City: response.data[i].City, Code: response.data[i].Code, po: response.data[i].po_place })
                }
                setCities(city)
            })
            .catch((error) => {
                console.log(error);
            })
    }
}

useEffect(async () => {
  await getUserInformationn();
}, []);

  useEffect(async () => {
    await findCitiesAndPoCode()
  }, [region]);

  const getUserInformationn = async () => {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);

    const userlatlng = {
        Taitung: { region:"middle" , city: 'Taitung', Latitude: 22.7613, Longitude: 121.1438 },
        Hualian: { region:"east" , city: 'Hualian', Latitude: 23.9911, Longitude: 121.6112 },
        Miaoli: {region:"middle" , city: 'Miaoli', Latitude: 24.5602, Longitude: 120.8214 },
        Taichung: {region:"east" , city: 'Taichung', Latitude: 24.1477, Longitude: 120.6736 },
        Zhanghua: {region:"middle" , city: 'Zhanghua', Latitude: 24.0717, Longitude: 120.5624 },
        Yunlin: {region:"middle" , city: 'Yunlin', Latitude: 23.7092, Longitude: 120.4313 },
        Nantou: {region:"middle" , city: 'Nantou', Latitude: 23.918, Longitude: 120.6775 },
        Keelung: {region:"North" , city: 'Keelung', Latitude: 25.1276, Longitude: 121.7392 },
        Taipei: {region:"North" , city: 'Taipei', Latitude: 25.033, Longitude: 121.5654 },
        Yilan: {region:"North" , city: 'Yilan', Latitude: 22.6158, Longitude: 120.712 },
        Hsinchu: {region:"North" , city: 'Hsinchu', Latitude: 24.8138, Longitude: 120.9675 },
        Taoyuan: {region:"North" , city: 'Taoyuan', Latitude: 24.9554, Longitude: 121.23 },
        Jiayi: { region:"south" ,city: 'Jiayi', Latitude: 23.4801, Longitude: 120.4491 },
        Tainan: {region:"south" , city: 'Tainan', Latitude: 22.9999, Longitude: 120.2269 },
        Kaohsiung: {region:"south" , city: 'Kaohsiung', Latitude: 22.6273, Longitude: 120.3014 },
        Pingtung: {region:"south" , city: 'Pingtung', Latitude: 22.6558, Longitude: 120.4703 },
        Penghu: {region:"external" , city: 'Penghu', Latitude: 23.5833, Longitude: 119.5833 },
        Kinmen: {region:"external" , city: 'Kinmen', Latitude: 24.4943, Longitude: 118.4163 }
    }

    //console.log(userlatlng[decoded.city])
    setUserlocation(userlatlng[decoded.city])
    return await userlatlng[decoded.city]
    // return axios.get(config.url.API_URL + '/user/' + decoded.user_id)
    // .then((res)=>{
    //     
    // })

}

  return (
    <div className={style.weatherContainer}>
      <div style={{ "textAlign": "center" }}>
        <h1>Weather forecast of your location</h1>
      </div>
      <div className={style.locationSetting}>
        <h4>location : {userlocaion.city}</h4>
        {/* <div className={style.locationSelect}>
          <h5> Change location: </h5>
          <div >
            <label htmlFor="regionSelect">{t('region')}</label>
            <select value={region} onChange={e => setRegion(e.target.value)} id="regionSelect">
              <option selected>Choose...</option>
              <option value="north">北部</option>
              <option value="middle">中部</option>
              <option value="south">南部</option>
              <option value="east">東部</option>
              <option value="external">外島</option>
            </select>
          </div>
          <div>
            {cities.length === 0 ? null :
              <div>
                <label htmlFor="cityselect">{t('city')}</label>
                <select id="cityselect" value={city} onChange={e => setCity(e.target.value)}>
                  <option selected>Choose...</option>
                  {cities.map((city, index) => { return <option key={index} value={JSON.stringify(city)}>{t(`${city.City}`)}</option> })}
                </select>
              </div>
            }
          </div>
         
        </div> */}
      </div>
      <IbmData location={userlocaion}/> 
      <Annual location={userlocaion}/>

    </div>
  )
}

export default WeatherPage
