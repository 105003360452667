import React, { useEffect, useState } from "react";
import style from "./userGarden.module.css";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { config } from "../../setting";
import GardenCropCard from "./GardenCropCard";
import { Link } from "react-router-dom";
import UserLikeCard from "./UserLikeCard";

const UserCropGarden = (props) => {
  const [userinfo, setUserInfo] = useState({});
  const [plaintcrops, setPlantcrops] = useState([]);
  const [likeList, setLikeList] = useState([]);
  const token = localStorage.usertoken;
  const decoded = jwt_decode(token);

  function displayUser() {
    console.log(decoded);

    axios.get(config.url.NEW_API + "/users/" + decoded.uuid).then((res) => {
      setUserInfo(res.data);
    });
  }

  const getUserPlanted = () => {
    axios
      .get(config.url.NEW_API + "/usercrop/usercrop/" + decoded.uuid)
      .then((res) => {
        console.log(res.data);
        setPlantcrops(res.data);
      });
  };

  const getLikeList = () => {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);

    axios.get(config.url.NEW_API + "/like/" + decoded.uuid).then((response) => {
      console.log(response.data);
      setLikeList(response.data);
    });
  };

  useEffect(() => {
    getUserPlanted();
    getLikeList();
    displayUser();
  }, []);

  return (
    <div style={{ backgroundImage: "url(/asset/undraw_sunny.png)" }} className={style.garden_wrapper} >
      <div className={style.garden}>
        <div className={style.left_garden}>
          <div className={style.garden_profile_image}>
            <img src={userinfo.user_image} alt={"User"}/>
          </div>
          <div className={style.garden_profile_status}>
            <span><b>{userinfo.first_name} {userinfo.last_name}</b></span>
            <span><b>Location:</b> {userinfo.country} {userinfo.city}</span>
            {/* <span><b>Position:</b> {userinfo.position}</span>
            <span><b>Member:</b> {userinfo.member}</span> */}
          </div>
          <div className={style.garden_profile_forum}>
            <p><Link to={"/user/question"}>Forum</Link></p>
          </div>
        </div>
        <div className={style.right_garden}>
          <h1>{userinfo.first_name}'s Garden</h1>
          <div className={style.garden_box}>
            {plaintcrops.map((crop) => {
              return <GardenCropCard key={crop.uuid} crop={crop} />;
            })}
          </div>
          <h1>My wishing list</h1>
          <div className={style.wishing_list}>
            {likeList.map((like) => {
                return <UserLikeCard like={like} />; 
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCropGarden;
