import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import style from "./userCrop.module.css";
import CityName from "../../helperdata/TaiwanCityData";
import axios from "axios";
import { config } from "../../setting";
import jwt_decode from "jwt-decode";
import { calculateIrrigationbyWeek } from "./IrrigationFunction";
import { useTranslation } from "react-i18next";

const UserCropAdd = (props) => {
  const { t } = useTranslation();
  const histroy = useHistory();
  const { id } = useParams();
  let location = useLocation();
  const data = location.state?.data;
  const [region, setRegion] = useState("");
  const [TaiwanRegion, setTaiwanRegion] = useState({});

  const [cities, setCities] = useState([
    "Keelung",
    "Taipei",
    "Yilan",
    "Hsinchu",
    "Taoyuan",
    "Miaoli",
    "Taichung",
    "Zhanghua",
    "Yunlin",
    "Nantou",
    "Jiayi",
    "Tainan",
    "Kaohsiung",
    "Pingtung",
    "Taitung",
    "Hualian",
  ]);

  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const [fieldsize, setFieldSize] = useState(0);
  const [note, setNote] = useState("");

  const [forecast, setForecast] = useState([]);
  const [po_place, setPo_place] = useState(0);

  const [cropInfo, setCropInfo] = useState({});

  const [error, seterror] = useState("");

  const getCropBaseInfo = () => {
    axios
      .get(config.url.NEW_API + "/crops/detailinfo/" + id)
      .then((response) => {
        console.log(response.data);
        setCropInfo(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getdefultforcaseData = async () => {
    axios.get(config.url.NEW_API + "/forecast/north")
      .then((response) => {
        var forcase = [];
        var responseForcaseData = [];
        responseForcaseData = response.data;

        setPo_place(po_place);

        responseForcaseData.map((data, index) => {
          const date = data["Date.fcst"];
          const AVAtemperature = "";
          const rainfall = "";
          const maxTemp = "";
          const minTemp = "";
          // console.log(data[minTemp])
          forcase.push({
            Index: index,
            Date: date,
            avgTemp: data[AVAtemperature],
            rainfall: data[rainfall],
            maxTemp: data[maxTemp],
            minTemp: data[minTemp],
          });
        });
        //console.log(forcase)
        setForecast(forcase);

        // console.log(po_place)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const findCitiesAndPoCode = async () => {
    if (region !== "") {
      await axios
        .get(config.url.NEW_API + "/forecast/cities/" + region)
        .then((response) => {
          var city = [];
          for (let i = 0; i < response.data.length; i++) {
            city.push({
              City: response.data[i].City,
              Code: response.data[i].Code,
              po: response.data[i].po_place,
            });
          }
          console.log(city)
          setCities(city);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getforcaseData = async () => {
    if (city !== "") {
      axios
        .get(config.url.NEW_API + "/forecast/" + region)
        .then((response) => {
          var forcase = [];
          var responseForcaseData = [];
          responseForcaseData = response.data;
          //console.log(responseForcaseData);
          var jsonCity = JSON.parse(city).Code;
          //console.log(jsonCity)

          var po_place = JSON.parse(city).po;
          setPo_place(po_place);

          responseForcaseData.map((data, index) => {
            const date = data["Date.fcst"];
            const AVAtemperature = "TEMPMEDIA_fcast_" + jsonCity;
            const rainfall = "SNfcast_" + jsonCity + ".mean";
            const maxTemp = "TEMPMAX_fcast_" + jsonCity;
            const minTemp = "TEMPMIN_fcast_" + jsonCity;
            // console.log(data[minTemp])
            forcase.push({
              Index: index,
              Date: date,
              avgTemp: data[AVAtemperature],
              rainfall: data[rainfall],
              maxTemp: data[maxTemp],
              minTemp: data[minTemp],
            });
          });
          //console.log(forcase)
          setForecast(forcase);

          // console.log(po_place)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function getTaiwanCities() {
    const TaiwanCity = CityName;
    setTaiwanRegion(TaiwanCity);
  }

  // function getCities() {
  //   if (region !== "") {
  //     setRegionCity(TaiwanRegion[region]);
  //   }
  // }

  function BacktoCropInfoPage() {
    histroy.push("/cropPage/" + id);
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (region === "") {
      seterror("plase enter your region ");
    } else if (city === "") {
      seterror("plase enter your city ");
    } else {
      const token = localStorage.usertoken;
      const decoded = jwt_decode(token);

      var startday = new Date(JSON.parse(date).Date);
      startday = new Date(
        startday.getTime() + Math.abs(startday.getTimezoneOffset() * 60000)
      );

    var kcmeta = await calculateIrrigationbyWeek(id, date, region, city, "25");

      await axios
        .post(config.url.NEW_API + "/usercrop/", {
          useruuid: decoded.uuid,
          cropId: id,
          start_date: startday,
          field_size: fieldsize,
          location: JSON.parse(city).City,
          note: note,
          meta: kcmeta,
        })
        .then((response) => {
          window.location = "/usercrop/garden";
          histroy.push("/usercrop/garden");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getTaiwanCities();
    getdefultforcaseData();
    getCropBaseInfo();
    console.log(data);
  }, []);

  useEffect(() => {
    // getCities();
    findCitiesAndPoCode();
  }, [region]);

  useEffect(() => {
    getforcaseData();
    
  }, [city]);

  return (
    <div
      className={style.garden_plan}
      style={{ backgroundImage: "url(/asset/undraw_team_work.png)" }}
    >
      <p className={style.planmygarden}>Plan my Garden</p>
      <div className={style.my_veg_id}>
        <p className={style.vegID}>My veg ID</p>
        <form className={style.veg_form} onSubmit={onSubmit}>
          <div className={style.each_field_plan}>
            <div id="o-2">
              <h1>{cropInfo.common_name}</h1>
              <label>Region</label>
              <select
                style={{ backgroundImage: "url(/asset/vector.png)" }}
                id={style.city}
                defaultValue={region}
                onChange={(e) => {
                  setRegion(e.target.value);
                }}
              >
                <option>請選擇</option>
                <option value={"north"}>北區</option>
                <option value={"east"}>東區</option>
                <option value={"middle"}>中區</option>
                <option value={"south"}>南區</option>
                <option value={"external"}>外島</option>
              </select>
            </div>
            <div style={{ textAlign: "center" }} id="o-1">
              <img src={cropInfo.crop_image} />
            </div>
          </div>

          <div className={style.each_field_plan}>
            <div className={style.equal_field}>
              {cities.length === 0 ? null : (
                <div>
                  <label htmlFor="cityselect">{t("city")}</label>
                  <select style={{ backgroundImage: "url(/asset/vector.png)" }}
                   id={style.city}
                    defaultValue={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option selected>Choose...</option>
                    {cities.map((city, idx) => {
                      return (
                        <option key={idx} value={JSON.stringify(city)}>
                          {t(`${city.City}`)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <div>
              <label>Grow Start Date</label>
              <select
                name=""
                style={{ backgroundImage: "url(/asset/vector.png)" }}
                id={style.city}
                value={date}
                onChange={(e) => setDate(e.target.value)}
              >
                <option selected>Choose...</option>
                {forecast.map((date) => {
                  return (
                    <option key={date.Date} value={JSON.stringify(date)}>
                      {date.Date}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <div className={style.equal_field}>
              {regionCity.length === 0 ? null : (
                <div>
                  <label htmlFor="cityselect">{t("city")}</label>
                  <select
                    id="cityselect"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option selected>Choose...</option>
                    {regionCity.map((city, idx) => {
                      return (
                        <option key={idx} value={JSON.stringify(city)}>
                          {t(`${city.City}`)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div> */}
          </div>

          <div className={style.each_field_plan}>
            <div>
              <label>Grow area(公頃 hectare)</label>
              <input
                value={fieldsize}
                onChange={(e) => setFieldSize(e.target.value)}
                className={style.useCropinputbox}
                type="text"
              />
            </div>
            <div>
              <label>Note</label>
              <input
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className={style.useCropinputbox}
                type="text"
              />
            </div>
          </div>
          <br />
          <div
            className={style.each_field_plan}
            style={{ textAlign: "center" }}
          >
            <div>
              <button
                className={style.btn_1}
                onClick={BacktoCropInfoPage}
                value=""
              >
                Not my Veg
              </button>
            </div>
            <div>
              {error != "" ? <small>{error}</small> : null}
              <input
                type="submit"
                value="Add to garden"
                className={style.btn_2}
              />

              {/* </button> */}
            </div>
          </div>
        </form>
      </div>
      <br />
      <br />
    </div>
  );
};

export default UserCropAdd;
