import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import style from './profileEdit.module.css'
import axios from 'axios'
import { config } from '../../setting'


// todo : should have alart box after password is change
const UserUpdagePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const params = useParams();
    const onResetClicked = async () => {
        if (confirmPassword === newPassword) {
            try {
                console.log(params)
                await axios.put(`${config.url.NEW_API}/users/passwordreset/${params.id}`, { newPassword: newPassword, oldPassword: oldPassword })
                .then(() =>{
                    history.push('/user/profile')
                })
               
            } catch (e) {
                console.log(e)
           
                setErrorMessage("old pass word is incorrect")
            }
        }else{
            setErrorMessage("Confirm password is not the same as your new password")
        }

    }
    const history = useHistory();

    return (

        <div className={style.UpdatePasswordContainer}>
            <h1>reset password</h1>
           
            <div>
                <label htmlFor="oldpassword"> Old password  </label>
                <input value={oldPassword} onChange={e => setOldPassword(e.target.value)} id='oldpassword' type="password" />
            </div>

            <div>
                <label htmlFor="newPassword">Set New Password  </label>
                <input id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" />
            </div>
            <div>
                <label htmlFor="confirmpassword">Confirm Password  </label>
                <input id="confirmpassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" />
            </div>
            {errorMessage && <div>{errorMessage}</div>}
            <button disabled={!newPassword || !confirmPassword} onClick={onResetClicked}>reset password</button>
            <button onClick={() => history.push('/user/profile')}>Cancel</button>
        </div>
    )
}

export default UserUpdagePassword