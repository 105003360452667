import React from "react";
import style from "./userGarden.module.css";
import { Link } from "react-router-dom";

const UserLikeCard = (props) => {
  return (
    <div>
      <Link to={'/cropPage/'+props.like.cropId}>
      <div className={style.wishing_images}>
        <div> 
          <img className={style.userlikeImages} src={props.like.cropbase.crop_image} /> 
          <h2 className={style.wishing_list_1}>
            {props.like.cropbase.common_name}
          </h2>
        </div>
      </div>
      </Link>
    </div>
  );
};

export default UserLikeCard;
