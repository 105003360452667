import React, { Component } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import KcValue from './KcValue'
import KcDisplay from './kcDisplay';
import { Link } from 'react-router-dom';
import { config } from '../../setting';

export default class EditCropTemplate extends Component {

    constructor(props) {
        super();
        this.state = {
            crop: {},
            discription: "",
            crop_image: "",
            image_name: "Choose File",
            file: "",
            filename: "",
            kc: {}
        }

        this.fileSelected = this.fileSelected.bind(this);
        this.imgOnSubmit = this.imgOnSubmit.bind(this);
    }

    componentDidMount() {

        axios.get(config.url.API_URL+'/crops/' + this.props.match.params.id)
            .then(response => {
                console.log(response.data[0].chinese_name)
                this.setState({ crop: response.data[0], discription: response.data[0].discription })
            })
            .catch((error) => {
                console.log(error);
            })

        axios.get(config.url.API_URL+'/kc/' + this.props.match.params.id)
            .then(res => {
                if (res.data[0] !== "undefined") {
                    console.log(res.data[0])
                    this.setState({ kc: res.data[0] })

                } else {
                    console.log(res.data[0])
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    async imgOnSubmit() {

        const fd = new FormData();
        fd.append('file', this.state.file);

        try {
            const res = await axios
                .put(config.url.API_URL+'/crops/upload/' + this.props.match.params.id, fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            const { fileName, filePath } = res.data
            this.setState({ crop_image: filePath, image_name: fileName });

        } catch (error) {
            if (error.response.status === 500) {
                console.log('There was a problem with the server')
            } else {
                console.log(error.response.data.msg)
            }
        }

    }

    fileSelected(event) {
        this.setState({ file: event.target.files[0], filename: event.target.files[0].name });
        console.log(event.target.files[0])
    }


    render() {


        return (
            <div>
                <p>crop Templet</p>
                <Link>edit</Link>
                {this.state.crop.crop_image ?
                    <div><img src={this.state.crop.crop_image} alt="" /></div>
                    : <div>
                        <form onSubmit={this.imgOnSubmit}>
                            <input type='file' id='customFile' onChange={this.fileSelected} />
                            <label htmlFor='customFile'>
                                {this.state.fileName}
                            </label>
                            <input type="submit" value="update image" className="btn btn-primary" />
                        </form>
                        <img style={{ width: '100px' }} src='/uploads/sample.png' alt="sample file" />
                    </div>}
                <p>{this.state.crop.chinese_name}</p>
                <p>{parse(this.state.discription)}</p>

                {this.state.kc ? <KcDisplay key={this.state.kc.id} kc={this.state.kc} /> : <KcValue />}

            </div>
        );
    }
}

