import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import style from './homepage.module.css'
import AOS from 'aos';

import About from './about';
import Service from './service';
import Contact from './contact';
import Footer from './footer';
import Team from './team';
import Howitwork from './howitwork';
import NewsSubscribe from './NewsSubscribe';



function Mainhomepage() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000
    });
  }, []);

  return (
    <div >
      {/* banner  */}
      <div className={style.Hero_section} data-aos="zoom-in" data-aos-duration="1000" id='home'>
        <div className={style.left_area}>
          <h1>Agrolly Taiwan<br />{t('head-1')}</h1>
          <p>{t('head-2')}
          </p>
          <a href="/login" className={style.btn_hero}>{t('head-3')}</a>
          {/* <div className={style.hero_social_links}>
            <a href="#"><i className="fa fa-twitter"></i></a>
            <a href="#"><i className="fa fa-instagram"></i></a>
            <a href="#"><i className="fa fa-facebook"></i></a>
          </div> */}
        </div>
        <div className={style.right_area}>
          <img src="asset/homePageFamer.png" />
        </div>
      </div>

      <About />
      <Service />
      <Howitwork/> 
      <Team />
      <NewsSubscribe/>
      <Footer />

    </div>
  );
}

export default Mainhomepage;