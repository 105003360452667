import { useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import PasswordResetSuccess from './passwordResetSuccess'
import PasswordResetFail from './passwordResetFail'
import { config } from '../../setting'
import style from './forgotpassword.module.css'
import backgroundImage from './forgetpasswordBack.png'

const PasswordResetLanding = () => {
    const [IsSuccess, setIsSuccess] = useState('');
    const [IsFailure, setIsFailure] = useState('');
    const [PasswordValue, setPasswordValue] = useState('')
    const [ConfrimPasswordValue, setConfrimPasswordValue] = useState('')
    const params = useParams();

    if (IsSuccess) return <PasswordResetSuccess />
    if (IsFailure) return <PasswordResetFail />

    const onResetClicked = async () => {
        console.log(params.passwordResetCode)
        try {
            await axios.put(`${config.url.NEW_API}/users/resetPassword/${params.passwordResetCode}`, { password: PasswordValue })
            setIsSuccess(true);
        } catch (e) {
            setIsFailure(true)
        }
    }

    return (
        <div style={{ backgroundImage: `url(${backgroundImage})` }} className={style.forgotpasswordback} >
            <div className={style.forgetpassword_Container}>
                <h1>Reset your password</h1>
                <p><b>Welcome back! please reset your password</b></p>
                <div className={style.forgetPasswordBox}>
                <img className={style.logoimage} src={'/asset/agrollyTaiwanlogo-white.png'} alt="" />
                    <div className={style.ResetPasswordInputBox}>
                        <label htmlFor="">New Password</label>
                        <input type='password' value={PasswordValue} onChange={e => { setPasswordValue(e.target.value) }} placeholder="password" />
                        <label htmlFor="">Confirm Password</label>
                        <input type='password' value={ConfrimPasswordValue} onChange={e => { setConfrimPasswordValue(e.target.value) }} placeholder="Confirm password" />
                    </div>
                    <div>
                        <button
                            disabled={!PasswordValue || !ConfrimPasswordValue || PasswordValue !== ConfrimPasswordValue}
                            onClick={onResetClicked}
                            className={style.sendEmailbtn}>
                            Reset Password
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PasswordResetLanding