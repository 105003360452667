import React from 'react';
import style from './card.module.css'
import { useTranslation } from 'react-i18next';

const WeeklyDisplayCard = (props) => {

    const { t } = useTranslation();
    var avaragetemp = props.tempData.avgTemp
    var Days = props.tempData.Days

    var vegedata = props.Kc
    // //get po_place data for sun light
    var startDay = props.tempData.StartDay
    var thismonth = new Date((startDay).replace(/-/g, '\/')).getMonth()+1;
    // thismonth = parseInt(thismonth)
    var poName = "N_" + thismonth
    var mouthPoparam = props.poData[poName]

    


    var Irrigation = Number.parseFloat((mouthPoparam * (0.46 * avaragetemp + 8) * vegedata) * Days).toFixed(2)

    //console.log(Irrigation)

    var rain = (props.tempData.weekRainfall).toFixed(2)

    const squareSize = props.unit
    
    // const unit = squareSize != 0 ? Number.parseInt(squareSize * Irrigation) : Number.parseInt(Irrigation)

    
    return (
       
        <div className={style.WeekcontentInCard}>
            <h4 className={style.weektext}> {props.weeknum} {t('week')} ({Days} {t('days')})</h4>
            <p>{startDay}  {t('to')}  {props.tempData.EndDay}</p>
            <p> {t('temperature')}: 
                <span style={props.tempData.MinWeekTemp > props.cropTmin ? { color: "#1E90FF" } : { color: "red" }}>{props.tempData.MinWeekTemp}&deg;C</span>
                -
                <span style={props.tempData.MaxWeekTemp <= props.cropTmax ? { color: "#1E90FF" } : { color: "red" }}>{props.tempData.MaxWeekTemp}&deg;C</span>
            </p>
            <p >{t('rain')} : {rain} <small>mm</small></p>
            <p style={props.tempData.weekRainfall > Irrigation ? { color: "#1E90FF" } : { color: "red" }}>{t('water-need-unit')} : {Irrigation}<small>mm</small></p>
            {/* {squareSize == null ? "" :<p>{props.area} {t('square-meter')} : {unit} <small>mm</small></p>} */}
        </div>
    );
}

export default WeeklyDisplayCard;


// style={props.tempData.weekRainfall > Irrigation ? { color: "#1E90FF" } : { color: "red" }}