import React from 'react'
import style from './homepage.module.css'
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    return (
        <div className={style.news_letter} id="contact">
            <div className={style.main_box}>
                <div className={style.left_area_box} data-aos="fade-right" data-aos-duration="2000">
                    <img src="/asset/subscribeimage2.jpg" alt="newsletter image" />
                </div>
                <div className={style.right_area_box} data-aos="fade-left" data-aos-duration="2000">
                    <h1>{t('subscribe')} Agrolly Taiwan</h1>
                    <p>comming up informations send to you by email</p>
                    <form action="https://gmail.us14.list-manage.com/subscribe/post?u=ee813c064d55e1c9b3d5490ae&amp;id=7bf5577de0&amp;f_id=006695e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                        <input type="text" name="" className={style.sub_input} placeholder="Enter your Email..." />
                        <button className={style.sub_btn}>{t('subscribe')}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact
