import React, { useState } from 'react';
import style from './login.module.css'
import { Link, useHistory } from 'react-router-dom';
import Facebook from '../Facebook';
import Google from '../Google'
import axios from 'axios'
import { config } from '../../setting'
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("")
    const [passoword, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const history = useHistory();

    const onSubmit = async(e) => {
        e.preventDefault();

        const userLogin = {
            email: email,
            password: passoword
        }

        try{
            await axios.post(config.url.NEW_API + '/users/login', userLogin) 
            .then(response => {
                console.log(response.data.token)
                localStorage.setItem('usertoken', response.data.token)
                // history.push('/user/profile')
                window.location = "/user/profile";
            })
        }catch(e){
            console.log(e.response.data.error)
            setErrorMessage(e.response.data.error)
        }
       
    }


    return (
        <div className={style.container}>
            <div className={style.login_wrapper}
                style={{ backgroundImage: "url(/asset/loginbackgroundImage.png)" }}>
                <div className={style.login_heading}>
                    <h1>Welcome Back!</h1>
                </div>
                <div className={style.main_form}>
                    <div className={style.left_form}>
                        <img src={'/asset/loginImg.jpeg'} alt="" />
                    </div>
                    <div className={style.right_form}>
                        <div className={style.login_logo}>
                            <img src={'/asset/agrollyTaiwanlogo-black.png'} alt="" />
                        </div>
                        <div className={style.login_form}>
                            <form onSubmit={onSubmit}>
                                <label htmlFor="InputEmail">{t('email')}</label>
                                <input className={style.inputBox}
                                    type="email"
                                    id="InputEmail"
                                    placeholder='someone@gmail.com'
                                    aria-describedby="emailHelp"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)} />
                                <label htmlFor="InputPassword">{t('password')}</label>
                                <input className={style.inputBox}
                                    type="password"
                                    id="InputPassword"
                                    placeholder='password 密碼'
                                    value={passoword}
                                    onChange={e => setPassword(e.target.value)} />
                                <br />
                                <a href="/user/forgotpassword" className={style.forget_password}>{t('forget-password')}?</a>
                                {errorMessage && <div>{errorMessage}</div>}
                                <input className={style.login_btn}
                                    style={{ width: "70%" }}
                                    type="submit"
                                    value={t('login')}
                                    align="center"
                                />

                            </form>
                            <div className={style.otheroption}>
                                <p>
                                    --- or sigh up with ---
                                </p>
                                <span style={{ display: "inline" }}>
                                    <Facebook />
                                    <Google />
                                </span>
                                <p>--- Don't have an account ? ---</p>

                                <Link to="/user/register">
                                    <button className={
                                        style.signup_btn
                                    }>
                                        {t('sign-up')}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login


// export default class UserLogin extends Component {



//     constructor(props) {
//         super(props);

//         this.onChangeEmail = this.onChangeEmail.bind(this)
//         this.onChangePassword = this.onChangePassword.bind(this)
//         this.onSubmit = this.onSubmit.bind(this)

//         this.state = {
//             email: '',
//             password: '',
//             errorMessage: '',

//         }
//     }

//     onChangeEmail(e) {
//         this.setState({ email: e.target.value });
//     }

//     onChangePassword(e) {
//         this.setState({ password: e.target.value });
//     }

//     onSubmit(e) {
//         e.preventDefault();

//         const userLogin = {
//             email: this.state.email,
//             password: this.state.password
//         }

//         axios
//             .post(config.url.API_URL + '/user/login', userLogin)
//             .then(response => {
//                 console.log(response.data.token)
//                 // localStorage.setItem('usertoken', response.token)
//                 // window.location = "/user/profile"
//             })
//             .catch(err => {
//                 console.log(err)
//             })

//         // login(userLogin).then(res => {
//         //     if (res) { // console.log(res)
//         //         localStorage.setItem('usertoken', res.token)
//         //         window.location = "/user/profile"
//         //     }else{

//         //     }
//         // })
//     }

//     render() {
//         return (
//             <div className={style.container}>
//                 <div className={style.login_wrapper}
//                     style={{ backgroundImage: "url(/asset/loginbackgroundImage.png)" }}>
//                     <div className={style.login_heading}>
//                         <h1>Welcome Back!</h1>
//                     </div>
//                     <div className={style.main_form}>
//                         <div className={style.left_form}>
//                             <img src={'/asset/loginImg.jpeg'} alt="" />
//                         </div>
//                         <div className={style.right_form}>
//                             <div className={style.login_logo}>
//                                 <img src={'/asset/agrollyTaiwanlogo-black.png'}
//                                     alt="" />
//                             </div>
//                             <div className={style.login_form}>
//                                 <form onSubmit={this.onSubmit}>
//                                     <label htmlFor="InputEmail">Email</label>
//                                     <input className={style.inputBox}
//                                         type="email"
//                                         id="InputEmail"
//                                         aria-describedby="emailHelp"
//                                         value={this.state.email}
//                                         onChange={this.onChangeEmail} />
//                                     <label htmlFor="InputPassword">Password</label>
//                                     <input className={style.inputBox}
//                                         type="password"
//                                         id="InputPassword"
//                                         value={this.state.password}
//                                         onChange={this.onChangePassword} />
//                                     <br />
//                                     <a href="/user/forgotpassword" className={style.forget_password}>Forget Password?</a>

//                                     <input className={style.login_btn}
//                                         style={{ width: "70%" }}
//                                         type="submit"
//                                         value="Log In"
//                                         align="center"
//                                     />

//                                 </form>
//                                 <div className={style.otheroption}>
//                                     <p>
//                                         --- or sigh up with ---
//                                     </p>
//                                     <span style={{ display: "inline" }}>
//                                         <Facebook />
//                                         <Google />
//                                     </span>
//                                     <p>--- Don't have an account ? ---</p>

//                                     <Link to="/user/register">
//                                         <button className={
//                                             style.signup_btn
//                                         }>
//                                             Sign Up
//                                         </button>
//                                     </Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }
