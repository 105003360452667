import React, { useState, useEffect } from 'react';
import axios from 'axios';
import style from './comment.module.css'
import {config} from '../../../setting';

const UserCommentCard = (props) => {

    const [userInfo, setuserInfo] = useState({})

    function getuserInfo() {
        axios.get( config.url.API_URL + '/user/' + props.megInfo.user_id)
            .then((res) => {
                console.log(res.data)
                setuserInfo(res.data)
            })
    }


    useEffect(() => {
        getuserInfo()
    }, []);



    return (
        <div className={style.commentContainer}>
            <div className={style.userInfo}>
                <div className={style.userImgContainer}>
                    <img src={userInfo.user_image} alt="" />
                </div>
                <span> {userInfo.first_name}</span>
            </div>
            <div className={style.messageContainer}>
                <p>{props.megInfo.message}</p>
            </div>
        </div>
    );
}

export default UserCommentCard;