import React ,{useEffect} from 'react'
import style from './twhomepage.module.css'
import { useTranslation } from 'react-i18next';
import AOS from 'aos';

const Twheader = () => {

    const { t } = useTranslation();

    useEffect(() => {
      AOS.init({
        duration: 1500
      });
    }, []);

    return (
        <div>
            <div className={style.Hero_section} data-aos="zoom-in" data-aos-duration="2000" id='home'>
                <div className={style.left_area}>
                    <h1>Agrolly Taiwan <br />科技農業</h1>
                    <p>科技植入，大數據分析氣候，灌溉及溫度，公開資訊及共享資訊，創建提供農業討論區
                    </p>
                    <a href="#" className={style.btn_hero}>開始</a>
                    {/* <div className={style.hero_social_links}>
                        <a href="#"><i className="fa fa-twitter"></i></a>
                        <a href="#"><i className="fa fa-instagram"></i></a>
                        <a href="#"><i className="fa fa-facebook"></i></a>
                    </div> */}
                </div>
                <div className={style.right_area}>
                    <img src="/asset/homePageFamer.png" />
                </div>
            </div>
        </div>
    )
}

export default Twheader