import React from 'react';
import parse from 'html-react-parser';
import style from '../cropPageStyle.module.css'
import { Link } from "react-router-dom";

const PestDisplayCard = (props) => {

    const pestData = props.pest.pestdisease


    return (
        <div className={style.pestInfo}>
            <div>
                <b>
                    <Link to={"/pestprofile/" + pestData.id} >
                        <p className={style.pestName}>{pestData.name}({pestData.name_en})</p>
                    </Link>
                </b>
                <p>{parse(`${pestData.description}`)}</p>
            </div>

            <div className={style.pestInfoImage}>
                <img src={pestData.pic_path} alt="" />
            </div>
            <hr />
        </div>

    );
}

export default PestDisplayCard;