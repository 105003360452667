import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import style from "./forgotpassword.module.css";
import backgroundImage from "./forgetpasswordBack.png";

const EmailVerificationSuccess = () => {
  const history = useHistory();
  return (
    <div
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className={style.forgotpasswordback}
    >
      <div className={style.forgetpassword_Container}>
        <h1>Success</h1>
        <p>
          We successfully verify your Email, you are now able to login will full
          function{" "}
        </p>
        <p>我們成功驗證了你的信箱，歡迎使用 Agrolly Taiwan</p>
        <img style={{ margin: "4%" }} src="/asset/correctImage.png" alt="" />
        <div>
          <button
            className={style.sendEmailbtn}
            onClick={() => history.push("/user/login")}
          >
            Go to login
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationSuccess;
