import axios from "axios"
import { config } from '../../setting'

const calculateIrrigationbyWeek = async(cropId, selectdate, region, city, po_place) =>{
    const getKc = await getKcValueAndDays(cropId)
    console.log(getKc)
    const date = selectdate;
    const forcase = await getforcaseData(region, city)
    console.log(forcase)
    const calculatebyWeek = await calculateAvgbyWeek(date, getKc, forcase)
    return calculatebyWeek
}

const getKcValueAndDays =  async(cropId) => {
    const cropKcandDate = await axios.get(config.url.NEW_API + "/crops/detailinfo/" + cropId)
    .then((response) =>{
        return response.data
    }).catch((error) => {
        console.log(error);
      });

    return cropKcandDate.kcvalue[0]
}

const getforcaseData = async (region, city) => {
    
    if (city !== "") {
      return axios
        .get(config.url.NEW_API + "/forecast/" + region)
        .then((response) => {
          var forcase = [];
          var responseForcaseData = response.data;
          var jsonCity = JSON.parse(city).Code;
          //console.log(region)

          //console.log(responseForcaseData)
          var po_place = JSON.parse(city).po;

          responseForcaseData.map((data, index) => {
            const datekey = "Date.fcst";
            const date = data[datekey];
            const AVAtemperature = "TEMPMEDIA_fcast_" + jsonCity;
            const rainfall = "SNfcast_" + jsonCity + ".mean";
            const maxTemp = "TEMPMAX_fcast_" + jsonCity;
            const minTemp = "TEMPMIN_fcast_" + jsonCity;
            // console.log(data[minTemp])
            forcase.push({
              Index: index,
              Date: date,
              rainfall: data[rainfall],
              maxTemp: data[maxTemp],
              minTemp: data[minTemp],
              avgTemp: data[AVAtemperature],
            });
          });
          // console.log(forcase)
          return forcase;
          // console.log(po_place)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

const calculateAvgbyWeek = async (date, kcdata, forecast) => {
    
    if (date !== "") {
      var choiceDateIndex;

      if (date) {
        choiceDateIndex = JSON.parse(date).Index;
      }

      //console.log(choiceDateIndex);
      //Init state

      var dayOfweek = 7;
      var InitWeekValue = [];
      var DevWeekValue = [];
      var MidWeekValue = [];
      var LastWeekValue = [];
      var harvestWeekValue = [];

      var calStartDay = "";
      var calEndDay = "";
      var weekhightestTemmp = 0;
      var weeklowestTemp = 100;
      var weekrainfall = 0;

      var WeekMaxTempTotal = 0;
      var WeekMinTempTotal = 0;
      let i;
      var maxminTempAvg = 0;

      var InitDay = kcdata.inital_days ;
      // console.log(InitDay)
      // console.log(forecast)
      for (i = 0; i < InitDay; i++) {
        if (dayOfweek > -1) {
          // this is only to get the start day date.
          if (dayOfweek === 7) {
            calStartDay = forecast[choiceDateIndex + i].Date;
          }
          console.log(forecast[choiceDateIndex+i])
          dayOfweek--;
          weekhightestTemmp = Math.max(
            weekhightestTemmp,
            forecast[choiceDateIndex + i].maxTemp
          );
          weeklowestTemp = Math.min(
            weeklowestTemp,
            forecast[choiceDateIndex + i].minTemp
          );
          weekrainfall = weekrainfall + forecast[choiceDateIndex + i].rainfall;
          WeekMaxTempTotal =
            WeekMaxTempTotal + forecast[choiceDateIndex + i].maxTemp;
          WeekMinTempTotal =
            WeekMinTempTotal + forecast[choiceDateIndex + i].minTemp;
        }
        if (dayOfweek == 0) {
          calEndDay = forecast[choiceDateIndex + i].Date;
          maxminTempAvg = (WeekMaxTempTotal / 7 + WeekMinTempTotal / 7) / 2;
          maxminTempAvg =
            Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
          InitWeekValue.push({
            StartDay: calStartDay,
            EndDay: calEndDay,
            MaxWeekTemp: weekhightestTemmp,
            MinWeekTemp: weeklowestTemp,
            weekRainfall:
              Math.round((weekrainfall + Number.EPSILON) * 100) / 100,
            avgTemp: maxminTempAvg,
            Days: 7,
          });
          WeekMaxTempTotal = 0;
          WeekMinTempTotal = 0;
          weekhightestTemmp = 0;
          weeklowestTemp = 100;
          weekrainfall = 0;
          dayOfweek = 7;
        }
      }
      if (dayOfweek !== 0 && dayOfweek !== 7) {
        calEndDay = forecast[choiceDateIndex + i - 1].Date;
        maxminTempAvg =
          (WeekMaxTempTotal / (7 - dayOfweek) +
            WeekMinTempTotal / (7 - dayOfweek)) /
          2;
        maxminTempAvg =
          Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
        InitWeekValue.push({
          StartDay: calStartDay,
          EndDay: calEndDay,
          MaxWeekTemp: weekhightestTemmp,
          MinWeekTemp: weeklowestTemp,
          weekRainfall: Math.round((weekrainfall + Number.EPSILON) * 100) / 100,
          avgTemp: maxminTempAvg,
          Days: 7 - dayOfweek,
        });
        WeekMaxTempTotal = 0;
        WeekMinTempTotal = 0;
        weekhightestTemmp = 0;
        weeklowestTemp = 100;
        weekrainfall = 0;
        dayOfweek = 7;
      }
      // console.log(InitWeekValue)
      var InitWeeks =  InitWeekValue;

      //develop stage
      var DevDay = kcdata.dev_days;
      console.log(DevDay)
      dayOfweek = 7;
      for (i = InitDay; i < InitDay + DevDay; i++) {
        if (dayOfweek > -1) {
          if (dayOfweek == 7) {
            // console.log(forecast[choiceDateIndex + i])
            calStartDay = forecast[choiceDateIndex + i].Date;
          }
          // console.log(forecast[choiceDateIndex + i])
          dayOfweek--;
          weekhightestTemmp = Math.max(
            weekhightestTemmp,
            forecast[choiceDateIndex + i].maxTemp
          );
          weeklowestTemp = Math.min(
            weeklowestTemp,
            forecast[choiceDateIndex + i].minTemp
          );
          weekrainfall = weekrainfall + forecast[choiceDateIndex + i].rainfall;
          WeekMaxTempTotal =
            WeekMaxTempTotal + forecast[choiceDateIndex + i].maxTemp;
          WeekMinTempTotal =
            WeekMinTempTotal + forecast[choiceDateIndex + i].minTemp;
        }
        if (dayOfweek == 0) {
          // console.log(forecast[choiceDateIndex + i].Date)
          calEndDay = forecast[choiceDateIndex + i].Date;
          maxminTempAvg = (WeekMaxTempTotal / 7 + WeekMinTempTotal / 7) / 2;
          maxminTempAvg =
            Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
          DevWeekValue.push({
            StartDay: calStartDay,
            EndDay: calEndDay,
            MaxWeekTemp: weekhightestTemmp,
            MinWeekTemp: weeklowestTemp,
            weekRainfall: weekrainfall,
            avgTemp: maxminTempAvg,
            Days: 7 - dayOfweek,
          });
          WeekMaxTempTotal = 0;
          WeekMinTempTotal = 0;
          weekhightestTemmp = 0;
          weeklowestTemp = 100;
          weekrainfall = 0;
          dayOfweek = 7;
        }
      }
      if (dayOfweek !== 0 && dayOfweek !== 7) {
        calEndDay = forecast[choiceDateIndex + i - 1].Date;
        maxminTempAvg =
          (WeekMaxTempTotal / (7 - dayOfweek) +
            WeekMinTempTotal / (7 - dayOfweek)) /
          2;
        maxminTempAvg =
          Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
        DevWeekValue.push({
          StartDay: calStartDay,
          EndDay: calEndDay,
          MaxWeekTemp: weekhightestTemmp,
          MinWeekTemp: weeklowestTemp,
          weekRainfall: weekrainfall,
          avgTemp: maxminTempAvg,
          Days: 7 - dayOfweek,
        });
        WeekMaxTempTotal = 0;
        WeekMinTempTotal = 0;
        weekhightestTemmp = 0;
        weeklowestTemp = 100;
        weekrainfall = 0;
        dayOfweek = 7;
      }
      // console.log(DevWeekValue)
      var developWeeks = DevWeekValue;

      var MidDay = kcdata.mid_days;
      //console.log(MidDay)
      dayOfweek = 7;
      for (i = InitDay + DevDay; i < InitDay + DevDay + MidDay; i++) {
        if (dayOfweek > -1) {
          if (dayOfweek == 7) {
            calStartDay = forecast[choiceDateIndex + i].Date;
            // console.log(forecast[choiceDateIndex+i].Date)
          }
          // console.log(forecast[choiceDateIndex+i])
          dayOfweek--;
          weekhightestTemmp = Math.max(
            weekhightestTemmp,
            forecast[choiceDateIndex + i].maxTemp
          );
          weeklowestTemp = Math.min(
            weeklowestTemp,
            forecast[choiceDateIndex + i].minTemp
          );
          weekrainfall = weekrainfall + forecast[choiceDateIndex + i].rainfall;
          WeekMaxTempTotal =
            WeekMaxTempTotal + forecast[choiceDateIndex + i].maxTemp;
          WeekMinTempTotal =
            WeekMinTempTotal + forecast[choiceDateIndex + i].minTemp;
        }
        if (dayOfweek == 0) {
          // console.log(forecast[choiceDateIndex+i].Date)
          calEndDay = forecast[choiceDateIndex + i].Date;
          maxminTempAvg = (WeekMaxTempTotal / 7 + WeekMinTempTotal / 7) / 2;
          maxminTempAvg =
            Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
          MidWeekValue.push({
            StartDay: calStartDay,
            EndDay: calEndDay,
            MaxWeekTemp: weekhightestTemmp,
            MinWeekTemp: weeklowestTemp,
            weekRainfall: weekrainfall,
            avgTemp: maxminTempAvg,
            Days: 7 - dayOfweek,
          });
          WeekMaxTempTotal = 0;
          WeekMinTempTotal = 0;
          weekhightestTemmp = 0;
          weeklowestTemp = 100;
          weekrainfall = 0;
          dayOfweek = 7;
        }
      }
      if (dayOfweek != 0 && dayOfweek != 7) {
        calEndDay = forecast[choiceDateIndex + i - 1].Date;
        maxminTempAvg =
          (WeekMaxTempTotal / (7 - dayOfweek) +
            WeekMinTempTotal / (7 - dayOfweek)) /
          2;
        maxminTempAvg =
          Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
        MidWeekValue.push({
          StartDay: calStartDay,
          EndDay: calEndDay,
          MaxWeekTemp: weekhightestTemmp,
          MinWeekTemp: weeklowestTemp,
          weekRainfall: weekrainfall,
          avgTemp: maxminTempAvg,
          Days: 7 - dayOfweek,
        });
        WeekMaxTempTotal = 0;
        WeekMinTempTotal = 0;
        weekhightestTemmp = 0;
        weeklowestTemp = 100;
        weekrainfall = 0;
        dayOfweek = 7;
      }

      // console.log(MidWeekValue)
     var midWeeks = MidWeekValue;

      var LastDay = kcdata.last_days;
      //(LastDay)
      dayOfweek = 7;
      for (
        i = InitDay + DevDay + MidDay;
        i < InitDay + DevDay + MidDay + LastDay;
        i++
      ) {
        if (dayOfweek > -1) {
          if (dayOfweek == 7) {
            calStartDay = forecast[choiceDateIndex + i].Date;
            // console.log(forecast[choiceDateIndex+i].Date)
          }
          // console.log(forecast[choiceDateIndex+i])
          dayOfweek--;
          weekhightestTemmp = Math.max(
            weekhightestTemmp,
            forecast[choiceDateIndex + i].maxTemp
          );
          weeklowestTemp = Math.min(
            weeklowestTemp,
            forecast[choiceDateIndex + i].minTemp
          );
          weekrainfall = weekrainfall + forecast[choiceDateIndex + i].rainfall;
          WeekMaxTempTotal =
            WeekMaxTempTotal + forecast[choiceDateIndex + i].maxTemp;
          WeekMinTempTotal =
            WeekMinTempTotal + forecast[choiceDateIndex + i].minTemp;
        }
        if (dayOfweek == 0) {
          // console.log(forecast[choiceDateIndex+i].Date)
          calEndDay = forecast[choiceDateIndex + i].Date;
          maxminTempAvg = (WeekMaxTempTotal / 7 + WeekMinTempTotal / 7) / 2;
          maxminTempAvg =
            Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
          LastWeekValue.push({
            StartDay: calStartDay,
            EndDay: calEndDay,
            MaxWeekTemp: weekhightestTemmp,
            MinWeekTemp: weeklowestTemp,
            weekRainfall: weekrainfall,
            avgTemp: maxminTempAvg,
            Days: 7 - dayOfweek,
          });
          WeekMaxTempTotal = 0;
          WeekMinTempTotal = 0;
          weekhightestTemmp = 0;
          weeklowestTemp = 100;
          weekrainfall = 0;
          dayOfweek = 7;
        }
      }

      if (dayOfweek != 0 && dayOfweek != 7) {
        calEndDay = forecast[choiceDateIndex + i - 1].Date;
        maxminTempAvg =
          (WeekMaxTempTotal / (7 - dayOfweek) +
            WeekMinTempTotal / (7 - dayOfweek)) /
          2;
        maxminTempAvg =
          Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
        LastWeekValue.push({
          StartDay: calStartDay,
          EndDay: calEndDay,
          MaxWeekTemp: weekhightestTemmp,
          MinWeekTemp: weeklowestTemp,
          weekRainfall: weekrainfall,
          avgTemp: maxminTempAvg,
          Days: 7 - dayOfweek,
        });
        WeekMaxTempTotal = 0;
        WeekMinTempTotal = 0;
        weekhightestTemmp = 0;
        weeklowestTemp = 100;
        weekrainfall = 0;
        dayOfweek = 7;
      }

      //console.log(LastWeekValue);
     var lastweeks = LastWeekValue;
    }

    return {InitWeeks:InitWeeks, developWeeks:developWeeks, midWeeks:midWeeks, lastweeks:lastweeks}
    
  };


export { calculateIrrigationbyWeek };