import React from 'react'
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import style from "./plantedCropDetail.module.css";
import Dialog from "../layout/Dialog";
import Compress from 'compress.js';
import DatePicker from 'react-date-picker';
import axios from "axios";
import { config } from "../../setting";


const DiaryEdit = (props) => {

    const [editdiaryContent, setEditDiaryContent] = useState("")
    const [showImageUpdate, SetshowImageUpdate] = useState()

    const [file, setFile] = useState("")
    const [filename, setFilename] = useState("Choose File")
    const [editdiaryDate, setEdittDiaryDate] = useState(new Date());
    const [editdiaryImage, setEditDiaryImage] = useState("")
    const [needUpdate, setNeedUpdaet] = useState(false)
    const [editisOpen, seteditIsOpen] = useState(false)

    const DiaryimgOnSubmit = async(e) => {
        e.preventDefault();

        const fd = new FormData();
        fd.append('file', file);
    
        try {
            const res = await axios.put(config.url.NEW_API + '/diary/uploadimage/' + props.diary.id, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            // console.log(res.data)
            // console.log(res.data.image)
            
            // const { fileName, filePath } = res.data

            await setEditDiaryImage(res.data.image)  
    
        } catch (error) {
            if (error.response.status === 500) {
                console.log('There was a problem with the server')
            } else {
                //no file upload
                console.log(error.response.data.msg)
            }
        }
    
    }

    const getDiaryinfo = async () => {
        console.log(props.diary.id);

        await axios.get(config.url.NEW_API + "/diary/editget/" + props.diary.id).then((res) => {
            console.log(res.data);
            setEditDiaryContent(res.data.content)
            setEdittDiaryDate(Date.parse(res.data.date))
            setEditDiaryImage(res.data.image)
            if (!needUpdate) {
                setNeedUpdaet(true)
            }

        });
    }

    const fileSelected = async (event) => {
        const image = event.target.files[0]

        const compressfile = await resizeImageFn(image)
        setFile(compressfile)
        setFilename(event.target.name)
    }

    async function resizeImageFn(file) {

        const compress = new Compress()

        const resizedImage = await compress.compress([file], {
            size: 1,
            quality: 0.8,
            maxWidth: 300,
            maxHeight: 300,
            resize: true
        })
        const img = resizedImage[0];
        const base64str = img.data
        const imgExt = img.ext
        const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
        return resizedFiile;
    }

    useEffect(() => {
        getDiaryinfo()
    }, []);


    // useEffect(() => {
    //     getDiaryinfo()
    // }, [needUpdate]);


    return (
        <div >
            <button className={style.editPicBtn} style={{ float: "right" }} onClick={(e) => seteditIsOpen(true)}>edit</button>
            <Dialog isOpen={editisOpen} onClose={(e) => seteditIsOpen(false)}>
                <div className={style.editDiaryImageBox}>
                    <img height={100} src={editdiaryImage} />
                    <div>
                        {/* <button className={style.editPicBtn} style={{ float: "right" }}  type="file" onClick={(e) => SetshowImageUpdate(true)}><i className="fas fa-edit"></i></button> */}
                        <label for="myfile">Edit Image:</label>
                        <input type="file" id="myfile" onChange={e => fileSelected(e)} name="myfile" multiple />
                        <button onClick={DiaryimgOnSubmit}> update Image</button>
                    </div>
                </div>
                <br />
                <br />
                <Form >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>作物日誌</Form.Label>
                        <Form.Control value={editdiaryContent} onChange={e => { setEditDiaryContent(e.target.value) }} as="textarea" rows={8} />
                    </Form.Group>
                    <DatePicker value={editdiaryDate} onChange={setEdittDiaryDate} />

                    <br />
                    <br />
                    <button onClick={() => props.EditCropDiray(props.diary.id, editdiaryContent, editdiaryDate)} className="btn btn-primary">Finish Edit</button> {"   "}
                    <button onClick={() => props.DeleteCropDiray(props.diary.id)} className="btn btn-primary">delete</button>
                </Form>
            </Dialog>
        </div >
    )
}

export default DiaryEdit