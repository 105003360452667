import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const EditCropCard = (props) => {


  return (

    <div >
    <Link to={"/editPage/" + props.crops.crop_id}>Edit</Link>
        <div >
          <img src={props.Image} alt={props.crops.commonName} />
        </div>
      <div >
        <div>
            <p>{props.crops.crop_id}</p>
          <h4>{props.crops.chinese_name} {props.crops.common_name}</h4>
          <p>{props.crops.bio_name}</p>
          <p>Water Sensitivity: {props.crops.water_sensitivity}</p>
        </div>
      
      </div>

    </div>

  );
}

export default EditCropCard;