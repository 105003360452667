import React ,{useEffect} from 'react'
import style from './twhomepage.module.css'
import AOS from 'aos';

const TwAbout = () => {

    useEffect(() => {
        AOS.init({
          duration: 1500
        });
      }, []);

    return (
        <div className={style.about_section} id="about">
            <div className={style.about_image} data-aos="fade-right" data-aos-duration="2000">
                <img src="/asset/aboutImage.png" className={style.about_image_1} />
            </div>
            <div className={style.content_area} data-aos="fade-left" data-aos-duration="2000">
                <div className={style.about_content}>
                    <h1 data-aos="fade-right" data-aos-duration="2000">關於我們
                    </h1>
                    <hr className={style.divider_about} />
                    <h2>All You Need To Know About Agrolly Taiwan</h2>
                    <p>
                        Agrolly Taiwan 是一個公開資訊免費提供農夫及學術使用.  Agrolly Taiwan 運用國際ＦＡＯ標準及ＮＡＳＡ數據預測天氣，溫度，提作物所需的植物所需溫度及灌溉量，也同時提供資訊包括病蟲害及肥料等相關資訊，在資訊上提供並之持台灣的農夫
                        Agrolly Taiwan 運用大數據分析預測多種植物的生長資訊及在整段生長週期中所需要的要件，並讓台灣的農夫能隨手可得的，更能在播種前，或播種後事先做分險管理.
                    </p>

                    <p>
                        Agrolly Taiwan 是在多人的幫助下產出的，有來自美國，德國，印度，及國內外的台灣人.
                        Agrolly Taiwan 目的在集結農夫，及幫助農夫在溫室效應的環近下運用科技做出應對。
                    </p>

                </div>
            </div>
            <div style={{ clear: "both" }}></div>
        </div>
    )
}

export default TwAbout