import React from 'react';
import WeeklyDisplayCard from './weeklyDisplayCard';
import laData from '../../../helperdata/latitude.json'
import style from './card.module.css'


const StageCard = (props) => {

    const calInitstage = () => {
        const initTemp = props.initTemp
        const po_place = (props.po).toString()

        const latitude = laData;
        var poData;
        latitude.map(la => {
            if (la.city_long === po_place) {
                poData = la
                console.log(la)
                console.log(props.la)
            }
           

        })
        //console.log(initTemp)
        return initTemp.map((initdata, index) => {
            // console.log(initdata)
            return <WeeklyDisplayCard key={index} weeknum={index + 1} tempData={initdata} Kc={props.kc.inital_kc} poData={poData} cropTmax={props.cropTmax} cropTmin={props.cropTmin} />
        })
    }

    const calDevstage = () => {
        const DevTemp =  props.devTemp
        const po_place = (props.po).toString()

        const latitude = laData;
        var poData;
        latitude.map(la => {
            if (la.city_long === po_place) {
                poData = la
            }
        })
       //console.log(DevTemp)
        return DevTemp.map((devdata, index) => {
            //  console.log(devdata)
            return <WeeklyDisplayCard  key={index} weeknum={index + 1} tempData={devdata} Kc={props.kc.last_kc} poData={poData} cropTmax={props.cropTmax} cropTmin={props.cropTmin}/>
        })
    }

    const calMidstage = () => {
        const MidTemp = props.midTemp
        const po_place = (props.po).toString()

        const latitude = laData;
        var poData;
        latitude.map(la => {
            if (la.city_long === po_place) {
                poData = la
            }
        })

        return MidTemp.map((middata, index) => {
            return <WeeklyDisplayCard  key={index} weeknum={index + 1} tempData={middata} Kc={props.kc.mid_kc} poData={poData} cropTmax={props.cropTmax} cropTmin={props.cropTmin}/>
        })
    }

    const calLaststage = () => {
        const LastTemp = props.lastTemp
        const po_place = (props.po).toString()

        const latitude = laData;
        var poData;
        latitude.map(la => {
            if (la.city_long === po_place) {
                poData = la
            }
        })

        return LastTemp.map((lastdata, index) => {
            return  <WeeklyDisplayCard  key={index} weeknum={index + 1} tempData={lastdata} Kc={props.kc.last_kc} poData={poData} cropTmax={props.cropTmax} cropTmin={props.cropTmin}/>
        })
    }



    return (

        <div >
            <div className={style.stage_no}>
                <h3>Initial stage</h3>
                <div className={style.contentGrid}>
                    {calInitstage()}
                </div>
            </div>
            <div className={style.stage_no}>
                <h3>Crop Development stage</h3>
                <div className={style.contentGrid}>
                    {calDevstage()}
                </div>
            </div>
            <div className={style.stage_no}>
                <h3>Mid season stage</h3>
                <div className={style.contentGrid}>
                    {calMidstage()}
                </div>
            </div>
            <div className={style.stage_no}>
                <h3>Late season stage</h3>
                <div className={style.contentGrid}>
                    {calLaststage()}
                </div>
            </div>
        </div>

    );
}

export default StageCard;

// return <WeeklyDisplayCard maxT={props.maxT} minT={props.minT} key={index} weeknum={index + 1} poData={poData} tempData={middata} Kc={props.devKc} area={props.area} unit={props.unit} />