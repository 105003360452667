import React, { useState } from "react";
import axios from "axios";
import { config } from "../../setting";
import MetaDisplay from "./MetaDisplay";

const CropMata = (props) => {
  const [cropID, setCropId] = useState(0);
  const [kcdata, setkcValue] = useState([]);



  const intialStage = () =>{
    const meta = props.cropmeta

    const po_place = props.po_data

    return meta.InitWeeks.map((week, index) => {
      

        var avaragetemp = week.avgTemp;
        var Days = week.Days;
        var vegedata = props.kcdata.inital_kc;
        var mouthPoparam = 0.1;
        // console.log(vegedata)

        var startDay = week.StartDay
        var thismonth = new Date((startDay).replace(/-/g, '\/')).getMonth()+1;
        var poName = "N_" + thismonth
        mouthPoparam = po_place[poName]

        // console.log(mouthPoparam)

        var requireIrrigation = Number.parseFloat( mouthPoparam * (0.46 * avaragetemp + 8) * vegedata * Days ).toFixed(2);

        return (
          <MetaDisplay week={week} key={index} requireIrrigation={requireIrrigation}/>
        );
      })
  }

  const developStage = () =>{
    const meta = props.cropmeta

    const po_place = props.po_data

    return meta.developWeeks.map((week, index) => {
      
      
        var avaragetemp = week.avgTemp;
        var Days = week.Days;
        var vegedata = props.kcdata.dev_kc;
        var mouthPoparam = 0.1;
        // console.log(vegedata)

        var startDay = week.StartDay
        var thismonth = new Date((startDay).replace(/-/g, '\/')).getMonth()+1;
        var poName = "N_" + thismonth
        mouthPoparam = po_place[poName]

        // console.log(mouthPoparam)

      

        var requireIrrigation = Number.parseFloat( mouthPoparam * (0.46 * avaragetemp + 8) * vegedata * Days ).toFixed(2);

        return (
          <MetaDisplay week={week} key={index} requireIrrigation={requireIrrigation}/>
        );
      })
  }

  const midStage = () =>{
    const meta = props.cropmeta

    const po_place = props.po_data

    return meta.midWeeks.map((week, index) => {
      
       
        var avaragetemp = week.avgTemp;
        var Days = week.Days;
        var vegedata = props.kcdata.mid_kc;
        var mouthPoparam = 0.1;
        // console.log(vegedata)

        var startDay = week.StartDay
        var thismonth = new Date((startDay).replace(/-/g, '\/')).getMonth()+1;
        var poName = "N_" + thismonth
        mouthPoparam = po_place[poName]

        // console.log(mouthPoparam)

      

        var requireIrrigation = Number.parseFloat( mouthPoparam * (0.46 * avaragetemp + 8) * vegedata * Days ).toFixed(2);

        return (
          <MetaDisplay week={week} key={index} requireIrrigation={requireIrrigation}/>
        );
      })
  }

  const lastStage = () =>{
    const meta = props.cropmeta

    const po_place = props.po_data

    return meta.lastweeks.map((week, index) => {
      
      
        var avaragetemp = week.avgTemp;
        var Days = week.Days;
        var vegedata = props.kcdata.last_kc;
        var mouthPoparam = 0.1;
        // console.log(vegedata)

        var startDay = week.StartDay
        var thismonth = new Date((startDay).replace(/-/g, '\/')).getMonth()+1;
        var poName = "N_" + thismonth
        mouthPoparam = po_place[poName]

        // console.log(mouthPoparam)

      

        var requireIrrigation = Number.parseFloat( mouthPoparam * (0.46 * avaragetemp + 8) * vegedata * Days ).toFixed(2);

        return (
          <MetaDisplay week={week} key={index} requireIrrigation={requireIrrigation}/>
        );
      })
  }

  return (
    <div>
      {intialStage()}
      <hr/>
      {developStage()}
      <hr/>
      {midStage()}
      <hr/>
      {lastStage()}
      <hr/>
    </div>
  );
};

export default CropMata;
