import axios from "axios";
import React, { useEffect, useState } from "react";
import style from "./irrigation.module.css";
import jwt_decode from "jwt-decode";

import { useTranslation } from "react-i18next";
import { config } from "../../../setting";
import laData from "../../../helperdata/latitude.json";
import CityName from "../../../helperdata/TaiwanCityData";

import WeekStageCard from "./stageCard";
import MonthlyCard from "./monthDisplayCard";
import VerifyCard from "../../dataverify/verifyCard";

const Irrigation = (props) => {
  const { t } = useTranslation();

  const [kcdata, setKcdata] = useState({});
  const [cropMexTemp, setmaxTemp] = useState(0);
  const [cropMinTemp, setminTemp] = useState(0);

  const [userinfo, setUserInfo] = useState({});

  const [region, setRegion] = useState("");
  const [cities, setCities] = useState([
    "Keelung",
    "Taipei",
    "Yilan",
    "Hsinchu",
    "Taoyuan",
    "Miaoli",
    "Taichung",
    "Zhanghua",
    "Yunlin",
    "Nantou",
    "Jiayi",
    "Tainan",
    "Kaohsiung",
    "Pingtung",
    "Taitung",
    "Hualian",
  ]);
  const [city, setCity] = useState("");

  const [cityupdate, setCityUpdate] = useState(false);

  const [forecast, setForecast] = useState([]);
  const [po_place, setPo_place] = useState("00");
  const [date, setDate] = useState("");

  const [la, setla] = useState([]);

  //week data
  const [InitWeeks, setInitWeek] = useState([]);
  const [DevWeeks, setDavWeek] = useState([]);
  const [MidWeeks, setMidWeek] = useState([]);
  const [LastWeeks, setLastWeek] = useState([]);

  //month data
  const [MonthAvgTemp, setMonthAvgTemp] = useState([]);

  const [show, setShow] = useState("");

  const [measure, setmesure] = useState(0);
  const [measuretime, setmesuretime] = useState("");
  const [unit, setUnit] = useState(0);

  useEffect(() => {
    getCropKC();
    getUserInfo();
  }, []);

  useEffect(() => {
    findCitiesAndPoCode();
    getLatitudeParameter();
    // setDateUpdate(true)
  }, [region]);

  useEffect(() => {
    //get city weather forcase data ready
    getforcaseData();
  }, [city]);

  useEffect(() => {
    calculateAvgbyWeek();
    calculatebyMonth();
    setCityUpdate(true);
    setmesuretime("")
  }, [date]);

  const getCropKC = async () => {
    // console.log(props.kcValue[0])
    setKcdata(props.kcValue[0]);
    setmaxTemp(props.maxT);
    setminTemp(props.minT);
  };

  const getUserInfo = () => {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    setUserInfo(decoded);
    setRegion(decoded.region);
  };

  const findCitiesAndPoCode = async () => {
    if (region !== "") {
      await axios
        .get(config.url.NEW_API + "/forecast/cities/" + region)
        .then((response) => {
          var city = [];
          for (let i = 0; i < response.data.length; i++) {
            city.push({
              City: response.data[i].City,
              Code: response.data[i].Code,
              po: response.data[i].po_place,
            });
          }
          setCities(city);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getLatitudeParameter = async () => {
    const latitude = laData;
    let localpo;
    let localpoVal = [];
    //console.log(latitude)
    var citypoData = po_place === "00" ? "25" : po_place;
    latitude.map((la) => {
      if (citypoData === la.city_long) {
        localpo = la;
      }
    });

    // console.log(localpo)

    localpoVal[0] = localpo.N_1;
    localpoVal[1] = localpo.N_2;
    localpoVal[2] = localpo.N_3;
    localpoVal[3] = localpo.N_4;
    localpoVal[4] = localpo.N_5;
    localpoVal[5] = localpo.N_6;
    localpoVal[6] = localpo.N_7;
    localpoVal[7] = localpo.N_8;
    localpoVal[8] = localpo.N_9;
    localpoVal[9] = localpo.N_10;
    localpoVal[10] = localpo.N_11;
    localpoVal[11] = localpo.N_12;


    // console.log(localpoVal)

    setla(localpoVal);
  };

  const getforcaseData = async () => {
    if (city !== "") {
      axios
        .get(config.url.NEW_API + "/forecast/" + region)
        .then((response) => {
          var forcase = [];
          var responseForcaseData = response.data;
          var jsonCity = JSON.parse(city).Code;

          // console.log(responseForcaseData)
          var po_place = JSON.parse(city).po;
          setPo_place(po_place);

          responseForcaseData.map((data, index) => {
            const datekey = "Date.fcst";
            const date = data[datekey];
            const AVAtemperature = "TEMPMEDIA_fcast_" + jsonCity;
            const rainfall = "SNfcast_" + jsonCity + ".mean";
            const maxTemp = "TEMPMAX_fcast_" + jsonCity;
            const minTemp = "TEMPMIN_fcast_" + jsonCity;
            // console.log(data[minTemp])
            forcase.push({
              Index: index,
              Date: date,
              rainfall: data[rainfall],
              maxTemp: data[maxTemp],
              minTemp: data[minTemp],
              avgTemp: data[AVAtemperature],
            });
          });
          // console.log(forcase)
          setForecast(forcase);
          // console.log(po_place)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const calculatebyMonth = async () => {
    if (date !== "") {
      var startday = new Date(JSON.parse(date).Date.replace(/-/g, "/"));
      var dd = startday.getDate();
      var mm = startday.getMonth();

      let avgtemp = [];

      let selectmonthtemp = 0,
        selectmonthcount = 0,
        selectmonthavgtemp = 0,
        selectmonthrainfall = 0,
        selectmonthmaxtemp = 0,
        selectmonthmintemp = 100;
      let month1temp = 0,
        count1 = 0,
        getavg1 = 0,
        rainfall1 = 0,
        maxtemp1 = 0,
        mintemp1 = 100;
      let month2temp = 0,
        count2 = 0,
        getavg2 = 0,
        rainfall2 = 0,
        maxtemp2 = 0,
        mintemp2 = 100;
      let month3temp = 0,
        count3 = 0,
        getavg3 = 0,
        rainfall3 = 0,
        maxtemp3 = 0,
        mintemp3 = 100;
      let month4temp = 0,
        count4 = 0,
        getavg4 = 0,
        rainfall4 = 0,
        maxtemp4 = 0,
        mintemp4 = 100;
      let month5temp = 0,
        count5 = 0,
        getavg5 = 0,
        rainfall5 = 0,
        maxtemp5 = 0,
        mintemp5 = 100;
      let month6temp = 0,
        count6 = 0,
        getavg6 = 0,
        rainfall6 = 0,
        maxtemp6 = 0,
        mintemp6 = 100;
      let month7temp = 0,
        count7 = 0,
        getavg7 = 0,
        rainfall7 = 0,
        maxtemp7 = 0,
        mintemp7 = 100;
      let month8temp = 0,
        count8 = 0,
        getavg8 = 0,
        rainfall8 = 0,
        maxtemp8 = 0,
        mintemp8 = 100;
      let month9temp = 0,
        count9 = 0,
        getavg9 = 0,
        rainfall9 = 0,
        maxtemp9 = 0,
        mintemp9 = 100;
      let month10temp = 0,
        count10 = 0,
        getavg10 = 0,
        rainfall10 = 0,
        maxtemp10 = 0,
        mintemp10 = 100;
      let month11temp = 0,
        count11 = 0,
        getavg11 = 0,
        rainfall11 = 0,
        maxtemp11 = 0,
        mintemp11 = 100;
      let month12temp = 0,
        count12 = 0,
        getavg12 = 0,
        rainfall12 = 0,
        maxtemp12 = 0,
        mintemp12 = 100;

      axios.get(config.url.NEW_API + "/forecast/" + region).then((response) => {
        var responseForcaseData = response.data;
        var jsonCity = JSON.parse(city).Code;

        responseForcaseData.map((data, index) => {
          const date = data["Date.fcst"].replace(/-/g, "/");
          const datefomat = new Date(date);
          // console.log(date)
          const getmouth = datefomat.getMonth();
          const getdate = datefomat.getDate();
          const AVAtemperature = "TEMPMEDIA_fcast_" + jsonCity;
          const rainfall = "SNfcast_" + jsonCity + ".mean";
          const maxTemp = "TEMPMAX_fcast_" + jsonCity;
          const minTemp = "TEMPMIN_fcast_" + jsonCity;

          // selected month
          if (getmouth === mm) {
            if (getdate >= dd) {
              selectmonthtemp += data[AVAtemperature];
              selectmonthrainfall += data[rainfall];
              selectmonthcount++;
              selectmonthmaxtemp = Math.max(data[maxTemp], selectmonthmaxtemp);
              selectmonthmintemp = Math.min(data[minTemp], selectmonthmintemp);
            }
          }

          if (getmouth === 0) {
            // console.log(date)
            // console.log("January")
            // console.log(month1temp)
            month1temp += data[AVAtemperature];
            rainfall1 += data[rainfall];
            count1++;
            maxtemp1 = Math.max(data[maxTemp], maxtemp1);
            mintemp1 = Math.min(data[minTemp], mintemp1);
          } else if (getmouth === 1) {
            month2temp += data[AVAtemperature];
            rainfall2 += data[rainfall];
            count2++;
            maxtemp2 = Math.max(data[maxTemp], maxtemp2);
            mintemp2 = Math.min(data[minTemp], mintemp2);
          } else if (getmouth === 2) {
            month3temp += data[AVAtemperature];
            rainfall3 += data[rainfall];
            count3++;
            maxtemp3 = Math.max(data[maxTemp], maxtemp3);
            mintemp3 = Math.min(data[minTemp], mintemp3);
          } else if (getmouth === 3) {
            month4temp += data[AVAtemperature];
            rainfall4 += data[rainfall];
            count4++;
            maxtemp4 = Math.max(data[maxTemp], maxtemp4);
            mintemp4 = Math.min(data[minTemp], mintemp4);
          } else if (getmouth === 4) {
            month5temp += data[AVAtemperature];
            rainfall5 += data[rainfall];
            count5++;
            maxtemp5 = Math.max(data[maxTemp], maxtemp5);
            mintemp5 = Math.min(data[minTemp], mintemp5);
          } else if (getmouth === 5) {
            month6temp += data[AVAtemperature];
            rainfall6 += data[rainfall];
            count6++;
            maxtemp6 = Math.max(data[maxTemp], maxtemp6);
            mintemp6 = Math.min(data[minTemp], mintemp6);
            //console.log(data[minTemp])
          } else if (getmouth === 6) {
            month7temp += data[AVAtemperature];
            rainfall7 += data[rainfall];
            count7++;
            maxtemp7 = Math.max(data[maxTemp], maxtemp7);
            mintemp7 = Math.min(data[minTemp], mintemp7);
          } else if (getmouth === 7) {
            month8temp += data[AVAtemperature];
            rainfall8 += data[rainfall];
            count8++;
            maxtemp8 = Math.max(data[maxTemp], maxtemp8);
            mintemp8 = Math.min(data[minTemp], mintemp8);
          } else if (getmouth === 8) {
            month9temp += data[AVAtemperature];
            rainfall9 += data[rainfall];
            count9++;
            maxtemp9 = Math.max(data[maxTemp], maxtemp9);
            mintemp9 = Math.min(data[minTemp], mintemp9);
          } else if (getmouth === 9) {
            month10temp += data[AVAtemperature];
            rainfall10 += data[rainfall];
            count10++;
            maxtemp10 = Math.max(data[maxTemp], maxtemp10);
            mintemp10 = Math.min(data[minTemp], mintemp10);
          } else if (getmouth === 10) {
            month11temp += data[AVAtemperature];
            rainfall11 += data[rainfall];
            count11++;
            maxtemp11 = Math.max(data[maxTemp], maxtemp11);
            mintemp11 = Math.min(data[minTemp], mintemp11);
          } else if (getmouth === 11) {
            month12temp += data[AVAtemperature];
            rainfall12 += data[rainfall];
            count12++;
            maxtemp12 = Math.max(data[maxTemp], maxtemp12);
            mintemp12 = Math.min(data[minTemp], mintemp12);
          }
        });

        selectmonthavgtemp = selectmonthtemp / selectmonthcount;
        getavg1 = month1temp / count1;
        getavg2 = month2temp / count2;
        getavg3 = month3temp / count3;
        getavg4 = month4temp / count4;
        getavg5 = month5temp / count5;
        getavg6 = month6temp / count6;
        getavg7 = month7temp / count7;
        getavg8 = month8temp / count8;
        getavg9 = month9temp / count9;
        getavg10 = month10temp / count10;
        getavg11 = month11temp / count11;
        getavg12 = month12temp / count12;

        avgtemp[0] = {
          month:"January",
          avgTemp: Math.round(getavg1),
          Rain: Math.round(rainfall1),
          maxTemp: maxtemp1,
          minTemp: mintemp1,
          days: count1,
        };
        avgtemp[1] = {
          month:"February",
          avgTemp: Math.round(getavg2),
          Rain: Math.round(rainfall2),
          maxTemp: maxtemp2,
          minTemp: mintemp2,
          days: count2,
        };
        avgtemp[2] = {
          month:"March",
          avgTemp: Math.round(getavg3),
          Rain: Math.round(rainfall3),
          maxTemp: maxtemp3,
          minTemp: mintemp3,
          days: count3,
        };
        avgtemp[3] = {
          month:"April",
          avgTemp: Math.round(getavg4),
          Rain: Math.round(rainfall4),
          maxTemp: maxtemp4,
          minTemp: mintemp4,
          days: count4,
        };
        avgtemp[4] = {
          month:"May",
          avgTemp: Math.round(getavg5),
          Rain: Math.round(rainfall5),
          maxTemp: maxtemp5,
          minTemp: mintemp5,
          days: count5,
        };
        avgtemp[5] = {
          month:"June",
          avgTemp: Math.round(getavg6),
          Rain: Math.round(rainfall6),
          maxTemp: maxtemp6,
          minTemp: mintemp6,
          days: count6,
        };
        avgtemp[6] = {
          month:"July",
          avgTemp: Math.round(getavg7),
          Rain: Math.round(rainfall7),
          maxTemp: maxtemp7,
          minTemp: mintemp7,
          days: count7,
        };
        avgtemp[7] = {
          month:"August",
          avgTemp: Math.round(getavg8),
          Rain: Math.round(rainfall8),
          maxTemp: maxtemp8,
          minTemp: mintemp8,
          days: count8,
        };
        avgtemp[8] = {
          month:"September",
          avgTemp: Math.round(getavg9),
          Rain: Math.round(rainfall9),
          maxTemp: maxtemp9,
          minTemp: mintemp9,
          days: count9,
        };
        avgtemp[9] = {
          month:"October",
          avgTemp: Math.round(getavg10),
          Rain: Math.round(rainfall10),
          maxTemp: maxtemp10,
          minTemp: mintemp10,
          days: count10,
        };
        avgtemp[10] = {
          month:"November",
          avgTemp: Math.round(getavg11),
          Rain: Math.round(rainfall11),
          maxTemp: maxtemp11,
          minTemp: mintemp11,
          days: count11,
        };
        avgtemp[11] = {
          month:"December",
          avgTemp: Math.round(getavg12),
          Rain: Math.round(rainfall12),
          maxTemp: maxtemp12,
          minTemp: mintemp12,
          days: count12,
        };
        avgtemp[mm] = {
          month:"SelectMonth_"+MouthName(mm),
          avgTemp: Math.round(selectmonthavgtemp),
          Rain: Math.round(selectmonthrainfall),
          maxTemp: selectmonthmaxtemp,
          minTemp: selectmonthmintemp,
          days: selectmonthcount,
        };
      });
      // console.log(avgtemp)
      setMonthAvgTemp(avgtemp);
      
    }
  };

  const MouthName = (number) => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[number];
  };

  const calculateAvgbyWeek = async () => {
    if (date !== "") {
      var choiceDateIndex;

      if (date) {
        choiceDateIndex = JSON.parse(date).Index;
      }

      // console.log("choice data index "+ choiceDateIndex);
      //Init state

      var dayOfweek = 7;
      var InitWeekValue = [];
      var DevWeekValue = [];
      var MidWeekValue = [];
      var LastWeekValue = [];

      var calStartDay = "";
      var calEndDay = "";
      var weekhightestTemmp = 0;
      var weeklowestTemp = 100;
      var weekrainfall = 0;

      var WeekMaxTempTotal = 0;
      var WeekMinTempTotal = 0;
      let i;
      var maxminTempAvg = 0;

      var InitDay = kcdata.inital_days;
      // console.log(InitDay)
      for (i = 0; i < InitDay; i++) {
        if (dayOfweek > -1) {
          // this is only to get the start day date.
          if (dayOfweek === 7) {
            calStartDay = forecast[choiceDateIndex + i].Date;
          }
          // console.log(forecast[choiceDateIndex+i])
          dayOfweek--;
          weekhightestTemmp = Math.max( weekhightestTemmp,forecast[choiceDateIndex + i].maxTemp);
          weeklowestTemp = Math.min(weeklowestTemp,forecast[choiceDateIndex + i].minTemp);
          weekrainfall = weekrainfall + forecast[choiceDateIndex + i].rainfall;
          WeekMaxTempTotal = WeekMaxTempTotal + forecast[choiceDateIndex + i].maxTemp;
          WeekMinTempTotal = WeekMinTempTotal + forecast[choiceDateIndex + i].minTemp;
        }
        if (dayOfweek == 0) {
          calEndDay = forecast[choiceDateIndex + i].Date;
          maxminTempAvg = (WeekMaxTempTotal / 7 + WeekMinTempTotal / 7) / 2;
          maxminTempAvg = Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
          InitWeekValue.push({
            StartDay: calStartDay,
            EndDay: calEndDay,
            MaxWeekTemp: weekhightestTemmp,
            MinWeekTemp: weeklowestTemp,
            weekRainfall:parseInt(weekrainfall),
              // Math.round((weekrainfall + Number.EPSILON) * 100) / 100,
            avgTemp: maxminTempAvg,
            Days: 7,
          });
          WeekMaxTempTotal = 0;
          WeekMinTempTotal = 0;
          weekhightestTemmp = 0;
          weeklowestTemp = 100;
          weekrainfall = 0;
          dayOfweek = 7;
        }
      }
      //for remain day that can not form a week
      if (dayOfweek !== 0 && dayOfweek !== 7) {
        calEndDay = forecast[choiceDateIndex + i - 1].Date;
        maxminTempAvg =
          (WeekMaxTempTotal / (7 - dayOfweek) +
            WeekMinTempTotal / (7 - dayOfweek)) /
          2;
        maxminTempAvg =
          Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
        InitWeekValue.push({
          StartDay: calStartDay,
          EndDay: calEndDay,
          MaxWeekTemp: weekhightestTemmp,
          MinWeekTemp: weeklowestTemp,
          weekRainfall: parseInt(weekrainfall),
          // Math.round((weekrainfall + Number.EPSILON) * 100) / 100,
          avgTemp: maxminTempAvg,
          Days: 7 - dayOfweek,
        });
        WeekMaxTempTotal = 0;
        WeekMinTempTotal = 0;
        weekhightestTemmp = 0;
        weeklowestTemp = 100;
        weekrainfall = 0;
        dayOfweek = 7;
      }
      // console.log(InitWeekValue)
      setInitWeek(InitWeekValue);

      //develop stage
      var DevDay = kcdata.dev_days;
      //console.log(DevDay)
      dayOfweek = 7;
      for (i = InitDay; i < InitDay + DevDay; i++) {
        if (dayOfweek > -1) {
          if (dayOfweek == 7) {
            // console.log(forecast[choiceDateIndex + i].Date)
            calStartDay = forecast[choiceDateIndex + i].Date;
          }
          // console.log(forecast[choiceDateIndex + i])
          dayOfweek--;
          weekhightestTemmp = Math.max(
            weekhightestTemmp,
            forecast[choiceDateIndex + i].maxTemp
          );
          weeklowestTemp = Math.min(
            weeklowestTemp,
            forecast[choiceDateIndex + i].minTemp
          );
          weekrainfall = weekrainfall + forecast[choiceDateIndex + i].rainfall;
          WeekMaxTempTotal =
            WeekMaxTempTotal + forecast[choiceDateIndex + i].maxTemp;
          WeekMinTempTotal =
            WeekMinTempTotal + forecast[choiceDateIndex + i].minTemp;
        }
        if (dayOfweek == 0) {
          // console.log(forecast[choiceDateIndex + i].Date)
          calEndDay = forecast[choiceDateIndex + i].Date;
          maxminTempAvg = (WeekMaxTempTotal / 7 + WeekMinTempTotal / 7) / 2;
          maxminTempAvg =
            Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
          DevWeekValue.push({
            StartDay: calStartDay,
            EndDay: calEndDay,
            MaxWeekTemp: weekhightestTemmp,
            MinWeekTemp: weeklowestTemp,
            weekRainfall: weekrainfall,
            avgTemp: maxminTempAvg,
            Days: 7 - dayOfweek,
          });
          WeekMaxTempTotal = 0;
          WeekMinTempTotal = 0;
          weekhightestTemmp = 0;
          weeklowestTemp = 100;
          weekrainfall = 0;
          dayOfweek = 7;
        }
      }
      if (dayOfweek !== 0 && dayOfweek !== 7) {
        calEndDay = forecast[choiceDateIndex + i - 1].Date;
        maxminTempAvg =(WeekMaxTempTotal / (7 - dayOfweek) + WeekMinTempTotal / (7 - dayOfweek)) / 2;
        maxminTempAvg = Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
        DevWeekValue.push({
          StartDay: calStartDay,
          EndDay: calEndDay,
          MaxWeekTemp: weekhightestTemmp,
          MinWeekTemp: weeklowestTemp,
          weekRainfall: weekrainfall,
          avgTemp: maxminTempAvg,
          Days: 7 - dayOfweek,
        });

        // inital it before the next stage
        WeekMaxTempTotal = 0;
        WeekMinTempTotal = 0;
        weekhightestTemmp = 0;
        weeklowestTemp = 100;
        weekrainfall = 0;
        dayOfweek = 7;
      }
      // console.log(DevWeekValue)
      setDavWeek(DevWeekValue);

      var MidDay = kcdata.mid_days;
      //console.log(MidDay)
      dayOfweek = 7;
      for (i = InitDay + DevDay; i < InitDay + DevDay + MidDay; i++) {
        if (dayOfweek > -1) {
          if (dayOfweek == 7) {
            calStartDay = forecast[choiceDateIndex + i].Date;
            // console.log(forecast[choiceDateIndex+i].Date)
          }
          // console.log(forecast[choiceDateIndex+i])
          dayOfweek--;
          weekhightestTemmp = Math.max(
            weekhightestTemmp,
            forecast[choiceDateIndex + i].maxTemp
          );
          weeklowestTemp = Math.min(
            weeklowestTemp,
            forecast[choiceDateIndex + i].minTemp
          );
          weekrainfall = weekrainfall + forecast[choiceDateIndex + i].rainfall;
          WeekMaxTempTotal =
            WeekMaxTempTotal + forecast[choiceDateIndex + i].maxTemp;
          WeekMinTempTotal =
            WeekMinTempTotal + forecast[choiceDateIndex + i].minTemp;
        }
        if (dayOfweek == 0) {
          // console.log(forecast[choiceDateIndex+i].Date)
          calEndDay = forecast[choiceDateIndex + i].Date;
          maxminTempAvg = (WeekMaxTempTotal / 7 + WeekMinTempTotal / 7) / 2;
          maxminTempAvg =
            Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
          MidWeekValue.push({
            StartDay: calStartDay,
            EndDay: calEndDay,
            MaxWeekTemp: weekhightestTemmp,
            MinWeekTemp: weeklowestTemp,
            weekRainfall: weekrainfall,
            avgTemp: maxminTempAvg,
            Days: 7 - dayOfweek,
          });
          WeekMaxTempTotal = 0;
          WeekMinTempTotal = 0;
          weekhightestTemmp = 0;
          weeklowestTemp = 100;
          weekrainfall = 0;
          dayOfweek = 7;
        }
      }
      if (dayOfweek != 0 && dayOfweek != 7) {
        calEndDay = forecast[choiceDateIndex + i - 1].Date;
        maxminTempAvg =
          (WeekMaxTempTotal / (7 - dayOfweek) +
            WeekMinTempTotal / (7 - dayOfweek)) /
          2;
        maxminTempAvg =
          Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
        MidWeekValue.push({
          StartDay: calStartDay,
          EndDay: calEndDay,
          MaxWeekTemp: weekhightestTemmp,
          MinWeekTemp: weeklowestTemp,
          weekRainfall: weekrainfall,
          avgTemp: maxminTempAvg,
          Days: 7 - dayOfweek,
        });
        WeekMaxTempTotal = 0;
        WeekMinTempTotal = 0;
        weekhightestTemmp = 0;
        weeklowestTemp = 100;
        weekrainfall = 0;
        dayOfweek = 7;
      }

      // console.log(MidWeekValue)
      setMidWeek(MidWeekValue);

      var LastDay = kcdata.last_days;
      //(LastDay)
      dayOfweek = 7;
      for (
        i = InitDay + DevDay + MidDay;
        i < InitDay + DevDay + MidDay + LastDay;
        i++
      ) {
        if (dayOfweek > -1) {
          if (dayOfweek == 7) {
            calStartDay = forecast[choiceDateIndex + i].Date;
            // console.log(forecast[choiceDateIndex+i].Date)
          }
          // console.log(forecast[choiceDateIndex+i])
          dayOfweek--;
          weekhightestTemmp = Math.max(
            weekhightestTemmp,
            forecast[choiceDateIndex + i].maxTemp
          );
          weeklowestTemp = Math.min(
            weeklowestTemp,
            forecast[choiceDateIndex + i].minTemp
          );
          weekrainfall = weekrainfall + forecast[choiceDateIndex + i].rainfall;
          WeekMaxTempTotal =
            WeekMaxTempTotal + forecast[choiceDateIndex + i].maxTemp;
          WeekMinTempTotal =
            WeekMinTempTotal + forecast[choiceDateIndex + i].minTemp;
        }
        if (dayOfweek == 0) {
          // console.log(forecast[choiceDateIndex+i].Date)
          calEndDay = forecast[choiceDateIndex + i].Date;
          maxminTempAvg = (WeekMaxTempTotal / 7 + WeekMinTempTotal / 7) / 2;
          maxminTempAvg =
            Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
          LastWeekValue.push({
            StartDay: calStartDay,
            EndDay: calEndDay,
            MaxWeekTemp: weekhightestTemmp,
            MinWeekTemp: weeklowestTemp,
            weekRainfall: weekrainfall,
            avgTemp: maxminTempAvg,
            Days: 7 - dayOfweek,
          });
          WeekMaxTempTotal = 0;
          WeekMinTempTotal = 0;
          weekhightestTemmp = 0;
          weeklowestTemp = 100;
          weekrainfall = 0;
          dayOfweek = 7;
        }
      }

      if (dayOfweek != 0 && dayOfweek != 7) {
        calEndDay = forecast[choiceDateIndex + i - 1].Date;
        maxminTempAvg =
          (WeekMaxTempTotal / (7 - dayOfweek) +
            WeekMinTempTotal / (7 - dayOfweek)) /
          2;
        maxminTempAvg =
          Math.round((maxminTempAvg + Number.EPSILON) * 100) / 100;
        LastWeekValue.push({
          StartDay: calStartDay,
          EndDay: calEndDay,
          MaxWeekTemp: weekhightestTemmp,
          MinWeekTemp: weeklowestTemp,
          weekRainfall: weekrainfall,
          avgTemp: maxminTempAvg,
          Days: 7 - dayOfweek,
        });
        WeekMaxTempTotal = 0;
        WeekMinTempTotal = 0;
        weekhightestTemmp = 0;
        weeklowestTemp = 100;
        weekrainfall = 0;
        dayOfweek = 7;
      }

      // console.log(LastWeekValue);
      setLastWeek(LastWeekValue);
    }
  };

  const culculateArea = async () => {
    var unit;
    // console.log(props.distance)
    if (measure != 0 && props.distance != null) {
      unit = (measure * 10000) / props.distance;
    }
    // console.log(unit)
    setUnit(unit);
  };

  function calculateKcClick() {
    culculateArea();
    setShow(measuretime);
  }

  const contentShow = () => {
    if (show === "week") {
      return (
        <WeekStageCard
          kc={kcdata}
          initTemp={InitWeeks}
          devTemp={DevWeeks}
          midTemp={MidWeeks}
          lastTemp={LastWeeks}
          cropTmax={cropMexTemp}
          cropTmin={cropMinTemp}
          po={po_place}
          area={measure}
          unit={unit}
          la={la}
        />
      );
    } else if (show === "month") {
      return (
        <MonthlyCard
          monthforcase={MonthAvgTemp}
          kc={kcdata}
          cropTmax={cropMexTemp}
          cropTmin={cropMinTemp}
          // cropID={props.cropID}
          la={la}
          date={date}
          area={measure}
          unit={unit}
        />
      );
    }
  };

  return (
    <div className={style.details_container}>
      {kcdata ? (
        <div className={style.details}>
          <div className={style.irrigation_form}>
            <div className={style.equal_field}>
              <label htmlFor="regionSelect">{t("region")}</label>
              <select
                value={region}
                onChange={(e) => setRegion(e.target.value)}
                id="regionSelect"
              >
                <option selected>Choose...</option>
                <option value="north">北部</option>
                <option value="middle">中部</option>
                <option value="south">南部</option>
                <option value="east">東部</option>
                <option value="external">外島</option>
              </select>
            </div>
            <div className={style.equal_field}>
              {cities.length === 0 ? null : (
                <div>
                  <label htmlFor="cityselect">{t("city")}</label>
                  <select
                    id="cityselect"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option selected>Choose...</option>
                    {cities.map((city, idx) => {
                      return (
                        <option key={idx} value={JSON.stringify(city)}>
                          {t(`${city.City}`)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
            <div className={style.equal_field}>
              {city === "" ? null : (
                <div>
                  <label htmlFor="dateselect">{t("date")}</label>
                  <select
                    id="dateselect"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  >
                    <option selected>Choose...</option>
                    {forecast.map((date) => {
                      return (
                        <option key={date.Date} value={JSON.stringify(date)}>
                          {date.Date}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>

            <div className={style.equal_field}>
              <label htmlFor="areacount">{t("area-square")}</label>
              <input
                type="number"
                className={style.equal_field_input}
                value={measure}
                onChange={(e) => setmesure(e.target.value)}
                id="areacount"
              />
            </div>
            <div className={style.equal_field}>
              <label htmlFor="timeSelect">{t("week-month")}</label>
              <select
                value={measuretime}
                onChange={(e) => setmesuretime(e.target.value)}
                className="form-control"
                id="timeSelect"
              >
                <option selected>Choose...</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
              </select>
            </div>
            <div className={style.equal_field}>
              <label htmlFor=""></label>
              <br />
              <button
                className={style.submitbtn}
                onClick={calculateKcClick}
                disabled={InitWeeks.length == 0}
              >
                {t("calculate")}
              </button>
            </div>
          </div>
          <br />
          <div className={style.irrigation_description}>
            <div className={style.scroll_area}>{contentShow()}</div>
          </div>
        </div>
      ) : (
        <p> {t("no-irrigation-data")}</p>
      )}

      <div>
        <VerifyCard cropId={props.cropId} />
      </div>
    </div>
  );
};

export default Irrigation;
