import React ,{ Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./i18next";
import Loader from './component/loader/loader2'

ReactDOM.render(
   <Suspense fallback={<Loader />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);



